import { createAsyncThunk } from "@reduxjs/toolkit";
import partnerHttpRequest from "../Apicall/partnerHttpRequest";
import { UPDATE_ROLE } from "../endpoint/constant";
import { RoleCredential } from "./createRole";


const updateRole = createAsyncThunk<any, RoleCredential>('create/role', async (data, { rejectWithValue }) => {
    try {
        const response = await partnerHttpRequest.post(UPDATE_ROLE, data)
        const { ErrorCode } = response.data;
        if (ErrorCode !== 200) {
            throw new Error("Internal server error")
        }
        return response.data
    } catch (error: any) {
        const err = error?.response?.data?.errors;
        return rejectWithValue(err.CreateBy[0])
    }
})
export default updateRole;