import axios from "axios";
import { useEffect, useState } from "react";
import { AiOutlineCloudUpload } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import { selectPartnerConfig } from "../../redux/slice/DownloadPartnerIntegrationKitSlice";
import doc from "../../assets/images/doc.svg";
import { Link } from "react-router-dom";
import { selectPartnerSource } from "../../redux/slice/getPartnerSourceSlice";
import getPartnerSource from "../../redux/services/getPartnerSource";
import Iframe from "react-iframe";
import IFrameOnClick from "./IFrameOnClick";
import Button from "../../component/button/Button";
const PDFUploader = () => {
  const [file, setFile] = useState(null);
  const [mouPath, setMouPath] = useState("");

  const [message, setMessage] = useState(false);
  const [viewPdf, setPviewPdf] = useState(false);
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();

  const selector = useSelector(selectPartnerSource);

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];

    if (
      selectedFile.name.endsWith(".doc") ||
      selectedFile.name.endsWith(".docx")
    ) {
      setFile(selectedFile);
      var reader = new FileReader();
      reader.readAsDataURL(selectedFile);
      reader.onload = function () {
        const base64 = reader.result;
        uploadFile(base64.split(",")[1]);
      };
      reader.onerror = function (error) {};
    } else {
      setMessage(true);
      toast.error("Please select a doc file");
    }
  };
  const getUserData = async () => {
    const roleId = localStorage.getItem("r_id");
    const partnerCreateBy = localStorage.getItem("partnerCreateBy");
    try {
      const ID =
        roleId === "1" || roleId === "35"
          ? localStorage.getItem("p_id")
          : localStorage.getItem("p_id") !== null
          ? localStorage.getItem("p_id")
          : partnerCreateBy;
      // const ID = localStorage.getItem("p_id") || "";
      if (!ID) {
        return;
      }
      const response = await dispatch(getPartnerSource({ ID }));
      const { Result } = response.payload;
      // setImage(Result[0]?.PartnerLogo);
      // localStorage.setItem("u_name", Result[0]?.PartnerUserName);
      // localStorage.setItem("p_logo", Result[0]?.PartnerLogo);
      setMouPath(Result[0]?.MOUPath);
      localStorage.setItem("MOUPath", Result[0]?.MOUPath);
    } catch (error) {}
  };

  const uploadFile = async (base64) => {
    setMessage(true);
    try {
      const result = await axios(
        `https://api.societypartner.auroscholar.org/api/partner/UploadPartnerMOU`,
        {
          method: "POST",
          data: {
            ID: localStorage.getItem("p_id"),
            MOU: base64,
            UserName: localStorage.getItem("u_name"),
          },
        }
      );
      const { ErrorCode } = result.data;
      if (ErrorCode === 200) {
        setShow(true);
        toast.success("File uploaded successfully");
        getUserData();
      }
    } catch (error) {
      toast.error("Some error occured! Please try again");
    }
  };

  const handleDownload = async () => {
    console.log("click downloading start");
    const response = await axios(
      `https://api.societypartner.auroscholar.org/api/partner/DownloadPartnerMOU`,
      {
        responseType: "blob",
        method: "POST",
        data: { ID: localStorage.getItem("p_id") },
      }
    );

    const blob = new Blob([response.data], {
      type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    });
    const url = window.URL.createObjectURL(blob);
    // Create a link element and set its attributes
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `mou.docx`);
    // Append the link to the document and trigger the click event
    document.body.appendChild(link);
    link.click();
    // Clean up the temporary URL
    window.URL.revokeObjectURL(url);
  };

  useEffect(() => {
    if (selector[0]?.MOUPath) {
      setShow(true);
    }
  }, [selector]);
  return (
    <>
      <div className="flex items-center justify-center mt-4">
        <div className="mt-4">
          <button
            onClick={handleDownload}
            // disabled={!file}
            className={`py-2 px-4 bg-blue-500 text-white rounded`}
          >
            {/* <button
            onClick={handleDownload}
            disabled={!file}
            className="py-2 px-4 bg-blue-500 text-white rounded cursor-pointer"
          >
            Download PDF
          </button> */}
            Download PDF
          </button>
        </div>
      </div>

      {show === true ? (
        <>
          {" "}
          <div className="flex justify-center mt-4 mb-4">
            <a
              href={mouPath ? mouPath : localStorage.getItem("MOUPath")}
              target="_blank"
            >
              <img
                alt="DOC"
                src={doc}
                className="w-[6rem] cursor-pointer"
              ></img>
            </a>
          </div>
          <div className="flex justify-center">
            <button
              className={`py-2 px-4 bg-blue-500 text-white rounded`}
              onClick={() => {
                setShow(false);
                setPviewPdf(false);
              }}
            >
              Update
            </button>
            <button
              className={`py-2 px-4 bg-blue-500 text-white rounded ml-3`}
              onClick={() => {
                setPviewPdf(true);
              }}
            >
              View{" "}
            </button>
          </div>
          {viewPdf ? <IFrameOnClick /> : ""}
        </>
      ) : (
        <>
          {" "}
          <div className="flex justify-center mt-4">
            <div className="flex items-center justify-center w-2/4 ">
              <label
                htmlFor="dropzone-file"
                className="flex flex-col items-center justify-center w-full h-[200px] border-2 border-gray-300 border-dashed rounded-lg cursor-pointer"
              >
                <div className="flex flex-col items-center justify-center pt-5 pb-6">
                  <svg
                    className="w-[50px] h-8 mb-4 text-gray-500 dark:text-gray-400"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 20 16"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                    />
                  </svg>
                  <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                    <span className="font-semibold">Click to upload</span> or
                    drag and drop
                  </p>
                  <p className="text-xs text-gray-500 dark:text-gray-400">
                    Upload Your Mou Document here.
                  </p>
                </div>
                <input
                  type="file"
                  id="dropzone-file"
                  accept=".doc,.docx"
                  className="hidden"
                  onChange={handleFileChange}
                />
              </label>
            </div>
          </div>
        </>
      )}

      {/* {file && <p className="mt-4">Selected file: {file.name}</p>} */}
      {message && <ToastContainer />}
    </>
  );
};

export default PDFUploader;
