import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "./stateSlice";
import getUserRole from "../services/getUserRole";
import { RootState } from "../store/store";
import updateRole from "../services/updateRole";
import getRoleMenuMapping from "../services/getRoleMenuMapping";
import getPartnerUser from "../services/getPartnerUser";
const state: initialState = {
    status: 'idle',
    data: [],
    error: false
}
const getPartnerUserSlice = createSlice({
    name: "role/menu",
    initialState: state,
    reducers: {
        ActivePartnerUser: (state, action) => {
            const { ID } = action.payload;
            state.data = state.data?.map((item: any) => {
                return item.ID === ID ? { ...item, Status: 1 } : { ...item }
            })
        },
        DeactivatePartnerUser: (state, action) => {
            const { ID } = action.payload;
            state.data = state.data?.map((item: any) => {
                return item.ID === ID ? { ...item, Status: 0 } : { ...item }
            })
        },
        deletePartnerUser: (state, action) => {
            const { ID } = action.payload;
            state.data = state.data?.filter((item: any) => item.ID !== ID)
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getPartnerUser.pending, (state) => {
            state.status = 'loading';
        }).addCase(getPartnerUser.fulfilled, (state, action) => {

            state.status = 'success';
            state.data = action.payload.Result;
        })
            .addCase(getPartnerUser.rejected, (state, action) => {
                state.status = 'error';
            })
            .addDefaultCase((state) => ({ ...state }))
    }
})

export const selectPartnerUser = (state: RootState) => {
    const { data } = state.getPartnerUserSlice;
    const customisedData = data?.filter((item: any) => (item.Status === 1) || (item.Status === 0)).map((item: any) => ({
        ...item,
        Status: item.Status === 1 ? 'Active' : 'Inactive'
    }))
    return customisedData
}
export default getPartnerUserSlice.reducer
export const { ActivePartnerUser, DeactivatePartnerUser, deletePartnerUser } = getPartnerUserSlice.actions