import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store/store";
import { initialState } from "./stateSlice";
import quizByStudentClass from "../services/quizByStudentClass";
import LoginUser from "../services/LoginUser";
const state: initialState = {
    status: "idle",
    data: [],
    error: false,
};

const LoginSlice = createSlice({
    name: "user/login",
    initialState: state,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(LoginUser.pending, (state) => {
                state.status = "loading";
            })
            .addCase(LoginUser.fulfilled, (state, action) => {
                state.status = "success";
                state.data = action.payload;
            })
            .addCase(LoginUser.rejected, (state, action) => {
                state.status = "error";
                state.error = true;
            })
            .addDefaultCase((state) => ({ ...state }));
    },
});

export const SelectQuizByStudentClass = (state: RootState) => {
    return state?.quizByStudentClassSlice;
};

export default LoginSlice.reducer;
