import { createSlice } from "@reduxjs/toolkit";
import getSchoolDetails from "../services/getSchool";
import { RootState } from "../store/store";

interface initialState {
  status: "idle" | "loading" | "success" | "error";
  data: any;
  error: boolean;
}

const state: initialState = {
  status: "idle",
  data: [],
  error: false,
};

const getSchoolDetailSlice = createSlice({
  name: "get/schoolDetailList",
  initialState: state,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSchoolDetails.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getSchoolDetails.fulfilled, (state, action) => {
        state.status = "success";
        state.data = action.payload;
      })
      .addCase(getSchoolDetails.rejected, (state, action) => {
        state.status = "error";
        state.error = true;
      })
      .addDefaultCase((state) => ({ ...state }));
  },
});

export const SelectSchoolDetail = (state: RootState) => {
    const { Result } = state?.schoolDetailsSlice.data;
    // const customizeOptions = Result?.map((item: any) => ({
    //   value: item.kyc_status,
    //   label: item.kyc_status,
    // student_name: item.student_name
    // }));
    return Result;
};

export default getSchoolDetailSlice.reducer;
