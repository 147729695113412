import React, { useState } from "react";
import { useLocation, Navigate } from "react-router-dom";
import InputField from "../../component/InputField/InputField";
import partners from "../../assets/images/partners.svg";
import Button from "../../component/button/Button";
import { Link, useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../redux/hook/hooks";
import LoginUser from "../../redux/services/LoginUser";
import { ToastContainer, toast } from "react-toastify";
import { faEnvelope, faLock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";

const Login: React.FC = () => {
  const location = useLocation();
  const [loginCred, setLoginCred] = useState({
    UserName: "",
    Password: "",
  });
  const dispatch = useAppDispatch();
  const [showMessage, setShowMessage] = useState<boolean>(false);
  const [passwordType, setPasswordType] = useState("password");
  // const [disableButton, setDisableButton] = useState<boolean>(false);
  const navigate = useNavigate();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLoginCred({ ...loginCred, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    // setDisableButton(true);
    setShowMessage(true);

    if (loginCred.UserName.length < 3) {
      toast.warn("Please enter the user name(3 to 50 characters)");
      return;
    } else if (loginCred.Password.length < 6) {
      toast.warn("Password length should be between 6 to 50");
      return;
    }

    try {
      const response = await dispatch(LoginUser(loginCred)).unwrap();
      const { p_ErrorCode, pstatus } = response;
      if (pstatus === 0) {
        toast.error("You can not login till you are inactive");
        return;
      } else if (pstatus === 2) {
        toast.error("User Not found");
        return;
      } else if (p_ErrorCode === 200) {
        const { accessToken, RoleID, PartnerUserID, PartnerCreateBy } =
          response;
        console.log(RoleID, "RRRR");
        localStorage.setItem("a_t", accessToken);
        localStorage.setItem("r_id", RoleID);
        localStorage.setItem("p_id", PartnerUserID);
        localStorage.setItem("partnerCreateBy", PartnerCreateBy);
        setShowMessage(true);
        toast.success("Login Successfully");
        if (RoleID === 1) {
          // setTimeout(() => {
          console.log(RoleID, "ff");
          navigate("/partner/list");
          // }, 1000);
        } else if (RoleID === 35) {
          console.log(RoleID, "ff");
          navigate("/partner/levelone");
        } else if (RoleID === 36) {
          console.log(RoleID, "ff");
          navigate("/partner/mou-upload");
        } else {
          console.log(RoleID, "ff");
          // setTimeout(() => {
          navigate("/partner/config");
          // }, 1000);
        }
      }
    } catch (error: any) {
      toast.error("Invalid credential");
    }
  };

  if (localStorage.getItem("a_t")) {
    if (location.pathname === "/" && localStorage.getItem("r_id") === "1") {
      return <Navigate to="/partner/list" />;
    } else if (
      location.pathname === "/" &&
      localStorage.getItem("r_id") === "35"
    ) {
      return <Navigate to="/partner/levelone" />;
    } else if (
      location.pathname === "/" &&
      localStorage.getItem("r_id") === "36"
    ) {
      return <Navigate to="/partner/mou-upload" />;
    } else {
      return <Navigate to="/partner/config" />;
    }
  }

  return (
    <>
      <div className="w-full h-[100vh] flex justify-between m-auto items-center">
        <div className="w-2/4">
          <div className="w-3/4 m-auto h-4/5 mx=[50px]">
            <div className="text-center">
              <h1 className="text-5xl text-theme-color">Login</h1>
              <p className="mt-4 w-[80%] m-auto">Welcome back!.</p>
              <form onSubmit={handleSubmit}>
                <div>
                  <div className="relative my-3 flex w-3/5 m-auto">
                    <div className="pointer-events-none absolute inset-y-0 flex items-center w-9 bg-[#cbd5e1] rounded-l-md shadow-inner">
                      <span className="text-gray-500 m-auto">
                        {/* <img src={emailIcon} width="18px" alt="" /> */}
                        <FontAwesomeIcon
                          icon={faEnvelope}
                          style={{ color: "#0052a4" }}
                        />
                      </span>
                    </div>
                    <InputField
                      className="block px-3 py-2 w-full bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400 flex-auto pl-[45px] focus:outline-none"
                      type="text"
                      name="UserName"
                      placeholder="UserName"
                      value={loginCred.UserName}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="relative my-3 flex w-3/5 m-auto">
                    <div className="pointer-events-none absolute inset-y-0 flex items-center w-9 bg-[#cbd5e1] rounded-l-md shadow-inner">
                      <span className="text-gray-500 m-auto">
                        {/* <img src={passcodeIcon} width="15px" alt="" /> */}
                        <FontAwesomeIcon
                          icon={faLock}
                          style={{ color: "#0052a4" }}
                        />
                      </span>
                    </div>
                    <InputField
                      className="block px-3 py-2 w-full bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400 flex-auto pl-[45px] focus:outline-none"
                      type={passwordType}
                      name="Password"
                      placeholder="Password"
                      value={loginCred.Password}
                      onChange={handleChange}
                    />
                    {passwordType === "password" ? (
                      <AiFillEye
                        className="cursor-pointer absolute right-[10px] top-[10px]"
                        onClick={() => {
                          setPasswordType("text");
                        }}
                      />
                    ) : (
                      <AiFillEyeInvisible
                        className="cursor-pointer absolute right-[10px] top-[10px]"
                        onClick={() => {
                          setPasswordType("password");
                        }}
                      />
                    )}
                  </div>
                </div>

                <div className="w-3/5 m-auto flex justify-between my-2">
                  <span className="text-xs"></span>
                  <Link to="/forgot-password">
                    <span className="text-blue-600 hover:text-blue-700 font-medium cursor-pointer ...">
                      Forgot Password
                    </span>
                  </Link>
                </div>
                <Button
                  buttonContent={"LOGIN"}
                  // disabled={disableButton}
                  type="submit"
                />
                <br />
              </form>
              <div className="mt-2">
                <span>Don't have an account?</span>&nbsp;
                <Link to="sign-up">
                  <span className="text-blue-600 hover:text-blue-700 font-medium cursor-pointer ...">
                    Sign up
                  </span>
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="w-2/4 flex ">
          <h1>
            <img className="h-[100vh]" src={partners} alt="" />
          </h1>
        </div>
      </div>
      {showMessage && <ToastContainer pauseOnHover={false} limit={1} />}
    </>
  );
};

export default Login;
