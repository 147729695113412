import Select from "react-select";
interface MultiSelectProps {
  options: Record<string, string>[];
  multi: boolean;
  value?: any;
  placeholer?: string;
  required?: boolean;
  onChange: (item: any) => void;
  noOptionsMessage?: () => string | undefined;
  className?: string;
}

const MultiSelect = ({
  options,
  multi,
  value,
  onChange,
  className,
  required,
  noOptionsMessage,
}: MultiSelectProps) => {
  return (
    <>
      <Select
        options={options}
        value={value}
        required={required}
        isMulti={multi}
        noOptionsMessage={noOptionsMessage}
        onChange={onChange}
        className={className}
      />
    </>
  );
};

export default MultiSelect;
