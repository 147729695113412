import { createBrowserRouter } from "react-router-dom";
import Layout from "../layout/Layout";
import { privateRoutes } from "./privateRoutes";
import Login from "../pages/Login/Login";
import Registration from "../pages/Registration/Registration";
import ForgotPassword from "../pages/Forgot/ForgotPassword";
import VerifyOtp from "../pages/Forgot/VerifyOtp";
import ResetPassword from "../pages/Forgot/ResetPassword";

import Verifiedmail from "../pages/verifiedmail/Verifiedmail";
import NotFound from "../pages/NotFoundPage/NotFound";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Login />,
  },
  {
    path: "/sign-up",
    element: <Registration />,
  },
  {
    path: "/forgot-password",
    element: <ForgotPassword />,
  },
  {
    path: "/verify-otp",
    element: <VerifyOtp />,
  },
  // {
  //   path: "/reset-password",
  //   element: <ResetPassword />,
  // },
  {
    path: "/partner",
    element: <Layout />,
    children: privateRoutes,
  },
  {
    path: "email/verify",
    element: <Verifiedmail />,
  },
  {
    path: "*",
    element: <NotFound />,
  },
]);

export default router;
