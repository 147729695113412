import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "./stateSlice";
import createPartner from "../services/createPartner";
import EditPartner from "../services/EditPartner";
const state: initialState = {
    status: 'idle',
    message: "",
    error: false
}
const editPartnerSlice = createSlice({
    name: "edit/partner",
    initialState: state,
    reducers: {
    },
    extraReducers: (builder) => {
        builder.addCase(EditPartner.pending, (state) => {
            state.status = 'loading';
        }).addCase(EditPartner.fulfilled, (state) => {
            state.status = 'success';
            state.message = "User Created Successfully";
        })
            .addCase(EditPartner.rejected, (state) => {
                state.status = 'error';
            })
            .addDefaultCase((state) => ({ ...state }))
    }
})
export default editPartnerSlice.reducer;