import { createSlice } from "@reduxjs/toolkit";
import getSchoolKycDetails from "../services/getSchoolKycDetail";
import { RootState } from "../store/store";
import { initialState } from "./stateSlice";

const state: initialState = {
  status: "idle",
  data: [],
  error: false,
};

const getSchoolKycDetailSlice = createSlice({
  name: "get/detail/kyc/school",
  initialState: state,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSchoolKycDetails.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getSchoolKycDetails.fulfilled, (state, action) => {
        state.status = "success";
        state.data = action.payload.Result;
      })
      .addCase(getSchoolKycDetails.rejected, (state) => {
        state.status = "error";
        state.error = true;
      })
      .addDefaultCase((state) => ({ ...state }));
  },
});

export const selectSchoolKycDetails = (state: RootState) => {
  const { data } = state?.getSchoolKycDetailSlice;
  return data;
};

export default getSchoolKycDetailSlice.reducer;
