import { createSlice } from "@reduxjs/toolkit";

import getSubject from "../services/getSubject";
import { RootState } from "../store/store";

interface initialState {
  status: "idle" | "loading" | "success" | "error";
  data: any;
  error: boolean;
}
const state: initialState = {
  status: "idle",
  data: [],
  error: false,
};

const subjectSlice = createSlice({
  name: "get/subject",
  initialState: state,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSubject.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getSubject.fulfilled, (state, action) => {
        state.status = "success";
        state.data = action.payload;
      })
      .addCase(getSubject.rejected, (state, action) => {
        state.status = "error";
        state.error = true;
      })
      .addDefaultCase((state) => ({ ...state }));
  },
});
export const selectGetSubject = (state: RootState) => {
  const { Result } = state.subjectSlice.data;
  const customizeOptions = Result?.map((item: any) => ({
    value: item.subject_id,
    label: item.subject_name,
  }));
  return customizeOptions;
};

export default subjectSlice.reducer;
