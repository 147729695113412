import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store/store";
import { initialState } from "./stateSlice";
import quizByStudentClass from "../services/quizByStudentClass";
const state: initialState = {
    status: "idle",
    data: [],
    error: false,
};

const quizByStudentClassSlice = createSlice({
    name: "get/quiz/by/student/class",
    initialState: state,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(quizByStudentClass.pending, (state) => {
                state.status = "loading";
            })
            .addCase(quizByStudentClass.fulfilled, (state, action) => {
                state.status = "success";
                console.log(action.payload, 'action1')
                state.data = action.payload;
            })
            .addCase(quizByStudentClass.rejected, (state, action) => {
                state.status = "error";
                state.error = true;
            })
            .addDefaultCase((state) => ({ ...state }));
    },
});

export const SelectQuizByStudentClass = (state: RootState) => {
    console.log(state?.quizByStudentClassSlice, 'action2')
    return state?.quizByStudentClassSlice;
};

export default quizByStudentClassSlice.reducer;
