import { createSlice } from "@reduxjs/toolkit";
import getStudentDetail from "../services/getStudentDetail";
import { RootState } from "../store/store";

interface initialState {
  status: "idle" | "loading" | "success" | "error";
  data: any;
  error: boolean;
}

const state: initialState = {
  status: "idle",
  data: [],
  error: false,
};

const getStudentDetailSlice = createSlice({
  name: "get/studentDetailList",
  initialState: state,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getStudentDetail.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getStudentDetail.fulfilled, (state, action) => {
        state.status = "success";
        state.data = action.payload;
      })
      .addCase(getStudentDetail.rejected, (state, action) => {
        state.status = "error";
        state.error = true;
      })
      .addDefaultCase((state) => ({ ...state }));
  },
});

export const SelectStudentDetail = (state: RootState) => {
    const { Result } = state?.studentDetailSLice.data;
    // const customizeOptions = Result?.map((item: any) => ({
    //   value: item.kyc_status,
    //   label: item.kyc_status,
    // student_name: item.student_name
    // }));
    return Result;
};

export default getStudentDetailSlice.reducer;
