import { useState } from "react";
import AllPartnerList from "./AllPartnerList";
import MouNotUploadedList from "./MouNotUploadedList";
import RejectPartnerList from "./RejectPartnerList";
const Partners = () => {
  const [state, setState] = useState("AllParnter");
  return (
    <div>
      <input
        type="radio"
        id="css"
        name="component"
        defaultChecked={true}
        value="AllParnter"
        onChange={(e) => {
          setState(e.target.value);
        }}
      />
      <label htmlFor="css">All Parnter</label>
      <input
        type="radio"
        id="html"
        name="component"
        value="MouNotUpload"
        onChange={(e) => {
          setState(e.target.value);
        }}
      />
      <label htmlFor="html">Mou Not Upload</label>

      <input
        type="radio"
        id="javascript"
        name="component"
        value="RejectedPartner"
        onChange={(e) => {
          setState(e.target.value);
        }}
      />
      <label htmlFor="javascript">Rejected Partner</label>
      {state === "AllParnter" ? <AllPartnerList /> : ""}
      {state === "MouNotUpload" ? <MouNotUploadedList /> : ""}
      {state === "RejectedPartner" ? <RejectPartnerList /> : ""}
    </div>
  );
};

export default Partners;
