import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hook/hooks";
import partnerList from "../../redux/services/partnerList";
import Table from "../Table/Table";
import {
  selectPartnerList,
  verifyPartner,
} from "../../redux/slice/partnerListSlice";
import verifypartner from "../../redux/services/verifypartner";
import { ToastContainer, toast } from "react-toastify";
import DataTable from "react-data-table-component";

const Level1Partner = () => {
  const [showMessage, setShowMessage] = useState(false);

  const [column] = useState([
    {
      name: "Name",
      selector: (row: any) => row.Name,
    },
    {
      name: "Mobileno",
      selector: (row: any) => row.Mobileno,
    },
    {
      name: "Email",
      selector: (row: any) => row.Email,
    },
    {
      name: "EmailVerified",
      selector: (row: any) =>
        row.EmailVerified === 0 ? "Not Verified" : "verified",
    },
    {
      name: "Status",
      selector: (row: any) => row.Status,
      style: (row: any) => {
        return { cursor: row.Status === 1 ? "pointer" : "" };
      },

      cell: (row: any) => {
        return (
          <p
            onClick={() => {
              if (row.Status !== "Active") {
                verify(row);
              }
            }}
          >
            {row.Status}
          </p>
        );
      },
    },
  ]);
  const dispatch = useAppDispatch();
  const selector = useAppSelector(selectPartnerList);
  const conditionalRowStyles = [
    {
      when: (row: any) => row.Status,
      style: (row: any) => {
        return { cursor: row.Status === "Active" ? "" : "pointer" };
      },
    },
  ];

  const verify = async (row: any) => {
    try {
      const { CreateBy, PartnerUserId, Email } = row;
      const response = await dispatch(
        verifypartner({
          CreateBy,
          PartnerUserID: PartnerUserId,
          Email,
          VerifyBy: localStorage.getItem("p_id") || "",
        })
      );
      const { ErrorCode } = response;
      if (ErrorCode === 200) {
        dispatch(verifyPartner({ ID: PartnerUserId, CreateBy }));
        setShowMessage(true);
        toast.success("Partner activate successfully");
      }
    } catch (error) {}
  };
  const getAllPartnerList = async () => {
    try {
      const response = await dispatch(partnerList()).unwrap();
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAllPartnerList();
  }, []);
  return (
    <div>
      <DataTable
        columns={column}
        data={selector}
        pagination={true}
        conditionalRowStyles={conditionalRowStyles}
      />
      {showMessage && <ToastContainer />}
    </div>
  );
};

export default Level1Partner;
