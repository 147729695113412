import { createSlice } from "@reduxjs/toolkit";
import getKycLevelOneData from "../services/getKycLevelone";
import getQuizLevelOneData from "../services/getQuizLevelone";
import getStudentDetail from "../services/getStudentDetail";
import { RootState } from "../store/store";

interface initialState {
  status: "idle" | "loading" | "success" | "error";
  data: any;
  error: boolean;
}

const state: initialState = {
  status: "idle",
  data: [],
  error: false,
};

const getKycLevelOneSlice = createSlice({
  name: "get/kycLevelOne",
  initialState: state,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getKycLevelOneData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getKycLevelOneData.fulfilled, (state, action) => {
        state.status = "success";
        state.data = action.payload;
      })
      .addCase(getKycLevelOneData.rejected, (state, action) => {
        state.status = "error";
        state.error = true;
      })
      .addDefaultCase((state) => ({ ...state }));
  },
});

export const SelectKycLevel1 = (state: RootState) => {
  const { Result } = state?.KycLevel1Slice.data;
  return Result;
};

export default getKycLevelOneSlice.reducer;
