import { createAsyncThunk } from "@reduxjs/toolkit";
import partnerHttpRequest from "../Apicall/partnerHttpRequest";
import { LOGIN } from "../endpoint/constant";

const LoginUser = createAsyncThunk<any, any>('login', async (data, { rejectWithValue }) => {
    try {
        const response = await partnerHttpRequest.post(LOGIN, data)
        const { p_ErrorCode } = response.data;
        if (p_ErrorCode !== 200) {
            throw new Error("Internal server error")
        }
        return response.data
    } catch (error: any) {
        const { data } = error.response
        return rejectWithValue(data.p_Message)
    }
})
export default LoginUser;