import React, { ComponentPropsWithoutRef, useState } from "react";

// interface SelectFieldProps {
//   name: string;
//   value: any;
//   placeholder: string;
//   className: string;
//   handleChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
//   options:{[key:string] : any}[] | undefined;
// }
// type SelectFieldProps = ComponentPropsWithoutRef<"select">;
export interface SelectFieldProps extends ComponentPropsWithoutRef<"select"> {
  options: any;
}
const SelectField = ({
  name,
  value,
  onChange,
  className,
  placeholder,
  required,
  options,
}: SelectFieldProps) => {
  return (
    <div className="w-full">
      <select
        name={name}
        value={value}
        required={required}
        onChange={onChange}
        className={className}
      >
        <option value="">{placeholder}</option>
        {options?.map((item: any, index: number) => (
          <option key={index} value={item.value}>
            {item.label}
          </option>
        ))}
      </select>
    </div>
  );
};

export default SelectField;
