import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hook/hooks";

import {
  selectPartnerList,
  verifyPartner,
} from "../../redux/slice/partnerListSlice";
import verifypartner from "../../redux/services/verifypartner";
import { ToastContainer, toast } from "react-toastify";
import DataTable from "react-data-table-component";
import Level2PartnerList from "../../redux/services/Level2PartnerList";
import { selectPartner2List } from "../../redux/slice/Level2ParnterSlice";
import Button from "../../component/button/Button";
import { Link } from "react-router-dom";
import axios from "axios";
import httpRequest from "../../redux/Apicall/httpRequest";
import RejectedModal from "./RejectedModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileWord } from "@fortawesome/free-solid-svg-icons";
const Level2Partner = () => {
  const [showMessage, setShowMessage] = useState(false);
  const [open, setOpen] = useState(false);
  const [rejectPartner, setRejectPartner] = useState();

  const closeModal = () => {
    setOpen(false);
  };

  const [column] = useState([
    {
      name: "Name",
      selector: (row: any) => row.Name,
    },
    {
      name: "CreateDate",
      selector: (row: any) => row.CreateDate?.split("T")[0],
    },
    {
      name: "Mobileno",
      selector: (row: any) => row.Mobileno,
    },
    {
      name: "Donwload mou",
      selector: (row: any) => row.MOUPath,
      style: (row: any) => {
        return { cursor: row.Status === 1 ? "pointer" : "" };
      },

      cell: (row: any) => {
        return (
          <>
            {row.MOUPath === "0" ? (
              <p>Old user</p>
            ) : (
              <Link to={row.MOUPath} target="_blank">
                <FontAwesomeIcon icon={faFileWord} />
              </Link>
            )}
          </>
        );
      },
    },
    {
      name: "Status",
      selector: (row: any) => row.Status,
      style: (row: any) => {
        return { cursor: row.Status === 1 ? "pointer" : "" };
      },

      cell: (row: any) => {
        return (
          <>
            {row.Status !== 1 ? (
              <Button
                type="button"
                buttonContent="Verify"
                onClick={() => {
                  verify(row);
                }}
              />
            ) : (
              <button
                type="button"
                className="mt-2 py-1 px-3 bg-green-500 hover:bg-blue-700 text-white rounded"
              >
                verified
              </button>
            )}
          </>
        );
      },
    },
    {
      name: "Reject",
      selector: (row: any) => row.Status,
      style: (row: any) => {
        return { cursor: row.Status === 1 ? "pointer" : "" };
      },

      cell: (row: any) => {
        return (
          <>
            <Button
              type="button"
              buttonContent="Reject"
              onClick={() => {
                console.log(row, "rrowww");
                setRejectPartner(row);
                setOpen(true);
              }}
            />
          </>
        );
      },
    },
  ]);
  const dispatch = useAppDispatch();
  const selector = useAppSelector(selectPartner2List);
  const conditionalRowStyles = [
    {
      when: (row: any) => row.Status,
      style: (row: any) => {
        return { cursor: row.Status === "Active" ? "" : "pointer" };
      },
    },
  ];

  const verify = async (row: any) => {
    try {
      await axios(
        `https://api.societypartner.auroscholar.org/api/partner/Level2VerifyPartner`,
        {
          method: "POST",
          data: {
            PartnerUserId: row.PartnerUserID,
            CreateBy: localStorage.getItem("p_id"),
          },
        }
      ).then((result) => {
        if (result.status === 200) {
          setShowMessage(true);
          toast.success(result.data.Message);
          getAllPartnerList();
        }
      });
    } catch (error) {
      toast.error("Some error occured! Please try again");
    }
  };

  const getAllPartnerList = async () => {
    try {
      const response = await dispatch(Level2PartnerList()).unwrap();
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getAllPartnerList();
  }, []);
  return (
    <div>
      <RejectedModal
        isOpen={open}
        closeModal={closeModal}
        rejectPartner={rejectPartner}
        setShowMessage={setShowMessage}
      />
      <DataTable
        columns={column}
        data={selector}
        pagination={true}
        conditionalRowStyles={conditionalRowStyles}
      />
      {showMessage && <ToastContainer />}
    </div>
  );
};

export default Level2Partner;
