import partnerHttpRequest from "../Apicall/partnerHttpRequest";
import { VERIFY_PARTNER } from "../endpoint/constant";

const verifypartner = (credential: { PartnerUserID: number, CreateBy: number, Email: string, VerifyBy: string }) => {
    return async () => {
        try {
            const response = await partnerHttpRequest.post(VERIFY_PARTNER, credential)
            const { ErrorCode, Message } = response.data;
            if (ErrorCode !== 200) {
                throw new Error(Message);
            }
            return response.data;
        } catch (error) {
            return error;
        }
    };
};
export default verifypartner;