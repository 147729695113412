import { useEffect, useState } from "react";
import { messagesType } from "../role/CreateRole";
import Button from "../../component/button/Button";
import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import Message from "../../utilities/Message";
import { useAppDispatch, useAppSelector } from "../../redux/hook/hooks";
import {
  ActivePartnerUser,
  DeactivatePartnerUser,
  deletePartnerUser,
  selectPartnerUser,
} from "../../redux/slice/getPartnerUserSlice";
import getPartnerUser from "../../redux/services/getPartnerUser";
import ActivePartner from "../../redux/services/ActivePartner";
import DeactivePartner from "../../redux/services/DeactivePartner";
import DeletePartnerUser from "../../redux/services/DeletePartnerUser";
import { BsPencilFill } from "react-icons/bs";
import { RiDeleteBin5Fill } from "react-icons/ri";
import Loader from "../../GlobalLoader/Loader";
const GetUser = () => {
  const [loader, setLoader] = useState(false);
  const [showMessage, setShowMessage] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [messageType, setMessageType] = useState<messagesType>("");
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const selectUser = useAppSelector(selectPartnerUser);
  const [column] = useState([
    {
      name: "Partner Name",
      selector: (row: any) => row.PartnerUserName,
    },
    {
      name: "Role Name",
      selector: (row: any) => row.RoleName,
    },
    {
      name: "Status",
      selector: (row: any) => row.Status,
      style: {
        cursor: "pointer",
      },
      cell: (row: any) => {
        return (
          <p
            onClick={() => {
              if (row.Status === "Inactive") {
                ActivePartnerStatus(row);
              } else {
                DeactivePartnerStatus(row);
              }
            }}
          >
            {row.Status}
          </p>
        );
      },
    },
    {
      name: "Edit",
      width: "20%",
      center: true,
      style: {
        cursor: "pointer",
      },
      cell: (row: any) => {
        return (
          <span
            onClick={() => {
              navigate("/partner/create/partner", { state: row });
            }}
          >
            <BsPencilFill />
          </span>
        );
      },
    },
    {
      name: "Delete",
      width: "20%",
      center: true,
      style: {
        cursor: "pointer",
      },
      cell: (row: any) => {
        return (
          <span
            onClick={() => {
              deletePartnerUsers(row);
            }}
          >
            <RiDeleteBin5Fill />
          </span>
        );
      },
    },
  ]);
  const ActivePartnerStatus = async (row: any) => {
    setShowMessage(false);
    const ID = row.ID;
    const CreateBy = row.CreateBy;
    try {
      const response = await dispatch(ActivePartner({ ID, CreateBy }));
      const { ErrorCode } = response;
      if (ErrorCode === 200) {
        dispatch(ActivePartnerUser({ ID, CreateBy }));
        setMessageType("success");
        setShowMessage(true);
        setMessage("User is active successfully");
      }
    } catch (error) {}
  };
  const DeactivePartnerStatus = async (row: any) => {
    setShowMessage(false);
    const ID = row.ID;
    const CreateBy = row.CreateBy;
    try {
      const response = await dispatch(DeactivePartner({ ID, CreateBy }));
      const { ErrorCode } = response;
      if (ErrorCode === 200) {
        dispatch(DeactivatePartnerUser({ ID, CreateBy }));
        setMessageType("success");
        setShowMessage(true);
        setMessage("User is Deactive successfully");
      }
    } catch (error) {}
  };
  const deletePartnerUsers = async (row: any) => {
    setShowMessage(false);
    const ID = row.ID;
    const CreateBy = row.CreateBy;
    try {
      const response = await dispatch(DeletePartnerUser({ ID, CreateBy }));
      const { ErrorCode } = response;
      if (ErrorCode === 200) {
        dispatch(deletePartnerUser({ ID, CreateBy }));
        setMessageType("success");
        setShowMessage(true);
        setMessage("User is deleted successfully");
      }
    } catch (error) {}
  };
  const getUser = async () => {
    try {
      setLoader(true);
      await dispatch(
        getPartnerUser({ ID: localStorage.getItem("p_id") || "" })
      );
      setLoader(false);
    } catch (error) {}
  };
  useEffect(() => {
    getUser();
  }, []);
  return (
    <>
      <div className="flex justify-end px-10">
        <Button
          onClick={() => {
            navigate("/partner/create/partner");
          }}
          buttonContent="Create User"
        />
      </div>
      {loader ? <Loader /> : <DataTable columns={column} data={selectUser} />}
      {showMessage && <Message message={message} messageType={messageType} />}
    </>
  );
};

export default GetUser;
