import { createAsyncThunk } from "@reduxjs/toolkit";
import partnerHttpRequest from "../Apicall/partnerHttpRequest";
import { GET_PARTNER_SOURCE } from "../endpoint/constant";

const getPartnerSource = createAsyncThunk<any, { ID: string }>(
    "getPartnerType",
    async (data, { rejectWithValue }) => {
        try {
            const response = await partnerHttpRequest.post(GET_PARTNER_SOURCE, data);
            return response.data;
        } catch (error) { }
    }
);
export default getPartnerSource;
