import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store/store";
import { initialState } from "./stateSlice";
import quizBySubjectList from "../services/quizBySubjectList";
const state: initialState = {
    status: "idle",
    data: [],
    error: false,
};

const quizBySubjectListSlice = createSlice({
    name: "get/quiz/by/subject/list",
    initialState: state,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(quizBySubjectList.pending, (state) => {
                state.status = "loading";
            })
            .addCase(quizBySubjectList.fulfilled, (state, action) => {
                state.status = "success";
                state.data = action.payload;
            })
            .addCase(quizBySubjectList.rejected, (state, action) => {
                state.status = "error";
                state.error = true;
            })
            .addDefaultCase((state) => ({ ...state }));
    },
});

export const SelectQuizBySubjectList = (state: RootState) => {

    return state?.quizBySubjectListSlice;
};

export default quizBySubjectListSlice.reducer;
