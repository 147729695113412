import { createAsyncThunk } from "@reduxjs/toolkit";
import partnerHttpRequest from "../Apicall/partnerHttpRequest";
const getDistrict = createAsyncThunk(
  "districts",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await partnerHttpRequest.post('report/GetDistrictByStateID', data)
      return response.data
    } catch (error) { }
  }
);

export default getDistrict;
