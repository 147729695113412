import { Outlet } from "react-router";
import Header from "./Header/Header";
import { Navigate } from "react-router-dom";
import Sidebar from "./SideBar/Sidebar";
import { useLocation } from "react-router-dom";

const Layout = () => {
  const location = useLocation();

  if (!localStorage.getItem("a_t")) {
    return <Navigate to="/" />;
  }

  return (
    <div className="grid grid-cols-5">
      <div className="col-span-1">
        <Sidebar />
      </div>

      <div className="col-span-4">
        <Header />
        <Outlet />
      </div>
    </div>
  );
};

export default Layout;
