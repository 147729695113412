import { createSlice } from "@reduxjs/toolkit";
import getKyc from "../services/getKyc";
import { RootState } from "../store/store";
import { initialState } from "./stateSlice";
import GetKycLevel3Details from "../services/GetKycLevel3Details";
const state: initialState = {
  status: "idle",
  data: [],
  error: false,
};

const GetKycLevel3DetailsSlice = createSlice({
  name: "get/detials/kyc/3",
  initialState: state,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(GetKycLevel3Details.pending, (state) => {
        state.status = "loading";
      })
      .addCase(GetKycLevel3Details.fulfilled, (state, action) => {
        state.status = "success";
        state.data = action.payload.Result;
      })
      .addCase(GetKycLevel3Details.rejected, (state) => {
        state.status = "error";
        state.error = true;
      })
      .addDefaultCase((state) => ({ ...state }));
  },
});

export const selectKycDetails3 = (state: RootState) => {
  return state.getKycLevel3DetailsSlice.data;
};

export default GetKycLevel3DetailsSlice.reducer;
