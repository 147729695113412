import { createAsyncThunk } from "@reduxjs/toolkit";
import partnerHttpRequest from "../Apicall/partnerHttpRequest";
import { GET_PARTNER_USER } from "../endpoint/constant";

const getPartnerUser = createAsyncThunk<any, { ID: string }>('get/user/role', async (data, { rejectWithValue }) => {
    try {
        const response = await partnerHttpRequest.post(GET_PARTNER_USER, data)
        const { ErrorCode } = response.data;
        if (ErrorCode !== 200) {
            throw new Error("Internal server error")
        }
        return response.data
    } catch (error: any) {
        return rejectWithValue(error)
    }
})
export default getPartnerUser;