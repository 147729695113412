import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useAppDispatch, useAppSelector } from "../../../../redux/hook/hooks";
import getSchoolQuizDetails from "../../../../redux/services/getSchoolQuizDetails";
import { selectSchoolQuizDetails } from "../../../../redux/slice/getSchoolQuizDetailSlice";
import ChartComponent from "../../../Chart/ChartComponent";
interface RowProps {
  row: any;
}

const Schoolkyc: React.FC<RowProps> = ({ row }) => {
  const [chartValue, setChartValue] = useState<any>([]);
  const [chartLabel, setChartLabel] = useState<any>([]);
  const QuizColumn = [
    {
      name: "Quiz_Request_for_viva",
      selector: (row: any) => row.Quiz_Request_for_viva,
      sortable: true,
    },
    {
      name: "quiz_Approve",
      selector: (row: any) => row.quiz_Approve,
    },
    {
      name: "quiz_Disapprove",
      selector: (row: any) => row.quiz_Disapprove,
    },
  ];
  const dispatch = useAppDispatch();
  const SchoolQuizData = useAppSelector(selectSchoolQuizDetails);
  const handleSchoolQuizDetail = async () => {
    try {
      const credential = {
        School: row.school_id,
        Filter: row.Filter,
      };
      await dispatch(getSchoolQuizDetails(credential)).unwrap();
    } catch (error) {}
  };
  useEffect(() => {
    handleSchoolQuizDetail();
  }, []);
  useEffect(() => {
    if (SchoolQuizData.length > 0) {
      const keys = Object.keys(SchoolQuizData[0]).filter(
        (item) => item !== "school_id" && item !== "Filter"
      );
      setChartLabel(keys);
      const values = [];
      for (let property in SchoolQuizData[0]) {
        if (property !== "school_id" && property !== "Filter") {
          values.push(SchoolQuizData[0][property]);
        }
      }
      setChartValue(values);
    }
  }, [SchoolQuizData]);
  return (
    <div>
      <div className="pt-[15px]">
        <h1 className="text-2xl">School Quiz Detail</h1>
        <div>
          <DataTable
            className="z-0"
            columns={QuizColumn}
            data={SchoolQuizData}
            fixedHeader
          />
          <ChartComponent chartValue={chartValue} chartLabel={chartLabel} />
        </div>
      </div>
    </div>
  );
};

export default Schoolkyc;
