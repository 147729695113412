import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hook/hooks";
import getPartnerSource from "../../redux/services/getPartnerSource";
import { selectPartnerSource } from "../../redux/slice/getPartnerSourceSlice";
import {
  BiLogoReact,
  BiLogoAngular,
  BiLogoPhp,
  BiLogoAndroid,
  BiSolidFilePdf,
} from "react-icons/bi";
import { SiDotnet, SiPostman } from "react-icons/si";
import Button from "../../component/button/Button";
import DownloadPartnerIntegrationKit from "../../redux/services/DownloadPartnerIntegrationKit";

const PartnerConfig = () => {
  const [progress, setProgress] = useState(0);
  const [reactprogress, setReactProgress] = useState(0);
  const [phpprogress, setPhpProgress] = useState(0);
  const [dotnetprogress, setdotnetProgress] = useState(0);
  const [postmanprogress, setpostmanProgress] = useState(0);
  const [pdfprogress, setpdfProgress] = useState(0);
  const [androidprogress, setandroidProgress] = useState(0);
  const [sdkprogress, setsdkProgress] = useState(0);
  const [isFileDownloaded, setIsFileDownloaded] = useState(0);
  const dispatch = useAppDispatch();
  const selector = useAppSelector(selectPartnerSource);
  const getSource = async () => {
    const roleId = localStorage.getItem("r_id");
    const partnerCreateBy = localStorage.getItem("partnerCreateBy");

    try {
      const ID =
        roleId !== "4" ? partnerCreateBy : localStorage.getItem("p_id");
      if (ID) {
        const response = await dispatch(getPartnerSource({ ID })).unwrap();
        const { Result } = response;
        localStorage.setItem("logo", `${Result[0].PartnerLogo}`);
        localStorage.setItem("name", Result[0].Name);
        localStorage.setItem("MOUPath", Result[0].MOUPath);
        const data = Result?.map((item: any) => {
          return item.PartnerSource;
        });
        const commaSepratedPartnerSource = data
          .map((item: any) => `'${item}'`)
          .join(",");
        localStorage.setItem("partnerSource", commaSepratedPartnerSource);
      }
    } catch (error) {}
  };
  const downloadSourceCode = async (type: string, downloadType: string) => {
    try {
      await dispatch(
        DownloadPartnerIntegrationKit({
          type,
          downloadType,
          setProgress,
          setReactProgress,
          setPhpProgress,
          setdotnetProgress,
          setpostmanProgress,
          setsdkProgress,
          setandroidProgress,
          setpdfProgress,
          setIsFileDownloaded,
        })
      ).unwrap();
    } catch (error) {}
  };
  useEffect(() => {
    getSource();
  }, []);
  useEffect(() => {
    if (isFileDownloaded) {
      setProgress(0);
      setReactProgress(0);
      setPhpProgress(0);
      setdotnetProgress(0);
      setandroidProgress(0);
      setpdfProgress(0);
      setsdkProgress(0);
      setpostmanProgress(0);
    }
  }, [isFileDownloaded]);

  return (
    <div className="">
      <div className="flex flex-col">
        <div className="flex flex-col items-center my-[30px]">
          <div className="flex">
            <h1 className="text-[#000] font-medium">Api key : </h1>&nbsp;
            <p className="text-[#5c5353]"> {selector[0]?.APIKey} </p>
          </div>
          <div className="flex">
            <p className="text-[#000] font-medium">Partner Source : </p>&nbsp;
            {selector?.map((item: any, index: number) => {
              return (
                <div key={index}>
                  <p>{item.PartnerSource}&nbsp; </p>
                </div>
              );
            })}
          </div>
        </div>
        <div className="my-[20px] mx-[50px]">
          <h1 className="text-left text-[30px]  ml-[40px] text-[#004dcb]">
            Web
          </h1>
          <div className="flex flex-wrap">
            <div className="basis-1/2 mx-auto p-4">
              <div className="bg-white border border-gray-200 rounded-[10px] shadow-md p-5 flex flex-col items-center mx-[20px]">
                <BiLogoReact size={56} />
                <div className="p-2 text-center">
                  <p className="text-gray-700 text-center text-[22px]">
                    React source code
                  </p>
                  <Button
                    type="button"
                    onClick={() => {
                      downloadSourceCode("react", "zip");
                    }}
                    buttonContent={
                      reactprogress
                        ? `${reactprogress.toFixed(2)} MB downloaded`
                        : "Download"
                    }
                    className="bg-blue-500 hover:bg-blue-600 text-white py-1 px-3 mt-4 rounded"
                  />
                  {/* <button >
                  Download
                </button> */}
                </div>
              </div>
            </div>
            <div className="basis-1/2 mx-auto p-4">
              <div className="bg-white border border-gray-200 rounded-[10px] shadow-md p-5 flex flex-col items-center mx-[20px]">
                <BiLogoAngular size={56} />
                <div className="p-2 text-center">
                  <p className="text-gray-700">Angular source code</p>
                  <Button
                    type="button"
                    onClick={() => {
                      downloadSourceCode("angular", "zip");
                    }}
                    buttonContent={
                      progress
                        ? `${progress.toFixed(2)} MB downloaded`
                        : "Download"
                    }
                    className="bg-blue-500 hover:bg-blue-600 text-white py-1 px-3 mt-4 rounded"
                  />
                  {/* <div>{`${progress}% downloaded`}</div> */}
                </div>
              </div>
            </div>
            <div className="basis-1/2 mx-auto p-4">
              <div className="bg-white border border-gray-200 rounded-[10px] shadow-md p-5 flex flex-col items-center mx-[20px]">
                <BiLogoPhp size={56} />
                <div className="p-2 text-center">
                  <p className="text-gray-700">Php source code</p>
                  <Button
                    type="button"
                    onClick={() => {
                      downloadSourceCode("php", "zip");
                    }}
                    buttonContent={
                      phpprogress
                        ? `${phpprogress.toFixed(2)} MB downloaded`
                        : "Download"
                    }
                    className="bg-blue-500 hover:bg-blue-600 text-white py-1 px-3 mt-4 rounded"
                  />
                </div>
              </div>
            </div>
            <div className="basis-1/2 mx-auto p-4">
              <div className="bg-white border border-gray-200 rounded-[10px] shadow-md p-5 flex flex-col items-center mx-[20px]">
                <SiDotnet size={56} />
                <div className="p-2 text-center">
                  <p className="text-gray-700">Dotnet source code</p>
                  <Button
                    type="button"
                    onClick={() => {
                      downloadSourceCode("dotnet", "zip");
                    }}
                    buttonContent={
                      dotnetprogress
                        ? `${dotnetprogress.toFixed(2)} MB downloaded`
                        : "Download"
                    }
                    className="bg-blue-500 hover:bg-blue-600 text-white py-1 px-3 mt-4 rounded"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="my-[20px] mx-[50px]">
          <h1 className="text-left text-[30px]  ml-[40px] text-[#004dcb]">
            Pdf
          </h1>
          <div className="flex flex-wrap">
            {/* <h1>Pdf download</h1> */}
            <div className="basis-1/2 mx-auto p-4">
              <div className="bg-white border border-gray-200 rounded-[10px] shadow-md p-5 flex flex-col items-center mx-[20px]">
                <SiPostman size={56} />
                <div className="p-2 text-center">
                  <p className="text-gray-700">Postman collection</p>
                  <Button
                    type="button"
                    onClick={() => {
                      downloadSourceCode("postman", "json");
                    }}
                    buttonContent={
                      postmanprogress
                        ? `${postmanprogress.toFixed(2)} MB downloaded`
                        : "Download"
                    }
                    className="bg-blue-500 hover:bg-blue-600 text-white py-1 px-3 mt-4 rounded"
                  />
                  {/* <button >
                  Download
                </button> */}
                </div>
              </div>
            </div>
            <div className="basis-1/2 mx-auto p-4">
              <div className="bg-white border border-gray-200 rounded-[10px] shadow-md p-5 flex flex-col items-center mx-[20px]">
                <BiSolidFilePdf size={56} />
                <div className="p-2 text-center">
                  <p className="text-gray-700">pdf</p>
                  <Button
                    type="button"
                    onClick={() => {
                      downloadSourceCode("pdf", "pdf");
                    }}
                    buttonContent={
                      pdfprogress
                        ? `${pdfprogress.toFixed(2)} MB downloaded`
                        : "Download"
                    }
                    className="bg-blue-500 hover:bg-blue-600 text-white py-1 px-3 mt-4 rounded"
                  />
                  {/* <button >
                  Download
                </button> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className=" my-[20px] mx-[50px]">
          <h1 className="text-left text-[30px]  ml-[40px] text-[#004dcb]">
            Android
          </h1>
          <div className="flex flex-wrap">
            <div className="basis-1/2 mx-auto p-4">
              <div className="bg-white border border-gray-200 rounded-[10px] shadow-md p-5 flex flex-col items-center mx-[20px] p-[15px] text-center p-[15px] ">
                <SiPostman size={56} />
                <div className="p-2 text-center">
                  <p className="text-gray-700">Android</p>
                  <Button
                    type="button"
                    onClick={() => {
                      downloadSourceCode("android", "json");
                    }}
                    buttonContent={
                      androidprogress
                        ? `${androidprogress.toFixed(2)} MB downloaded`
                        : "Download"
                    }
                    className="bg-blue-500 hover:bg-blue-600 text-white py-1 px-3 mt-4 rounded"
                  />
                  {/* <button >
                  Download
                </button> */}
                </div>
              </div>
            </div>

            <div className="basis-1/2 mx-auto p-4">
              <div className="bg-white border border-gray-200 rounded-[10px] shadow-md p-5 flex flex-col items-center mx-[20px] p-[15px] text-center p-[15px]">
                <BiSolidFilePdf size={56} />
                <div className="p-2 text-center">
                  <p className="text-gray-700">Sdk</p>
                  <Button
                    type="button"
                    onClick={() => {
                      downloadSourceCode("sdk", "txt");
                    }}
                    buttonContent={
                      sdkprogress
                        ? `${sdkprogress.toFixed(2)} MB downloaded`
                        : "Download"
                    }
                    className="bg-blue-500 hover:bg-blue-600 text-white py-1 px-3 mt-4 rounded"
                  />
                  {/* <button >
                  Download
                </button> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PartnerConfig;
