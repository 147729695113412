import { createAsyncThunk } from "@reduxjs/toolkit";
import partnerHttpRequest from "../Apicall/partnerHttpRequest";
import { GET_ROLE_MENU } from "../endpoint/constant";

const getRoleMenuMapping = createAsyncThunk<any, { ID: string }>('/get/role/menu', async (data, { rejectWithValue }) => {

    try {
        const response = await partnerHttpRequest.post(GET_ROLE_MENU, data)
        return response.data
    } catch (error) {

    }

})
export default getRoleMenuMapping;
