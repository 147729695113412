import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/hook/hooks";
import getGrade from "../../../redux/services/getGrade";
import getState from "../../../redux/services/getState";
import { selectGrade } from "../../../redux/slice/gradeSlice";
import { selectState } from "../../../redux/slice/stateSlice";
import Select from "react-select";
import { selectKyc } from "../../../redux/slice/getKycSlice";
import getKyc from "../../../redux/services/getKyc";
import getMultiDistrict from "../../../redux/services/getMultiDistrict";
import { selectMultiDistrict } from "../../../redux/slice/multiDistrictSlice";
import QuizTrackInitialReportByPartnerSource from "../../../redux/services/QuizTrackInitialReportByPartnerSource";
import { SelectQuizReportInitially } from "../../../redux/slice/QuizTrackInitialReportByPartnerSourceSlice";
import Table from "../../Table/Table";
import Button from "../../../component/button/Button";
import QuizTaking from "../../../redux/services/QuizTaking";
import quizByStudentClass from "../../../redux/services/quizByStudentClass";
import quizBySubjectList from "../../../redux/services/quizBySubjectList";
import QuizByKycStatus from "../../../redux/services/QuizByKycStatus";
import { SelectQuizTaking } from "../../../redux/slice/QuizTakingSlice";
import { SelectQuizByStudentClass } from "../../../redux/slice/quizByStudentClassSlice";
import { SelectQuizBySubjectList } from "../../../redux/slice/quizBySubjectListSlice";
import { SelectQuizByKycStatus } from "../../../redux/slice/QuizByKycStatusSlice";
import { messagesType } from "../../role/CreateRole";
// import Message from "../../../utilities/Message";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router";

const StaticColumnCell2 = ({ value, type, name, data }: any) => {
  const navigate = useNavigate();
  const finalQuery = (param1?: string, param2?: string, param3?: string) => {
    let final = "";
    if (param1 && param2 && param3) {
      final = param1 + param2 + param3;
      return final;
    } else if (param1 && param2) {
      final = param1 + param2;
      return final;
    } else if (param2 && param3) {
      final = param1 + param2 + param3;
      return final;
    } else if (param1) {
      final += param1;
      return final;
    } else if (param2) {
      final = param1 + param2;
      return final;
    } else if (param3) {
      final = param1 + param3;
      return final;
    }
    return final;
  };
  const addQuery = (type: string, filter1: string, filter2: string) => {
    let filter = "";
    if (type === "Student count") {
      filter = finalQuery(filter1, filter2);
    } else if (type === "Quiz count") {
      filter = finalQuery(filter1);
    } else if (type === "Eligible quiz student count") {
      filter = finalQuery(filter1, " and score>7 ", filter2);
    } else {
      filter = finalQuery(filter1, " and score>7");
    }
    return filter;
  };
  const navigateToDetails = (type: any) => {
    if (type.param === "quiz taker 1-2") {
      const Filter = addQuery(
        type.label,
        "quiz_attempt BETWEEN '1' AND '2' ",
        "group by 'user_id'"
      );

      const ParamFilter = {
        Filter: data.Filter,
        ParamFilter: Filter,
      };

      navigate("/partner/details", { state: ParamFilter });
    } else if (type.param === "quiz taker 3-5") {
      const Filter = addQuery(
        type.label,
        "quiz_attempt BETWEEN '3' AND '5' ",
        "group by 'user_id' "
      );

      const ParamFilter = {
        Filter: data.Filter,
        ParamFilter: Filter,
      };

      navigate("/partner/details", { state: ParamFilter });
    } else if (type.param === "quiz taker 6-10") {
      const Filter = addQuery(
        type.label,
        "quiz_attempt BETWEEN '6' AND '10' ",
        " group by 'user_id' "
      );
      const ParamFilter = {
        Filter: data.Filter,
        ParamFilter: Filter,
      };
      navigate("/partner/details", { state: ParamFilter });
    } else if (type.param === "quiz taker 11-20") {
      const Filter = addQuery(
        type.label,
        "quiz_attempt BETWEEN '11' AND '20' ",
        "group by 'user_id' "
      );

      const ParamFilter = {
        Filter: data.Filter,
        ParamFilter: Filter,
      };

      navigate("/partner/details", { state: ParamFilter });
    } else if (type.param === "quiz taker 20+") {
      const Filter = addQuery(
        type.label,
        " quiz_attempt > 20 ",
        "group by 'user_id' "
      );
      const ParamFilter = {
        Filter: data.Filter,
        ParamFilter: Filter,
      };
      navigate("/partner/details", { state: ParamFilter });
    } else {
    }
  };
  return value?.map((item: any) => {
    return (
      <>
        <span>{item?.data}</span>
        {type && (
          <Button
            buttonContent="Details"
            type="button"
            onClick={() => {
              navigateToDetails(item);
            }}
          />
        )}
      </>
    );
  });
};

const PartnerQuizzes = () => {
  const [search, setSearch] = useState({
    studentMobileNo: "",
    exam_compeleteFrom: "",
    exam_compeleteTo: "",
    subject: "",
    studentGrade: "",
    level2_kyc_verified: "",
    state_id: "",
    district_id: "",
  });
  const navigate = useNavigate();
  const [showMessage, setShowMessage] = useState<boolean>(false);

  const [messageType, setMessageType] = useState<messagesType>("");
  const [partnerSource, setPartnerSource] = useState({
    Filter: `student_exam.partner_source In (${localStorage.getItem(
      "partnerSource"
    )})`,
  });
  const dispatch = useAppDispatch();
  const stateOptions = useAppSelector(selectState);
  const districtOptions = useAppSelector(selectMultiDistrict);
  const gradeOptions = useAppSelector(selectGrade);
  const kycStatusOptions = useAppSelector(selectKyc);
  const quizReport = useAppSelector(SelectQuizReportInitially);
  const selectByQuizTaking = useAppSelector(SelectQuizTaking);
  const selectByQuizStudentClass = useAppSelector(SelectQuizByStudentClass);
  const selectByQuizSubjectList = useAppSelector(SelectQuizBySubjectList);
  const selectByQuiKycStatus = useAppSelector(SelectQuizByKycStatus);

  function getDate30DaysBefore() {
    var currentDate = new Date(); // Get the current date
    var previousDate = new Date(); // Create a new date object

    previousDate.setDate(currentDate.getDate() - 30); // Subtract 30 days from the current date

    return previousDate;
  }

  const convertToCorrectTime = (date: any) => {
    var options = {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      second: "2-digit",
      hour12: false,
    };

    var formattedDate = date.toLocaleString("en-US", options);
    const newFormate = formattedDate.split(",");
    const splitNewFormate = newFormate[0].split("/");
    const finalDate =
      splitNewFormate[2] +
      "-" +
      splitNewFormate[1] +
      "-" +
      splitNewFormate[0] +
      " " +
      newFormate[1];
    return finalDate;
  };

  const finalQuery = (param1?: string, param2?: string, param3?: string) => {
    let final = "";
    if (param1 && param2 && param3) {
      final = param1 + param2 + param3;
      return final;
    } else if (param1 && param2) {
      final = param1 + param2;
      return final;
    } else if (param2 && param3) {
      final = param1 + param2 + param3;
      return final;
    } else if (param1) {
      final += param1;
      return final;
    } else if (param2) {
      final = param1 + param2;
      return final;
    } else if (param3) {
      final = param1 + param3;
      return final;
    }
    return final;
  };
  const addQuery = (type: string, filter1: string, filter2: string) => {
    let filter = "";
    if (type === "Student count") {
      filter = finalQuery(filter1, filter2);
    } else if (type === "Quiz count") {
      filter = finalQuery(filter1);
    } else if (type === "Eligible quiz student count") {
      filter = finalQuery(filter1, " and score>7 ", filter2);
    } else {
      filter = finalQuery(filter1, " and score>7");
    }
    return filter;
  };
  const navigateToDetails = (type: any) => {
    if (type.param === "Total quiz initiated - not completed") {
      const Filter = addQuery(
        type.label,
        "complete_by not in ('student','auto submitted','auto_submitted','exit by student') ",
        "group by 'user_id' "
      );
      const ParamFilter = {
        Filter: partnerSource.Filter,
        ParamFilter: Filter,
      };

      navigate("/partner/details", { state: ParamFilter });
    } else if (type.param === "Total core quiz") {
      const Filter = addQuery(
        type.label,
        " quiz_attempt ='1'",
        "group by 'user_id' "
      );
      const ParamFilter = {
        Filter: partnerSource.Filter,
        ParamFilter: Filter,
      };

      navigate("/partner/details", { state: ParamFilter });
    } else if (type.param === "Total retake") {
      const Filter = addQuery(
        type.label,
        "quiz_attempt in ('2', '3')",
        " group by 'user_id' "
      );
      const ParamFilter = {
        Filter: partnerSource.Filter,
        ParamFilter: Filter,
      };

      navigate("/partner/details", { state: ParamFilter });
    } else if (type.param === "Total quiz") {
      const Filter = addQuery(
        type.label,
        "quiz_attempt in ('2', '3')",
        "group by 'user_id' "
      );
      const ParamFilter = {
        Filter: partnerSource.Filter,
        ParamFilter: Filter,
      };

      navigate("/partner/details", { state: ParamFilter });
    } else if (type.param === "More than 1 month old registration") {
      let previousDate = getDate30DaysBefore();
      const date = new Date(previousDate);
      const correctDate = convertToCorrectTime(date);
      const Filter = addQuery(
        type.label,
        `student_exam.registration_date < '${correctDate}'`,
        " group by 'user_id'"
      );
      const ParamFilter = {
        Filter: partnerSource.Filter,
        ParamFilter: Filter,
      };

      navigate("/partner/details", { state: ParamFilter });
    } else {
      let previousDate = getDate30DaysBefore();
      const date = new Date(previousDate);
      const correctDate = convertToCorrectTime(date);
      const currentDate = new Date();
      const correctCurrentDateFomat = convertToCorrectTime(currentDate);
      const Filter = addQuery(
        type.label,
        `student_exam.registration_date >= '${correctDate}' and student_exam.registration_date < '${correctCurrentDateFomat}'`,
        "group by 'user_id'"
      );
      const ParamFilter = {
        Filter: partnerSource.Filter,
        ParamFilter: Filter,
      };

      navigate("/partner/details", { state: ParamFilter });
    }
  };
  const navigateToDetails2 = (type: any) => {
    if (type.param === "quiz taker 1-2") {
      const Filter = addQuery(
        type.label,
        "quiz_attempt BETWEEN '1' AND '2' ",
        "group by 'user_id'"
      );

      const ParamFilter = {
        Filter: partnerSource.Filter,
        ParamFilter: Filter,
      };

      navigate("/partner/details", { state: ParamFilter });
    } else if (type.param === "quiz taker 3-5") {
      const Filter = addQuery(
        type.label,
        "quiz_attempt BETWEEN '3' AND '5' ",
        "group by 'user_id' "
      );

      const ParamFilter = {
        Filter: partnerSource.Filter,
        ParamFilter: Filter,
      };

      navigate("/partner/details", { state: ParamFilter });
    } else if (type.param === "quiz taker 6-10") {
      const Filter = addQuery(
        type.label,
        "quiz_attempt BETWEEN '6' AND '10' ",
        " group by 'user_id' "
      );
      const ParamFilter = {
        Filter: partnerSource.Filter,
        ParamFilter: Filter,
      };
      navigate("/partner/details", { state: ParamFilter });
    } else if (type.param === "quiz taker 11-20") {
      const Filter = addQuery(
        type.label,
        "quiz_attempt BETWEEN '11' AND '20' ",
        "group by 'user_id' "
      );

      const ParamFilter = {
        Filter: partnerSource.Filter,
        ParamFilter: Filter,
      };

      navigate("/partner/details", { state: ParamFilter });
    } else if (type.param === "quiz taker 20+") {
      const Filter = addQuery(
        type.label,
        " quiz_attempt > 20 ",
        "group by 'user_id' "
      );
      const ParamFilter = {
        Filter: partnerSource.Filter,
        ParamFilter: Filter,
      };
      navigate("/partner/details", { state: ParamFilter });
    } else {
    }
  };
  const column = [
    {
      name: "ID",
      selector: (row: any) => row.id,
    },
    {
      name: "Parameters",
      selector: (row: any) => row.label,
    },
    {
      name: "Student count",
      id: "Student count",
      selector: (row: any) => row.student_count,
      style: { cursor: "pointer" },
      cell: (row: any, index: number, col: any) => (
        <p
          onClick={() => {
            navigateToDetails({ label: col.name, param: row.label });
          }}
        >
          {row.student_count}
        </p>
      ),
    },
    {
      name: "Quiz count",
      id: "Quiz count",
      selector: (row: any) => row.quiz_count,
      style: { cursor: "pointer" },
      cell: (row: any, index: number, col: any) => (
        <p
          onClick={() => {
            navigateToDetails({ label: col.name, param: row.label });
          }}
        >
          {row.quiz_count}
        </p>
      ),
    },
    {
      name: "Eligible quiz student count",
      id: "Eligible quiz student count",
      selector: (row: any) => row.Eligible_quiz_student_count,
      style: { cursor: "pointer" },
      cell: (row: any, index: number, col: any) => (
        <p
          onClick={() => {
            navigateToDetails({ label: col.name, param: row.label });
          }}
        >
          {row.Eligible_quiz_student_count}
        </p>
      ),
    },
    {
      name: "Eligible quiz",
      id: "Eligible quiz",
      selector: (row: any) => row.Eligible_quiz,
      style: { cursor: "pointer" },
      cell: (row: any, index: number, col: any) => (
        <p
          onClick={() => {
            navigateToDetails({ label: col.name, param: row.label });
          }}
        >
          {row.Eligible_quiz}
        </p>
      ),
    },
  ];
  const column2 = [
    {
      name: "ID",
      selector: (row: any) => row.id,
      id: "1",
    },
    {
      name: "Parameters",
      id: "2",
      selector: (row: any) => row.label,
    },
    {
      name: "Student count",
      id: "3",
      selector: (row: any) => row.student_count,
      cell: (row: any, index: number, col: any) => {
        return (
          <p
            onClick={() => {
              navigateToDetails2({ label: col.name, param: row.label });
            }}
          >
            {row.student_count}
          </p>
        );
      },
      style: {
        cursor: "pointer",
      },
    },
    {
      name: "Eligible quiz student count",
      id: "5",
      selector: (row: any) => row.Eligible_quiz_student_count,
      style: {
        cursor: "pointer",
      },
      cell: (row: any, index: number, col: any) => {
        return (
          <p
            onClick={() => {
              navigateToDetails2({ label: col.name, param: row.label });
            }}
          >
            {row.Eligible_quiz_student_count}
          </p>
        );
      },
    },
    {
      name: "Eligible quiz",
      id: "6",
      selector: (row: any) => row.Eligible_quiz,
      style: {
        cursor: "pointer",
      },
      cell: (row: any, index: number, col: any) => {
        return (
          <p
            onClick={() => {
              navigateToDetails2({ label: col.name, param: row.label });
            }}
          >
            {row.Eligible_quiz}
          </p>
        );
      },
    },
  ];
  const column3 = [
    {
      name: "ID",
      selector: (row: any) => row.all_class,
      sortable: true,
    },
    {
      name: "Parameters",
      selector: (row: any) => `Grade-${row.all_class}`,
      sortable: true,
    },
    {
      name: "Student count",
      selector: (row: any) => row.all_class_student,
      style: {
        cursor: "pointer",
      },
      cell: (row: any) => (
        <div className="flex flex-col">
          <p
            onClick={() => {
              const { Filter } = partnerSource;
              const ParamFilter = {
                Filter,
                ParamFilter: `student_class='${row.all_class}' group by 'user_id'`,
              };
              navigate("/partner/details", { state: ParamFilter });
            }}
          >
            {row.all_class_student}
          </p>
        </div>
      ),
      sortable: true,
    },
    {
      name: "	Quiz count",
      selector: (row: any) => row.all_class_quiz,
      style: {
        cursor: "pointer",
      },
      cell: (row: any) => (
        <div className="flex flex-col">
          <p
            onClick={() => {
              const { Filter } = partnerSource;
              const ParamFilter = {
                Filter,
                ParamFilter: `student_class='${row.all_class}' `,
              };
              navigate("/partner/details", { state: ParamFilter });
            }}
          >
            {row.all_class_quiz}
          </p>
        </div>
      ),
      sortable: true,
    },
    {
      name: "Eligible quiz student count",
      selector: (row: any) => row.all_class_quiz_winner_student,
      style: {
        cursor: "pointer",
      },
      cell: (row: any) => (
        <div className="flex flex-col">
          <p
            onClick={() => {
              const { Filter } = partnerSource;
              const ParamFilter = {
                Filter,
                ParamFilter: `student_class='${row.all_class}' AND score>'7' group by 'user_id'  `,
              };
              navigate("/partner/details", { state: ParamFilter });
            }}
          >
            {row.all_class_quiz_winner_student}
          </p>
        </div>
      ),
      sortable: true,
    },
    {
      name: "Eligible quiz",
      selector: (row: any) => row.all_class_quiz_winner,
      style: {
        cursor: "pointer",
      },
      cell: (row: any) => (
        <div className="flex flex-col">
          <p
            onClick={() => {
              const { Filter } = partnerSource;
              const ParamFilter = {
                Filter,
                ParamFilter: `student_class='${row.all_class}' AND score>'7' `,
              };
              navigate("/partner/details", { state: ParamFilter });
            }}
          >
            {row.all_class_quiz_winner}
          </p>
        </div>
      ),
      sortable: true,
    },
  ];
  const column4 = [
    {
      name: "Parameters",
      selector: (row: any) => row.subject,

      sortable: true,
    },
    {
      name: "Student count",
      selector: (row: any) => row.all_subject_student,
      cell: (row: any) => (
        <div className="flex flex-col">
          <p
            onClick={() => {
              const { Filter } = partnerSource;
              const ParamFilter = {
                Filter,
                ParamFilter: `student_subject='${row.subject}' group by 'user_id' `,
              };
              navigate("/partner/details", { state: ParamFilter });
            }}
          >
            {row.all_subject_student}
          </p>
        </div>
      ),
      style: {
        cursor: "pointer",
      },

      sortable: true,
    },
    {
      name: "	Quiz count",
      selector: (row: any) => row.all_subject_quiz,
      cell: (row: any) => (
        <div className="flex flex-col">
          <p
            onClick={() => {
              const { Filter } = partnerSource;
              const ParamFilter = {
                Filter,
                ParamFilter: `student_subject='${row.subject}' `,
              };
              navigate("/partner/details", { state: ParamFilter });
            }}
          >
            {row.all_subject_quiz}
          </p>
        </div>
      ),
      style: {
        cursor: "pointer",
      },
      sortable: true,
    },
    {
      name: "Eligible quiz student count",
      selector: (row: any) => row.all_subject_quiz_winner_student,
      cell: (row: any) => (
        <div className="flex flex-col">
          <p
            onClick={() => {
              const { Filter } = partnerSource;
              const ParamFilter = {
                Filter,
                ParamFilter: `student_subject='${row.subject}' AND score>'7' group by 'user_id' `,
              };
              navigate("/partner/details", { state: ParamFilter });
            }}
          >
            {row.all_subject_quiz_winner_student}
          </p>
        </div>
      ),
      style: {
        cursor: "pointer",
      },
      sortable: true,
    },
    {
      name: "Eligible quiz",
      selector: (row: any) => row.all_subject_quiz_winner,
      cell: (row: any) => (
        <div className="flex flex-col">
          <p
            onClick={() => {
              const { Filter } = partnerSource;
              const ParamFilter = {
                Filter,
                ParamFilter: `student_subject='${row.subject}' AND score>'7' `,
              };
              navigate("/partner/details", { state: ParamFilter });
            }}
          >
            {row.all_subject_quiz_winner}
          </p>
        </div>
      ),
      style: {
        cursor: "pointer",
      },
      sortable: true,
    },
  ];
  const column5 = [
    {
      name: "Parameters",
      selector: (row: any) => row.level2_kyc_verified,
      style: {
        cursor: "pointer",
      },
      sortable: true,
    },
    {
      name: "Student count",
      selector: (row: any) => row.all_kyc_student,
      style: {
        cursor: "pointer",
      },
      cell: (row: any) => (
        <div className="flex flex-col">
          <p
            onClick={() => {
              const { Filter } = partnerSource;
              const ParamFilter = {
                Filter,
                ParamFilter: `student_kyc='${row.level2_kyc_verified}' group by 'user_id' `,
              };
              navigate("/partner/details", { state: ParamFilter });
            }}
          >
            {row.all_kyc_student}
          </p>
        </div>
      ),
      sortable: true,
    },
    {
      name: "	Quiz count",
      selector: (row: any) => row.all_kyc_quiz,
      style: {
        cursor: "pointer",
      },
      cell: (row: any) => (
        <div className="flex flex-col">
          <p
            onClick={() => {
              const { Filter } = partnerSource;
              const ParamFilter = {
                Filter,
                ParamFilter: `student_kyc='${row.level2_kyc_verified}' `,
              };
              navigate("/partner/details", { state: ParamFilter });
            }}
          >
            {row.all_kyc_quiz}
          </p>
        </div>
      ),
      sortable: true,
    },
    {
      name: "Eligible quiz student count",
      selector: (row: any) => row.all_kyc_winner_student,
      style: {
        cursor: "pointer",
      },
      cell: (row: any) => (
        <div className="flex flex-col">
          <p
            onClick={() => {
              const { Filter } = partnerSource;
              const ParamFilter = {
                Filter,
                ParamFilter: `student_kyc='${row.level2_kyc_verified}' AND score>'7' group by 'user_id' `,
              };
              navigate("/partner/details", { state: ParamFilter });
            }}
          >
            {row.all_kyc_winner_student}
          </p>
        </div>
      ),
      sortable: true,
    },
    {
      name: "Eligible quiz",
      selector: (row: any) => row.all_kyc_winner,
      cell: (row: any) => (
        <div className="flex flex-col">
          <p
            onClick={() => {
              const { Filter } = partnerSource;
              const ParamFilter = {
                Filter,
                ParamFilter: `student_kyc='${row.level2_kyc_verified}' AND score>'7' `,
              };
              navigate("/partner/details", { state: ParamFilter });
            }}
          >
            {row.all_kyc_winner}
          </p>
        </div>
      ),
      sortable: true,
    },
  ];
  const handleChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
  ) => {
    setSearch({ ...search, [e.target.name]: e.target.value });
  };
  const handleState = async () => {
    try {
      await dispatch(getState()).unwrap();
    } catch (error) {}
  };
  const handleMultiDistrict = async () => {
    try {
      const { state_id } = search;
      const data = { ID: state_id };
      await dispatch(getMultiDistrict(data));
    } catch (error) {}
  };
  const handleGrade = async () => {
    try {
      await dispatch(getGrade()).unwrap();
    } catch (error) {}
  };
  const handleKycStatus = async () => {
    try {
      await dispatch(getKyc()).unwrap();
    } catch (error) {}
  };
  const getInitialQuizReport = async (data: any) => {
    try {
      await dispatch(QuizTrackInitialReportByPartnerSource(data)).unwrap();
    } catch (error) {}
  };
  const handleSearchButton = (show: boolean) => {
    let condition = `student_exam.partner_source In (${localStorage.getItem(
      "partnerSource"
    )})`;

    if (search.studentMobileNo) {
      condition += `and student_exam.mobile_no = '${search.studentMobileNo}'`;
    }
    if (search.state_id) {
      condition += `and student_exam.state_id In (${search.state_id}) `;
    }
    if (search.studentGrade) {
      condition += `and student_exam.student_class In(${search.studentGrade}) `;
    }
    if (search.subject) {
      condition += `and student_exam.subject In (${search.subject}) `;
    }
    if (search.district_id) {
      condition += `and student_exam.district_id In (${search.district_id}) `;
    }
    if (search.exam_compeleteFrom || search.exam_compeleteTo) {
      condition += `and student_exam.exam_compelete >= '${search.exam_compeleteFrom}' and student_exam.exam_compelete <= '${search.exam_compeleteTo}' `;
    }
    const filterObject = { Filter: condition };
    setPartnerSource({ ...partnerSource, Filter: condition });
    if (show) {
      getInitialQuizReport(filterObject);
    }

    return condition;
  };
  useEffect(() => {
    setPartnerSource({
      ...partnerSource,
      Filter: "student_exam.partner_source In ('Aimify_WEB') ",
    });
    handleState();
    handleGrade();
    handleKycStatus();
    // getInitialQuizReport(partnerSource);
  }, []);
  useEffect(() => {
    if (search.state_id) {
      handleMultiDistrict();
    }
  }, [search.state_id]);

  const extractMultiId = (data: any) => {
    const ids = data?.map((item: any) => `'${item.value}'`).toString();
    return ids;
  };

  const studentQuizTaking = async () => {
    setShowMessage(false);
    const Filter = handleSearchButton(false);
    try {
      if (search.exam_compeleteFrom && search.exam_compeleteTo) {
        const result = await dispatch(QuizTaking({ Filter })).unwrap();
        if (result.Result.length === 0) {
          setShowMessage(true);
          toast.warn("Data not found");
        }
      } else {
        setShowMessage(true);
        // setMessage("Date is required");
        toast.warn("Date is required");
        setMessageType("warn");
      }
    } catch (error) {}
  };

  const quizbyStudentClass = async () => {
    setShowMessage(false);
    const Filter = handleSearchButton(false);
    try {
      if (search.exam_compeleteFrom && search.exam_compeleteTo) {
        const result = await dispatch(quizByStudentClass({ Filter })).unwrap();
        console.log(result.Result.length, "resulltt");
        if (result?.Result.length === 0) {
          setShowMessage(true);
          toast.warning("Data not found");
        }
      } else {
        setShowMessage(true);
        toast.warn("Date is required");
        setMessageType("warn");
      }
    } catch (error) {}
  };

  const quizbySubjectList = async () => {
    setShowMessage(false);
    const Filter = handleSearchButton(false);
    try {
      if (search.exam_compeleteFrom && search.exam_compeleteTo) {
        const result = await dispatch(quizBySubjectList({ Filter })).unwrap();
        if (result.Result.length === 0) {
          setShowMessage(true);
          toast.warn("Data not found");
        }
      } else {
        setShowMessage(true);
        toast.warn("Date is required");
        setMessageType("warn");
      }
    } catch (error) {}
  };
  const quizbyKycStatus = async () => {
    setShowMessage(false);
    const Filter = handleSearchButton(false);
    try {
      if (search.exam_compeleteFrom && search.exam_compeleteTo) {
        const result = await dispatch(QuizByKycStatus({ Filter })).unwrap();
        if (result.Result.length === 0) {
          setShowMessage(true);
          toast.warn("Data not found");
        }
      } else {
        setShowMessage(true);
        // setMessage("Date is required");
        toast.warn("Date is required");
        setMessageType("warn");
      }
    } catch (error) {}
  };
  console.log(
    selectByQuizStudentClass?.data?.Result,
    "selectByQuizStudentClass?.data?.Result"
  );
  return (
    <>
      <div className="px-8">
        <div className="pt-[15px]">
          <h1 className="text-2xl">Partner Student Details Dashboards</h1>
        </div>
        <form>
          <div className="grid grid-cols-4 gap-4 mt-[12px]">
            <div className="col-span-1 h-[75vh] overflow-y-scroll">
              <div
                className="flex justify-between
          "
              >
                {/* <Button buttonContent={"Search"} /> */}
                <h1 className="flex items-center">Filter</h1>
                <button
                  className="px-[10px] bg-blue-500 hover:bg-blue-700 text-white rounded-[10px]"
                  type="button"
                  onClick={() => {
                    handleSearchButton(true);
                  }}
                >
                  Search{" "}
                </button>
              </div>
              <div className="py-2.5 pr-[5px] items-end [&_div]:py-[5px] ">
                <div>
                  <input
                    className="px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400 w-full"
                    type="text"
                    name="studentMobileNo"
                    placeholder="Student Mobile No."
                    value={search.studentMobileNo}
                    onChange={handleChange}
                    maxLength={10}
                  />
                </div>
                <div>
                  <label>Quiz from:</label>
                  <input
                    className="px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400 w-full"
                    type="date"
                    name="exam_compeleteFrom"
                    required
                    placeholder="exam_compeleteFrom"
                    onChange={(e) => {
                      setSearch({
                        ...search,
                        exam_compeleteFrom: `${e.target.value} 00:00:00`,
                      });
                    }}
                  />
                </div>
                <div>
                  <label>Quiz to:</label>
                  <input
                    className="px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400 w-full"
                    type="date"
                    name="exam_compeleteTo"
                    required
                    placeholder="exam_compeleteTo"
                    onChange={(e) => {
                      setSearch({
                        ...search,
                        exam_compeleteTo: `${e.target.value} 23:59:59`,
                      });
                    }}
                  />
                </div>
                <label>State:</label>
                <Select
                  name="studentState"
                  isMulti
                  options={stateOptions}
                  onChange={(selectedOption: any) => {
                    const val = extractMultiId(selectedOption);
                    setSearch({
                      ...search,
                      state_id: val,
                    });
                  }}
                />
                <label>District:</label>
                <Select
                  name="studentDistrict"
                  isMulti
                  options={districtOptions}
                  onChange={(selectedOption: any) => {
                    const val = extractMultiId(selectedOption);
                    setSearch({
                      ...search,
                      district_id: val,
                    });
                  }}
                />
                <label>Grade:</label>
                <Select
                  name="studentGrade"
                  isMulti
                  options={gradeOptions}
                  onChange={(selectedOption: any) => {
                    const val = extractMultiId(selectedOption);
                    setSearch({
                      ...search,
                      studentGrade: val,
                    });
                  }}
                />
                <label>KYC Status:</label>
                <Select
                  name="level2_kyc_verified"
                  isMulti
                  options={kycStatusOptions}
                  onChange={(selectedOption: any) => {
                    const val = extractMultiId(selectedOption);
                    setSearch({
                      ...search,
                      level2_kyc_verified: val,
                    });
                  }}
                />
              </div>
            </div>

            <div className="col-span-3">
              {!quizReport.includes(undefined) && (
                <Table data={quizReport} columns={column} />
              )}
              <div style={{ marginBottom: "30px", marginTop: "20px" }}>
                <div className="flex w-full items-center justify-around border-[#000] border-[1px] rounded-[10px] py-2 my-2">
                  <p>Student Quiz Taking</p>
                  <Button
                    buttonContent="Load Info"
                    type="button"
                    onClick={(e) => {
                      studentQuizTaking();
                    }}
                  />
                </div>
                {/* {selectByQuizTaking?.length > 0 ? (

                  <Table data={selectByQuizTaking} columns={column2} />
                ) : (
                  ""
                )} */}
                {!selectByQuizTaking?.includes(undefined) && (
                  <Table data={selectByQuizTaking} columns={column2} />
                )}
              </div>
              <div style={{ marginBottom: "30px", marginTop: "20px" }}>
                <div className="flex w-full items-center justify-around border-[#000] border-[1px] rounded-[10px] py-2 my-2">
                  <p>Student_Class</p>
                  <Button
                    buttonContent="Load Info"
                    type="button"
                    onClick={(e) => {
                      quizbyStudentClass();
                    }}
                  />
                </div>
                {selectByQuizStudentClass?.status === "loading" ? (
                  <p>Loading</p>
                ) : selectByQuizStudentClass?.data?.Result?.length > 0 ? (
                  <Table
                    data={selectByQuizStudentClass?.data?.Result}
                    columns={column3}
                  />
                ) : (
                  ""
                )}
              </div>
              <div style={{ marginBottom: "30px", marginTop: "20px" }}>
                <div className="flex w-full items-center justify-around border-[#000] border-[1px] rounded-[10px] py-2 my-2">
                  <p>Subject List</p>
                  <Button
                    buttonContent="Load Info"
                    type="button"
                    onClick={(e) => {
                      quizbySubjectList();
                    }}
                  />
                </div>
                {selectByQuizSubjectList?.status === "loading" ? (
                  <p>Loading</p>
                ) : selectByQuizSubjectList?.data?.Result?.length > 0 ? (
                  <Table
                    data={selectByQuizSubjectList?.data?.Result}
                    columns={column4}
                    noDataComponent="Data is not present"
                  />
                ) : (
                  ""
                )}
              </div>
              <div style={{ marginBottom: "30px", marginTop: "20px" }}>
                <div className="flex w-full items-center justify-around border-[#000] border-[1px] rounded-[10px] py-2 my-2">
                  {/* <Table data={quizReport} columns={column} /> */}
                  <p>Kyc Status List</p>
                  <Button
                    buttonContent="Load Info"
                    type="button"
                    onClick={(e) => {
                      quizbyKycStatus();
                    }}
                  />
                </div>
                {selectByQuiKycStatus?.status === "loading" ? (
                  <p>Loading</p>
                ) : selectByQuiKycStatus?.data?.Result?.length > 0 ? (
                  <Table
                    data={selectByQuiKycStatus?.data?.Result}
                    columns={column5}
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </form>
      </div>
      {showMessage && <ToastContainer />}
    </>
  );
};

export default PartnerQuizzes;
