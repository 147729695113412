import { createAsyncThunk } from "@reduxjs/toolkit";

import partnerHttpRequest from "../Apicall/partnerHttpRequest";
import { REGISTRATION } from "../endpoint/constant";

const userRegistration = createAsyncThunk(
  "user/register",
  async (credential: any, { rejectWithValue }) => {
    try {
      const response = await partnerHttpRequest.post(REGISTRATION, credential);

      return response.data;
    } catch (error: any) {
      const { Message, p_Message } = error.response.data;
      if (p_Message) {
        return rejectWithValue(p_Message);
      }
      else {
        return rejectWithValue(Message);
      }

    }
  }
);
export default userRegistration;
