import React, { ComponentPropsWithoutRef } from "react";
// interface InputFieldProps {
//   type: string;
//   name: string;
//   value: string;
//   placeholder: string;
//   className: string;
//   handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
//   required?: boolean;
//   // handleValidation: (e: React.ChangeEvent<HTMLInputElement>) => void;
// }
type InputFieldProps = ComponentPropsWithoutRef<"input">;

const InputField = ({
  name,
  className,
  type,
  value,
  placeholder,
  required,
  onChange,
  minLength,
  maxLength,
  readOnly,
}: InputFieldProps) => {
  return (
    <div className="w-full">
      <input
        name={name}
        type={type}
        value={value}
        placeholder={placeholder}
        onChange={onChange}
        onKeyDown={(e) => e.key === " " && e.preventDefault()}
        minLength={minLength}
        readOnly={readOnly}
        maxLength={maxLength}
        // onKeyUp={handleValidation}
        className={className}
        required={required}
      ></input>
    </div>
  );
};
export default InputField;
