import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "./stateSlice";
import createPartner from "../services/createPartner";
const state: initialState = {
    status: 'idle',
    message: "",
    error: false
}
const createPartnerSlice = createSlice({
    name: "partner/user",
    initialState: state,
    reducers: {
    },
    extraReducers: (builder) => {
        builder.addCase(createPartner.pending, (state) => {
            state.status = 'loading';
        }).addCase(createPartner.fulfilled, (state) => {
            state.status = 'success';
            state.message = "User Created Successfully";
        })
            .addCase(createPartner.rejected, (state) => {
                state.status = 'error';
            })
            .addDefaultCase((state) => ({ ...state }))
    }
})
export default createPartnerSlice.reducer;