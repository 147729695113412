import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "./stateSlice";
import Level2PartnerList from "../services/Level2PartnerList";
import { RootState } from "../store/store";
const state: initialState = {
    status: "idle",
    error: false,
    data: [],
};
const Level2ParnterSlice = createSlice({
    name: "get/partner2/list",
    initialState: state,
    reducers: {
        removePartner: (state, action) => {
            state.data = state.data?.filter((item: any) => item.ID !== action.payload)
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(Level2PartnerList.pending, (state) => {
                state.status = "loading";
            })
            .addCase(Level2PartnerList.fulfilled, (state, action) => {
                state.status = "success";
                state.data = action.payload.Result;
            })
            .addCase(Level2PartnerList.rejected, (state, action) => {
                state.status = "error";
                state.error = true;
            })
            .addDefaultCase((state) => ({ ...state }));
    },
});

export const selectPartner2List = (state: RootState) => {
    const { data } = state.Level2ParnterSlice

    return data;
}
export const { removePartner } = Level2ParnterSlice.actions
export default Level2ParnterSlice.reducer;
