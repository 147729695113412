import { createAsyncThunk } from "@reduxjs/toolkit";
import partnerHttpRequest from "../Apicall/partnerHttpRequest";
import { DOWNLOAD_PARTNER_INTEGRATION } from "../endpoint/constant";

const DownloadPartnerIntegrationKit = createAsyncThunk<any, any>('download/source', async ({ type, downloadType, setIsFileDownloaded, setProgress,
    setReactProgress,
    setPhpProgress,
    setdotnetProgress,
    setpostmanProgress,
    setsdkProgress,
    setandroidProgress,
    setpdfProgress, }, { rejectWithValue }) => {
    try {
        const response = await partnerHttpRequest.get(`${DOWNLOAD_PARTNER_INTEGRATION}/${type}`, {
            responseType: "blob", onDownloadProgress(progressEvent) {
                const loadedMB = progressEvent.loaded / (1024 * 1024);
                switch (type) {
                    case 'angular':
                        setProgress(loadedMB);
                        break;
                    case 'react':
                        setReactProgress(loadedMB);
                        break;
                    case 'php':
                        setPhpProgress(loadedMB);
                        break;
                    case 'dotnet':
                        setdotnetProgress(loadedMB);
                        break;
                    case 'pdf':
                        setpdfProgress(loadedMB);
                        break;
                    case 'android':
                        setandroidProgress(loadedMB);
                        break;
                    case 'sdk':
                        setsdkProgress(loadedMB);
                        break;
                    case 'postman':
                        setpostmanProgress(loadedMB);
                        break;

                    default:
                        break;
                }
            },
        })
        setIsFileDownloaded(Math.random() * 100);
        const url = window.URL.createObjectURL(new Blob([response.data]));
        // Create a link element and set its attributes
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${type}.${downloadType}`);
        // Append the link to the document and trigger the click event
        document.body.appendChild(link);
        link.click();
        // Clean up the temporary URL
        window.URL.revokeObjectURL(url);

        const { ErrorCode } = response.data;
        if (ErrorCode !== 200) {
            throw new Error("Internal server error")
        }

        return response.data
    } catch (error: any) {
        const err = error?.response?.data?.errors;
        return rejectWithValue(err.CreateBy[0])
    }
})
export default DownloadPartnerIntegrationKit;