import { createSlice } from "@reduxjs/toolkit";
import getKyc from "../services/getKyc";
import { RootState } from "../store/store";
import { initialState } from "./stateSlice";
import GetQuizLevel1Details from "../services/GetQuizLevel1Details";
import GetKycLevel1Details from "../services/GetKycLevel1Details";
const state: initialState = {
    status: "idle",
    data: [],
    error: false,
};

const GetKycLevel1DetailsSlice = createSlice({
    name: "get/detials/kyc/1",
    initialState: state,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(GetKycLevel1Details.pending, (state) => {
                state.status = "loading";
            })
            .addCase(GetKycLevel1Details.fulfilled, (state, action) => {
                state.status = "success";
                state.data = action.payload.Result;
            })
            .addCase(GetKycLevel1Details.rejected, (state,) => {
                state.status = "error";
                state.error = true;
            })
            .addDefaultCase((state) => ({ ...state }));
    },
});

export const selectKycDetails1 = (state: RootState) => {
    return state.getKycLevel1DetailsSlice.data
};

export default GetKycLevel1DetailsSlice.reducer;
