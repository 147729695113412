import { createSlice } from "@reduxjs/toolkit";
import getState from "../services/getState";
import { RootState } from "../store/store";
export interface initialState {
  status: "idle" | "loading" | "success" | "error";
  data?: any;
  error: boolean;
  message?: string
}
const state: initialState = {
  status: "idle",
  data: [],
  error: false,
};
const stateSlice = createSlice({
  name: "get/state",
  initialState: state,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getState.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getState.fulfilled, (state, action) => {
        state.status = "success";
        state.data = action.payload.Result;
      })
      .addCase(getState.rejected, (state, action) => {
        state.status = "error";
        state.error = true;
      })
      .addDefaultCase((state) => ({ ...state }));
  },
});

export const selectState = (state: RootState) => {
  const { data } = state.stateSlice
  const customizeOptions = data?.map((item: any) => ({
    value: item.state_id,
    label: item.state_name,
  }));
  return customizeOptions;
};
export default stateSlice.reducer;
