import { createAsyncThunk } from "@reduxjs/toolkit";
import partnerHttpRequest from "../Apicall/partnerHttpRequest";
import { VERIFY_OTP } from "../endpoint/constant";
interface verificationCredential {
    OTP: string,
    PartnerUserID: string | null
}
const verifyOTP = createAsyncThunk<any, verificationCredential>('reset/password', async (data, { rejectWithValue }) => {
    try {
        const response = await partnerHttpRequest.post(VERIFY_OTP, data)
        return response.data;
    } catch (error: any) {
        console.log(error)
        if (error.response.data?.errors?.OTP) {
            return rejectWithValue("Please enter OTP")
        }
        // console.log(error.response)
        const { message } = error.response.data
        console.log(message, 'message')
        return rejectWithValue(message)
    }

})
export default verifyOTP