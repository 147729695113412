import { useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
interface props {
  messageType: "success" | "error" | "warn" | "";
  message: string;
}
const Message = ({ message, messageType }: props) => {
  const showMessage = () => {
    if (messageType !== "") {
      toast[messageType](message);
    }
  };

  useEffect(() => {
    showMessage();
  }, [message, messageType]);
  return (
    <div>
      <ToastContainer />
    </div>
  );
};

export default Message;
