import { createSlice } from "@reduxjs/toolkit";
import getPlatform from "../services/getPlatform";
import { RootState } from "../store/store";

interface initialState {
  status: "idle" | "loading" | "success" | "error";
  data: any;
  error: boolean;
}
const state: initialState = {
  status: "idle",
  data: [],
  error: false,
};

const platformSlice = createSlice({
  name: "get/Platform",
  initialState: state,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getPlatform.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getPlatform.fulfilled, (state, action) => {
        state.status = "success";
        state.data = action.payload;
      })
      .addCase(getPlatform.rejected, (state, action) => {
        state.status = "error";
        state.error = true;
      })
      .addDefaultCase((state) => ({ ...state }));
  },
});
export const selectPlatformType = (state: RootState) => {
  const { Result } = state.platformSlice.data;
    const customizeOptions = Result
    return customizeOptions;
};

export default platformSlice.reducer;
