import { useEffect, useState } from "react";
import { useAppDispatch } from "../../redux/hook/hooks";
import { useAppSelector } from "../../redux/hook/hooks";
import DataTable from "react-data-table-component";
import { ToastContainer } from "react-toastify";
import AllPartnersList from "../../redux/services/AllPartnersList";
import { selectRejectPartner } from "../../redux/slice/RejctedPartnerSlice";
import RejectedPartnerList from "../../redux/services/RejectedPartnerList";
const RejectPartnerList = () => {
  const [showMessage, setShowMessage] = useState(false);

  const [column] = useState([
    {
      name: "Name",
      selector: (row: any) => row.Name,
    },
    {
      name: "CompanyName",
      selector: (row: any) => row.CompanyName,
    },
    {
      name: "Mobileno",
      selector: (row: any) => row.Mobileno,
    },
    {
      name: "Email",
      selector: (row: any) => row.Email,
    },
  ]);
  const dispatch = useAppDispatch();
  const selector = useAppSelector(selectRejectPartner);
  const conditionalRowStyles = [
    {
      when: (row: any) => row.Status,
      style: (row: any) => {
        return { cursor: row.Status === "Active" ? "" : "pointer" };
      },
    },
  ];

  const getPartner = async () => {
    try {
      const response = await dispatch(RejectedPartnerList()).unwrap();
    } catch (error) {}
  };

  useEffect(() => {
    getPartner();
  }, []);

  return (
    <div>
      <DataTable
        columns={column}
        data={selector}
        pagination={true}
        conditionalRowStyles={conditionalRowStyles}
      />
      {showMessage && <ToastContainer />}
    </div>
  );
};

export default RejectPartnerList;
