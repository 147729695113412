import { createAsyncThunk } from "@reduxjs/toolkit";
import partnerHttpRequest from "../Apicall/partnerHttpRequest";
import { QUIZ_LEVEL_1_DETAILS } from "../endpoint/constant";

const GetQuizLevel1Details = createAsyncThunk<any, any>('quiz/level/1', async (data, { rejectWithValue }) => {

    try {
        const response = await partnerHttpRequest.post(QUIZ_LEVEL_1_DETAILS, data)
        return response.data
    } catch (error) {

    }

})
export default GetQuizLevel1Details;
