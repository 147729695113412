import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store/store";
import getCategory from "../services/getCategory";

interface initialState {
  status: "idle" | "loading" | "success" | "error";
  data: any;
  error: boolean;
}
const state: initialState = {
  status: "idle",
  data: [],
  error: false,
};

const categorySlice = createSlice({
  name: "get/partnerType",
  initialState: state,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getCategory.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getCategory.fulfilled, (state, action) => {
        state.status = "success";
        state.data = action.payload;
      })
      .addCase(getCategory.rejected, (state, action) => {
        state.status = "error";
        state.error = true;
      })
      .addDefaultCase((state) => ({ ...state }));
  },
});
export const selectCategoryType = (state: RootState) => {
  const { Result } = state.categorySlice.data;
  const customizeOptions = Result?.map((item: any) => ({
    value: item.ID,
    label: item.Name,
  }));
  return customizeOptions;
};

export default categorySlice.reducer;
