import partnerHttpRequest from "../Apicall/partnerHttpRequest";
import { PARTNER_USER_ACTIVE } from "../endpoint/constant";

const ActivePartner = (credential: { ID: string, CreateBy: string }) => {
    return async () => {
        try {
            const response = await partnerHttpRequest.post(PARTNER_USER_ACTIVE, credential)
            const { ErrorCode, Message } = response.data;

            if (ErrorCode !== 200) {
                throw new Error(Message);
            }

            return response.data;
        } catch (error) {
            return error;
        }
    };
};
export default ActivePartner;