import partnerHttpRequest from "../Apicall/partnerHttpRequest";
import { ROLE_DEACTIVATE } from "../endpoint/constant";

const DeactiveRole = (credential: { ID: string, CreateBy: string }) => {
    return async () => {
        try {
            const response = await partnerHttpRequest.post(ROLE_DEACTIVATE, credential)
            const { ErrorCode, Message } = response.data;
            if (ErrorCode !== 200) {
                throw new Error(Message);
            }
            return response.data;
        } catch (error) {
            return error;
        }
    };
};
export default DeactiveRole;