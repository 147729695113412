import React, { FormEvent, useState } from "react";
import InputField from "../../component/InputField/InputField";
import partners from "../../assets/images/partners.svg";
import emailIcon from "../../assets/images/emailicon.svg";
import Button from "../../component/button/Button";
import { useAppDispatch } from "../../redux/hook/hooks";
import forgotPassword from "../../redux/services/forgotPassword";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
const ForgotPassword: React.FC = () => {
  const [email, setEmail] = useState<string>("");
  const [showMessage, setShowMessage] = useState<boolean>(false);
  const location = useLocation();
  const [disableButton, setDisableButton] = useState<boolean>(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };
  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setDisableButton(true);
    if (!email) {
      toast.warn("Please enter email address");
    }
    try {
      const response = await dispatch(
        forgotPassword({ Email: email })
      ).unwrap();
      const { Message } = response;
      setShowMessage(true);
      toast.success(Message);
      setEmail("");
      setTimeout(() => {
        setDisableButton(true);
        navigate("/");
      }, 1500);
    } catch (error: any) {
      setShowMessage(true);
      toast.error(error);
      setDisableButton(false);
    }
  };

  if (localStorage.getItem("a_t")) {
    if (
      location.pathname === "/forgot-password" &&
      localStorage.getItem("r_id") === "1"
    ) {
      return <Navigate to="/partner/list" />;
    } else if (
      location.pathname === "/forgot-password" &&
      localStorage.getItem("r_id") === "35"
    ) {
      return <Navigate to="/forgot-password" />;
    } else if (
      location.pathname === "/forgot-password" &&
      localStorage.getItem("r_id") === "36"
    ) {
      return <Navigate to="/partner/mou-upload" />;
    } else {
      return <Navigate to="/partner/config" />;
    }
  }
  return (
    <div className="w-full h-[100vh] flex justify-between m-auto items-center">
      <div className="w-2/4">
        <div className="w-3/4 h-4/5 m-auto">
          <div className="">
            <h1 className="text-3xl">Forgot Password</h1>
            <form className="mt-4" onSubmit={handleSubmit}>
              <div className="relative flex w-4/5">
                <div className="pointer-events-none absolute inset-y-0 flex items-center w-9 bg-[#cbd5e1] rounded-l-md shadow-inner z-[0]">
                  <span className="text-gray-500 m-auto">
                    <img src={emailIcon} width="18px" alt="" />
                  </span>
                </div>
                <InputField
                  className="block px-3 py-2 w-full bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400 flex-auto pl-[45px] focus:outline-none"
                  type="email"
                  // required={true}
                  name={email}
                  placeholder="Enter Email"
                  value={email}
                  onChange={handleChange}
                />
              </div>
              <Button buttonContent={"Submit"} />
              {/* button */}
              <br />
            </form>
          </div>
        </div>
      </div>
      <div className="w-2/4 flex ">
        <h1>
          <img className="h-[100vh]" src={partners} alt="" />
        </h1>
      </div>
      {showMessage && <ToastContainer autoClose={2000} />}
    </div>
  );
};
export default ForgotPassword;
