import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useAppDispatch, useAppSelector } from "../../../../redux/hook/hooks";
import getSchoolKycDetails from "../../../../redux/services/getSchoolKycDetail";
import { selectSchoolKycDetails } from "../../../../redux/slice/getSchoolKycDetailSlice";
import ChartComponent from "../../../Chart/ChartComponent";
interface RowProps {
  row: any;
}

const Schoolkyc: React.FC<RowProps> = ({ row }) => {
  const [chartValue, setChartValue] = useState<any>([]);
  const [chartLabel, setChartLabel] = useState<any>([]);
  const kycColumn = [
    {
      name: "kyc_Approve",
      selector: (row: any) => row.kyc_Approve,
      sortable: true,
    },
    {
      name: "kyc_Change",
      selector: (row: any) => row.kyc_Change,
    },
    {
      name: "kyc_Inprocess",
      selector: (row: any) => row.kyc_Inprocess,
    },
    {
      name: "kyc_Rejected",
      selector: (row: any) => row.kyc_Rejected,
    },
    {
      name: "kyc_blank",
      selector: (row: any) => row.kyc_blank,
    },
    {
      name: "kyc_pending",
      selector: (row: any) => row.kyc_pending,
    },
  ];
  const dispatch = useAppDispatch();
  const SchoolKycData = useAppSelector(selectSchoolKycDetails);
  const handleSchoolKycDetail = async () => {
    try {
      const credential = {
        School: row.school_id,
        Filter: row.Filter,
      };
      await dispatch(getSchoolKycDetails(credential)).unwrap();
    } catch (error) {}
  };
  useEffect(() => {
    handleSchoolKycDetail();
  }, []);
  useEffect(() => {
    if (SchoolKycData.length > 0) {
      const keys = Object.keys(SchoolKycData[0]).filter(
        (item) => item !== "school_id" && item !== "Filter"
      );
      setChartLabel(keys);
      const values = [];
      for (let property in SchoolKycData[0]) {
        if (property !== "school_id" && property !== "Filter") {
          values.push(SchoolKycData[0][property]);
        }
      }
      setChartValue(values);
    }
  }, [SchoolKycData]);
  return (
    <div>
      <div className="pt-[15px]">
        <h1 className="text-2xl">School Kyc Detail</h1>
        <div>
          <DataTable
            className="z-0"
            columns={kycColumn}
            data={SchoolKycData}
            fixedHeader
          />
          <ChartComponent chartValue={chartValue} chartLabel={chartLabel} />
        </div>
      </div>
    </div>
  );
};

export default Schoolkyc;
