import { createAsyncThunk } from "@reduxjs/toolkit";

import partnerHttpRequest from "../Apicall/partnerHttpRequest";
import { FORGOT_PASSWORD } from "../endpoint/constant";

const forgotPassword = createAsyncThunk<any, { Email: string }>('forgot/password', async (data, { rejectWithValue }) => {
    try {
        const response = await partnerHttpRequest.post(FORGOT_PASSWORD, data);
        return response.data
    } catch (error: any) {
        const { Message } = error.response.data
        return rejectWithValue(Message)
    }

})
export default forgotPassword