import { createAsyncThunk } from "@reduxjs/toolkit";
import partnerHttpRequest from "../Apicall/partnerHttpRequest";
import { SCHOOL_STUDENT_SUBJECT_DETAIL } from "../endpoint/constant";

const getSchoolStudentSubjectDetails = createAsyncThunk<any, any>(
  "subject/school/detail/level",
  async (data, { rejectWithValue }) => {
    try {
      const response = await partnerHttpRequest.post(
        SCHOOL_STUDENT_SUBJECT_DETAIL,
        data
      );
      return response.data;
    } catch (error) { }
  }
);
export default getSchoolStudentSubjectDetails;
