import { createSlice } from "@reduxjs/toolkit";
import verifyOTP from "../services/verifyOTP";
import { RootState } from "../store/store";
export interface initialState {
    status: 'idle' | 'loading' | 'success' | 'error';
    message: string
    error: boolean
}
const state: initialState = {
    status: 'idle',
    message: '',
    error: false
}
const verifyOTPSlice = createSlice({
    name: 'reset/password',
    initialState: state,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(verifyOTP.pending, (state) => {
            state.status = 'loading'

        }).addCase(verifyOTP.fulfilled, (state, action) => {
            state.status = 'success';
            state.message = action.payload;

        }).addCase(verifyOTP.rejected, (state, action) => {
            state.status = 'error';
            state.error = true
        }).addDefaultCase(state => ({ ...state }))
    }
})

// export const selectState = (state: RootState) => {
//     return state.verifyOTPSlice;
// }
export default verifyOTPSlice.reducer
