import DataTable from "react-data-table-component";
import { useAppDispatch, useAppSelector } from "../../redux/hook/hooks";
import { useEffect, useState } from "react";
import getUserRole from "../../redux/services/getUserRole";
import {
  DeactivateRole,
  selectRole,
  ActiveRole,
  deleteRoles,
} from "../../redux/slice/getUserRoleSlice";
import ActivateRole from "../../redux/services/ActivateRole";
import DeactiveRole from "../../redux/services/DeactiveRole";
import deleteRole from "../../redux/services/deleteRole";
import Button from "../../component/button/Button";
import { useNavigate } from "react-router-dom";
import { messagesType } from "./CreateRole";
import Message from "../../utilities/Message";
import Loader from "../../GlobalLoader/Loader";
import { BsPencilFill } from "react-icons/bs";
import { RiDeleteBin5Fill } from "react-icons/ri";
const GetRole = () => {
  const [loader, setLoader] = useState(false);
  const [showMessage, setShowMessage] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [messageType, setMessageType] = useState<messagesType>("");

  const [column, setColumn] = useState([
    {
      name: "RoleName",
      selector: (row: any) => row.RoleName,
    },
    {
      name: "Status",
      selector: (row: any) => row.STATUS,
      style: {
        cursor: "pointer",
      },
      cell: (row: any) => {
        return (
          <p
            onClick={() => {
              if (row.STATUS === "Inactive") {
                ActiveRoleStatus(row);
              } else {
                DeactiveRoleStatus(row);
              }
            }}
          >
            {row.STATUS}
          </p>
        );
      },
    },
    {
      name: "Edit",
      width: "20%",
      center: true,
      style: {
        cursor: "pointer",
      },
      cell: (row: any) => {
        return (
          <span
            onClick={() => {
              navigate("/partner/role/create", { state: row });
            }}
          >
            <BsPencilFill />
          </span>
        );
      },
    },
    {
      name: "Delete",
      width: "20%",
      center: true,
      style: {
        cursor: "pointer",
      },
      cell: (row: any) => {
        return (
          <span
            onClick={() => {
              deleteUserRole(row);
            }}
          >
            <RiDeleteBin5Fill />
          </span>
        );
      },
    },
  ]);
  const dispatch = useAppDispatch();
  const roleSelect = useAppSelector(selectRole);

  const navigate = useNavigate();
  const ActiveRoleStatus = async (credential: any) => {
    setShowMessage(false);
    const { RoleID, CreateBy } = credential;

    try {
      const response = await dispatch(ActivateRole({ ID: RoleID, CreateBy }));
      const { ErrorCode } = response;
      if (ErrorCode === 200) {
        dispatch(ActiveRole({ ID: RoleID, CreateBy }));
        setMessageType("success");
        setShowMessage(true);
        setMessage("Role is activated successfully");
      }
    } catch (error) {
      setMessageType("error");
      setShowMessage(true);
      setMessage("Internal server error");
    }
  };
  const DeactiveRoleStatus = async (credential: any) => {
    setShowMessage(false);
    const { RoleID, CreateBy } = credential;

    try {
      const response = await dispatch(DeactiveRole({ ID: RoleID, CreateBy }));

      const { ErrorCode } = response;
      if (ErrorCode === 200) {
        dispatch(DeactivateRole({ ID: RoleID, CreateBy }));
        setMessageType("success");
        setShowMessage(true);
        setMessage("Role is Deactivated successfully");
      }
    } catch (error) {
      setMessageType("error");
      setShowMessage(true);
      setMessage("Internal server error");
    }
  };
  const deleteUserRole = async (credential: any) => {
    setShowMessage(false);
    const { RoleID, CreateBy } = credential;
    try {
      const response = await dispatch(deleteRole({ ID: RoleID, CreateBy }));
      const { ErrorCode } = response;
      if (ErrorCode === 200) {
        dispatch(deleteRoles({ ID: RoleID, CreateBy }));
        setMessageType("success");
        setShowMessage(true);
        setMessage("Role is deleted successfully");
      }
    } catch (error) {
      setMessageType("error");
      setShowMessage(true);
      setMessage("Internal server error");
    }
  };

  const getRole = async () => {
    const ID = localStorage.getItem("p_id");
    try {
      setLoader(true);
      if (ID) {
        await dispatch(getUserRole({ ID })).unwrap();
      }
      setLoader(false);
    } catch (error) {}
  };
  useEffect(() => {
    getRole();
  }, []);

  return (
    <>
      <div className="flex justify-end px-10">
        <Button
          onClick={() => {
            navigate("/partner/role/create");
          }}
          buttonContent="Create Role"
        />
      </div>
      {loader ? <Loader /> : <DataTable columns={column} data={roleSelect} />}
      {showMessage && <Message message={message} messageType={messageType} />}
    </>
  );
};

export default GetRole;
