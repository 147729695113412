import { createAsyncThunk } from "@reduxjs/toolkit";
import httpRequest from "../Apicall/httpRequest";

const getGrade = createAsyncThunk("grade", async (_, { rejectWithValue }) => {
  try {
    const response = await fetch(
      // `${process.env.REACT_APP_BASE_URL}grade_list`,
      `https://api.societypartner.auroscholar.org/api/partner/gradeList`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const result = await response.json();
    return result;
  } catch (error) {}
});

export default getGrade;
