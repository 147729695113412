import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "./stateSlice";
import DownloadPartnerIntegrationKit from "../services/DownloadPartnerIntegrationKit";
import { RootState } from "../store/store";
const state: initialState = {
    status: 'idle',
    error: false,
    message: ""
}
const DownloadPartnerIntegrationKitSlice = createSlice({
    name: "download/source/code",
    initialState: state,
    reducers: {
    },
    extraReducers: (builder) => {
        builder.addCase(DownloadPartnerIntegrationKit.pending, (state) => {
            state.status = 'loading';
        }).addCase(DownloadPartnerIntegrationKit.fulfilled, (state) => {
            state.status = 'success';
        })
            .addCase(DownloadPartnerIntegrationKit.rejected, (state, action) => {
                state.status = 'error';
                const message = action.payload as string;
                state.message = message;
            })
            .addDefaultCase((state) => ({ ...state }))
    }
})

export const selectPartnerConfig = (state: RootState) => {
    return state.DownloadPartnerIntegrationKitSlice
}
export default DownloadPartnerIntegrationKitSlice.reducer
