import React from "react";

const Loader = () => {
  return (
    <>
      <div className="relative w-full h-screen ">
        <div id="container" className="absolute top-[35%] left-1/2 ">
          <div className="flex gap-2 items-center">
            <div id="ball-1" className="circle"></div>
            <div id="ball-2" className="circle"></div>
            <div id="ball-3" className="circle"></div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Loader;
