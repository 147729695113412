import { createAsyncThunk } from "@reduxjs/toolkit";
import partnerHttpRequest from "../Apicall/partnerHttpRequest";
import { createUser } from "../../pages/users/CreatePartnerUser";
import { CREATE_PARTNER_USER } from "../endpoint/constant";


const createPartner = createAsyncThunk<any, createUser>('create/partner/user', async (data, { rejectWithValue }) => {
    try {
        const response = await partnerHttpRequest.post(CREATE_PARTNER_USER, data)
        return response.data
    } catch (error: any) {
        const { p_Message } = error.response.data
        return rejectWithValue(p_Message)
    }
})
export default createPartner;