import React from "react";
import auroLogo from "../../assets/images/auroSociety.png";
const Header: React.FC = () => {
  return (
    <div className="w-full bg-[#bae6fd] h-[12vh]">
      <div className="flex justify-center">
        <div>
          <img src={auroLogo}></img>
        </div>
      </div>
    </div>
  );
};

export default Header;
