import { createSlice, current } from "@reduxjs/toolkit";
import { initialState } from "./stateSlice";
import { RootState } from "../store/store";
import getRoleMenuMapping from "../services/getRoleMenuMapping";
const state: initialState = {
    status: 'idle',
    data: [],
    error: false
}
const getRoleMenuMappingSlice = createSlice({
    name: "role/menu",
    initialState: state,
    reducers: {
        mapUnmap: (state, action) => {
            state.data = state?.data?.map((item: any) => {
                return item.MenuID === action.payload.Id ? { ...item, CStatus: action.payload.CStatus } : { ...item }
            })
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getRoleMenuMapping.pending, (state) => {
            state.status = 'loading';
        }).addCase(getRoleMenuMapping.fulfilled, (state, action) => {
            state.status = 'success';
            state.data = action.payload.Result;
        })
            .addCase(getRoleMenuMapping.rejected, (state, action) => {
                state.status = 'error';
            })
            .addDefaultCase((state) => ({ ...state }))
    }
})

export const selectRoleMenuMapping = (state: RootState) => {
    const { data } = state.getRoleMenuMappingSlice;
    return data
}

export const selectMenuNameAndId = (state: RootState) => {
    const { data } = state.getRoleMenuMappingSlice;
    const customisedData = data?.map((item: any) => ({ value: item.MenuID, label: item.MenuName }))
    return customisedData;
}

export default getRoleMenuMappingSlice.reducer
export const { mapUnmap } = getRoleMenuMappingSlice.actions