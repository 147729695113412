import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useAppDispatch, useAppSelector } from "../../../../redux/hook/hooks";
import getSchoolStudentSubjectDetails from "../../../../redux/services/getSchoolStudentSubject";
import { selectSchoolSubjectDetails } from "../../../../redux/slice/getSchoolSubjectSlice";
import ChartComponent from "../../../Chart/ChartComponent";
interface RowProps {
  row: any;
}

const SchoolSubject: React.FC<RowProps> = ({ row }) => {
  const [chartValue, setChartValue] = useState<any>([]);
  const [chartLabel, setChartLabel] = useState<any>([]);
  const QuizColumn = [
    {
      name: "School Name",
      selector: (row: any) => row.school_id,
      sortable: true,
    },
    {
      name: "Biology",
      selector: (row: any) => row.Biology,
      sortable: true,
    },
    {
      name: "Chemistry",
      selector: (row: any) => row.Chemistry,
    },
    {
      name: "English",
      selector: (row: any) => row.English,
    },
    {
      name: "Geography",
      selector: (row: any) => row.Geography,
      sortable: true,
    },
    {
      name: "Hindi",
      selector: (row: any) => row.Hindi,
    },
    {
      name: "History",
      selector: (row: any) => row.History,
    },
    {
      name: "Maths",
      selector: (row: any) => row.Maths,
      sortable: true,
    },
    {
      name: "Physics",
      selector: (row: any) => row.Physics,
    },
    {
      name: "Politicalscience",
      selector: (row: any) => row.Politicalscience,
    },
    {
      name: "Science",
      selector: (row: any) => row.Science,
      sortable: true,
    },
    {
      name: "SocialScience",
      selector: (row: any) => row.SocialScience,
    },
    {
      name: "total",
      selector: (row: any) => row.total,
    },
  ];
  const dispatch = useAppDispatch();
  const SchoolSubjectData = useAppSelector(selectSchoolSubjectDetails);
  const handleSchoolSubjectDetail = async () => {
    try {
      const credential = {
        School: row.school_id,
        Filter: row.Filter,
      };
      await dispatch(getSchoolStudentSubjectDetails(credential)).unwrap();
    } catch (error) {}
  };
  useEffect(() => {
    handleSchoolSubjectDetail();
  }, []);
  useEffect(() => {
    if (SchoolSubjectData.length > 0) {
      const keys = Object.keys(SchoolSubjectData[0]).filter(
        (item) =>
          item !== "school_id" &&
          item !== "Filter" &&
          item !== "registration_id" &&
          item !== "total"
      );
      setChartLabel(keys);
      const values = [];
      for (let property in SchoolSubjectData[0]) {
        if (
          property !== "school_id" &&
          property !== "Filter" &&
          property !== "registration_id" &&
          property !== "total"
        ) {
          values.push(SchoolSubjectData[0][property]);
        }
      }
      setChartValue(values);
    }
  }, [SchoolSubjectData]);
  return (
    <div>
      <div className="pt-[15px]">
        <h1 className="text-2xl">School Subject Detail</h1>
        <div>
          <DataTable
            className="z-0"
            columns={QuizColumn}
            data={SchoolSubjectData}
            fixedHeader
          />
          <ChartComponent chartValue={chartValue} chartLabel={chartLabel} />
        </div>
      </div>
    </div>
  );
};

export default SchoolSubject;
