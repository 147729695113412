import { createSlice } from "@reduxjs/toolkit";
import getSchoolGradeDetails from "../services/getSchoolGradeDetails";
import getSchoolQuizDetails from "../services/getSchoolQuizDetails";
import { RootState } from "../store/store";
import { initialState } from "./stateSlice";

const state: initialState = {
  status: "idle",
  data: [],
  error: false,
};

const getSchoolGradeDetailSlice = createSlice({
  name: "get/detail/grade/school",
  initialState: state,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSchoolGradeDetails.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getSchoolGradeDetails.fulfilled, (state, action) => {
        state.status = "success";
        state.data = action.payload.Result;
      })
      .addCase(getSchoolGradeDetails.rejected, (state) => {
        state.status = "error";
        state.error = true;
      })
      .addDefaultCase((state) => ({ ...state }));
  },
});

export const selectSchoolGradeDetails = (state: RootState) => {
  const { data } = state?.getSchoolGradeDetailSlice;
  return data;
};

export default getSchoolGradeDetailSlice.reducer;
