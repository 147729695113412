import { AnyAction, combineReducers } from "redux";
import stateSlice from "../slice/stateSlice";
import districtSlice from "../slice/districtSlice";
import gradeSlice from "../slice/gradeSlice";
import subjectSlice from "../slice/subjectSlice";
import forgotSlice from "../slice/forgotSlice";
import resetPasswordSlice from "../slice/resetPasswordSlice";
import verifyOTPSlice from "../slice/verifyOTPSlice";
import platformSlice from "../slice/platformSlice";
import registerSlice from "../slice/registerSlice";
import partnerTypeSlice from "../slice/partnerTypeSlice";
import categorySlice from "../slice/categorySlice";
import getKycSlice from "../slice/getKycSlice";
import getUserRoleSlice from "../slice/getUserRoleSlice";
import getRoleMenuMappingSlice from "../slice/getRoleMenuMappingSlice";
import roleMenuMappingSlice from "../slice/roleMenuMappingSlice";
import roleMenuUnmappingSlice from "../slice/roleMenuUnmappingSlice";
import getPartnerUserSlice from "../slice/getPartnerUserSlice";
import createPartnerSlice from "../slice/createPartnerSlice";
import editPartnerSlice from "../slice/editPartnerSlice";
import multiDistrictSlice from "../slice/multiDistrictSlice";
import studentDetailSLice from "../slice/studentDetailSLice";
import QuizLevel1Slice from "../slice/QuizLevel1Slice";
import QuizLevel2Slice from "../slice/QuizLevel2Slice";
import QuizLevel3Slice from "../slice/QuizLevel3Slice";
import KycLevel1Slice from "../slice/KycLevel1Slice";
import KycLevel2Slice from "../slice/KycLevel2Slice";
import KycLevel3Slice from "../slice/KycLevel3Slice";
import GetQuizLevel1DetailsSlice from "../slice/GetQuizLevel1DetailsSlice";
import getKycLevel1DetailsSlice from "../slice/getKycLevel1DetailsSlice";
import getKycLevel3DetailsSlice from "../slice/getKycLevel3DetailsSlice";
import getKycLevel2DetailsSlice from "../slice/getKycLevel2DetailsSlice";
import GetQuizLevel2DetailsSlice from "../slice/GetQuizLevel2DetailsSlice";
import GetQuizLevel3DetailsSlice from "../slice/GetQuizLevel3DetailsSlice";
import schoolDetailsSlice from "../slice/schoolDetailsSlice";
import QuizTrackInitialReportByPartnerSourceSlice from "../slice/QuizTrackInitialReportByPartnerSourceSlice";
import QuizTakingSlice from "../slice/QuizTakingSlice";
import QuizByKycStatusSlice from "../slice/QuizByKycStatusSlice";
import quizByStudentClassSlice from "../slice/quizByStudentClassSlice";
import quizBySubjectListSlice from "../slice/quizBySubjectListSlice";
import getSchoolKycDetailSlice from "../slice/getSchoolKycDetailSlice";
import LoginSlice from "../slice/LoginSlice";
import getSchoolQuizDetailSlice from "../slice/getSchoolQuizDetailSlice";
import getSchoolGradeDetailSlice from "../slice/getSchoolGradeDetailSlice";
import getSchoolSubjectDetailSlice from "../slice/getSchoolSubjectSlice";
import getSchoolGradeAverageDetailSlice from "../slice/getSchoolSubjectSlice";
import getPartnerSourceSlice from "../slice/getPartnerSourceSlice";
import DownloadPartnerIntegrationKitSlice from "../slice/DownloadPartnerIntegrationKitSlice";
import QuizTrackDetailsSlice from "../slice/QuizTrackDetailsSlice";
import partnerListSlice from "../slice/partnerListSlice";
import GetMenuListByRoleIDSlice from "../slice/GetMenuListByRoleIDSlice";
import Level2ParnterSlice from "../slice/Level2ParnterSlice";
import GetUserDataByRoleIDSlice from "../slice/GetUserDataByRoleIDSlice.ts";
import PartnerNotUploadedMOUSlice from "../slice/PartnerNotUploadedMOUSlice";
import AllPartnerListSlice from "../slice/AllPartnerListSlice";
import RejctedPartnerSlice from "../slice/RejctedPartnerSlice";

const rootReducer = combineReducers({
  LoginSlice,
  stateSlice,
  districtSlice,
  gradeSlice,
  subjectSlice,
  forgotSlice,
  resetPasswordSlice,
  platformSlice,
  registerSlice,
  partnerTypeSlice,
  categorySlice,
  verifyOTPSlice,
  getKycSlice,
  getUserRoleSlice,
  getRoleMenuMappingSlice,
  roleMenuMappingSlice,
  roleMenuUnmappingSlice,
  getPartnerUserSlice,
  createPartnerSlice,
  editPartnerSlice,
  multiDistrictSlice,
  QuizLevel2Slice,
  KycLevel1Slice,
  KycLevel2Slice,
  KycLevel3Slice,
  QuizLevel1Slice,
  QuizLevel3Slice,
  studentDetailSLice,
  GetQuizLevel1DetailsSlice,
  getKycLevel1DetailsSlice,
  getKycLevel2DetailsSlice,
  getKycLevel3DetailsSlice,
  GetQuizLevel2DetailsSlice,
  GetQuizLevel3DetailsSlice,
  schoolDetailsSlice,
  QuizTrackInitialReportByPartnerSourceSlice,
  QuizTakingSlice,
  QuizByKycStatusSlice,
  quizByStudentClassSlice,
  quizBySubjectListSlice,
  getSchoolKycDetailSlice,
  getSchoolQuizDetailSlice,
  getSchoolGradeDetailSlice,
  getSchoolSubjectDetailSlice,
  getSchoolGradeAverageDetailSlice,
  getPartnerSourceSlice,
  DownloadPartnerIntegrationKitSlice,
  QuizTrackDetailsSlice,
  partnerListSlice,
  GetMenuListByRoleIDSlice,
  Level2ParnterSlice,
  GetUserDataByRoleIDSlice,
  PartnerNotUploadedMOUSlice,
  AllPartnerListSlice,
  RejctedPartnerSlice
});

export const reducerProxy = (state: any, action: AnyAction) => {
  if (action.type === 'logout/LOGOUT') {
    return rootReducer(undefined, action);
  }
  return rootReducer(state, action);
}
export default rootReducer;
