import { createAsyncThunk } from "@reduxjs/toolkit";
import partnerHttpRequest from "../Apicall/partnerHttpRequest";
import { ROLE_MENU_UNMAPPING } from "../endpoint/constant";

const roleMenuUnMapping = createAsyncThunk<any, any>('unmapping', async (data, { rejectWithValue }) => {

    try {
        const response = await partnerHttpRequest.post(ROLE_MENU_UNMAPPING, data)
        return response.data
    } catch (error) {

    }

})
export default roleMenuUnMapping;
