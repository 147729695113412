import { ChangeEvent, useState } from "react";
import { messagesType } from "../role/CreateRole";
import Message from "../../utilities/Message";
import DataTable from "react-data-table-component";
import { useAppDispatch, useAppSelector } from "../../redux/hook/hooks";
import {
  mapUnmap,
  selectRoleMenuMapping,
} from "../../redux/slice/getRoleMenuMappingSlice";
import useRoleMenu from "../../customHook/useRoleMenu";
import MultiSelect from "../../component/SelectField/MultiSelect";
import { selectRoleNameAndId } from "../../redux/slice/getUserRoleSlice";
import useFetch from "../../customHook/useFetch";
import userroleMenuMapping from "../../redux/services/userroleMenuMapping";
import roleMenuUnMapping from "../../redux/services/roleMenuUnMapping";
const GetRoleMenu = () => {
  const [roleId, setRoleId] = useState({ roleId: 0 });
  const { error } = useRoleMenu(roleId);
  const { error: roleError } = useFetch();
  const roleMenuMapping = useAppSelector(selectRoleMenuMapping);
  const [showMessage, setShowMessage] = useState<boolean>(false);
  const [message, setMessage] = useState<string>("");
  const [messageType, setMessageType] = useState<messagesType>("");
  const dispatch = useAppDispatch();
  const getRoleData = useAppSelector(selectRoleNameAndId);
  const [column] = useState([
    {
      name: "Menu Name",
      selector: (row: any) => row.MenuName,
    },
    {
      name: "Menu URL",
      selector: (row: any) => row.MenuURL,
    },
    // {
    //   name: "Status",
    //   selector: (row: any) => row.CStatus,
    // },
    {
      name: "Unmap",
      width: "20%",
      center: true,
      style: {
        cursor: "pointer",
      },
      cell: (row: any, index: number) => {
        return (
          <input
            type="checkbox"
            id={row.MenuID}
            checked={row.CStatus === "true" ? true : false}
            onChange={(e) => {
              roleMenuMapAndUnmap(e, index, row);
              // useFetch()
            }}
          />
        );
      },
    },
  ]);

  const roleMenuMapAndUnmap = async (
    e: ChangeEvent<HTMLInputElement>,
    index: number,
    row: any
  ) => {
    setShowMessage(false);
    try {
      if (row.CStatus === "false") {
        await dispatch(
          userroleMenuMapping({
            RoleID: localStorage.getItem("currentRole")
              ? localStorage.getItem("currentRole")
              : localStorage.getItem("r_id"),
            MenuID: row.MenuID,
            IsParentID: row.ISParentID ? row.ISParentID : "null",
            CreateBy: localStorage.getItem("p_id") || "",
          })
        ).unwrap();

        setMessage("Mapped successfully");
      } else {
        await dispatch(
          roleMenuUnMapping({
            RoleID: localStorage.getItem("currentRole")
              ? localStorage.getItem("currentRole")
              : localStorage.getItem("r_id"),
            RoleMenuMappingID: row.RoleMenuMappingID,
            MenuID: row.MenuID,
            IsParentID: row.ISParentID ? row.ISParentID : "null",
            CreateBy: localStorage.getItem("p_id") || "",
          })
        ).unwrap();
        setMessage("UnMapped successfully");
      }
      setShowMessage(true);
      setMessageType("success");
      // dispatch(getRoleMenuMapping({ ID: "1" }));
      dispatch(
        mapUnmap({
          Id: row.MenuID,
          CStatus: row.CStatus === "true" ? "false" : "true",
        })
      );
    } catch (error) {}
  };

  return (
    <>
      <div className="mx-[40px]">
        <div className="mt-4 px-1">
          <MultiSelect
            placeholer="select role"
            options={getRoleData}
            multi={false}
            onChange={(selctedItem) => {
              localStorage.setItem("currentRole", selctedItem.value);
              setRoleId({ ...roleId, roleId: selctedItem.value });
            }}
          />
        </div>
        <div className="mt-2">
          <DataTable columns={column} data={roleMenuMapping} />
        </div>
      </div>
      {showMessage && <Message message={message} messageType={messageType} />}
    </>
  );
};

export default GetRoleMenu;
