import { ChangeEvent, FormEvent, useState } from "react";
import InputField from "../../component/InputField/InputField";
import Button from "../../component/button/Button";
import { useAppDispatch } from "../../redux/hook/hooks";
import createRole from "../../redux/services/createRole";
import { useLocation, useNavigate } from "react-router-dom";
import updateRole from "../../redux/services/updateRole";
import { userNamRegex } from "../../utilities/Regex";
import { ToastContainer, toast } from "react-toastify";

interface roleCredential {
  RoleName: string;
}

export type messagesType = "success" | "error" | "warn" | "";

const CreateRole = () => {
  const location = useLocation();
  const [credential, setCredential] = useState<roleCredential>({
    RoleName: location?.state?.RoleID ? location.state.RoleName : "",
  });
  const [showMessage, setShowMessage] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCredential({ ...credential, [e.target.name]: e.target.value });
  };
  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setShowMessage(true);
    const isRoleNameValid = userNamRegex.test(credential.RoleName);
    if (!isRoleNameValid) {
      toast.warn("Role name should contains alphabet , underscore and @");
      return;
    } else if (credential.RoleName.length < 3) {
      toast.warn("Role name should be between 3 to 50 characters");
      return;
    }
    try {
      const { RoleName } = credential;
      const CreateBy = localStorage.getItem("p_id") || "";
      if (location.state) {
        const { RoleID } = location.state;
        await dispatch(updateRole({ RoleName, RoleID, CreateBy })).unwrap();
      } else {
        await dispatch(createRole({ RoleName, CreateBy })).unwrap();
      }
      setShowMessage(true);
      toast.success("Role is created successfully");
      setCredential({ ...credential, RoleName: "" });
      setTimeout(() => {
        navigate("/partner/role");
      }, 1000);
    } catch (error: any) {
      toast.error(error);
    }
  };
  return (
    <div className="flex justify-center mt-4 items-center h-[60vh]">
      <div className="w-[30%] p-6 bg-white border border-gray-200 rounded-lg shadow">
        <p className="mb-3 font-normal text-gray-700 mb-4">Role Name</p>
        <form onSubmit={handleSubmit}>
          <div className="flex justify-center">
            <InputField
              type="text"
              placeholder="Enter Role Name"
              value={credential.RoleName}
              name="RoleName"
              onChange={handleChange}
              className="w-full border rounded px-2 py-2"
            />
          </div>
          <div className="flex justify-center mt-4">
            <Button buttonContent="Create Role" type="submit" />
          </div>
        </form>
      </div>
      {showMessage && <ToastContainer autoClose={1000} />}
    </div>
  );
};

export default CreateRole;
