import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "./stateSlice";
import getUserRole from "../services/getUserRole";
import { RootState } from "../store/store";
import updateRole from "../services/updateRole";
const state: initialState = {
    status: 'idle',
    data: [],
    error: false
}
const getUserRoleSlice = createSlice({
    name: "user/role",
    initialState: state,
    reducers: {
        ActiveRole: (state, action) => {
            const { ID } = action.payload;
            state.data = state.data?.map((item: any) => {
                return item.RoleID === ID ? { ...item, STATUS: 1 } : { ...item }
            })

        },
        DeactivateRole: (state, action) => {
            const { ID } = action.payload;
            state.data = state.data?.map((item: any) => {
                return item.RoleID === ID ? { ...item, STATUS: 0 } : { ...item }
            })
        },
        deleteRoles: (state, action) => {
            const { ID } = action.payload;
            state.data = state.data?.filter((item: any) => item.RoleID !== ID)
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getUserRole.pending, (state) => {
            state.status = 'loading';
        }).addCase(getUserRole.fulfilled, (state, action) => {
            state.status = 'success';
            state.data = action.payload.Result;
        })
            .addCase(getUserRole.rejected, (state, action) => {
                state.status = 'error';
            }).addCase(updateRole.pending, (state, action) => {
                state.status = 'loading';
            }).addCase(updateRole.fulfilled, (state, action) => {
                state.status = 'success';
            }).addCase(updateRole.rejected, (state, action) => {
                state.status = 'error';
            })
            .addDefaultCase((state) => ({ ...state }))
    }
})

export const selectRole = (state: RootState) => {
    const { data } = state.getUserRoleSlice
    const customisedData = data?.filter((item: any) => (item.STATUS === 1) || (item.STATUS === 0))?.map((item: any) => ({
        ...item,
        STATUS: item.STATUS === 1 ? 'Active' : "Inactive",
    }))
    return customisedData;
}
export const selectRoleNameAndId = (state: RootState) => {
    const { data } = state.getUserRoleSlice
    const customisedData = data?.filter((item: any) => (item.STATUS === 1))?.map((item: any) => ({
        label: item.RoleName,
        value: item.RoleID
    }))
    return customisedData;
}

export default getUserRoleSlice.reducer
export const { ActiveRole, DeactivateRole, deleteRoles } = getUserRoleSlice.actions;