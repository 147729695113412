import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "./stateSlice";
import { RootState } from "../store/store";
import PartnerNotUploadedMOU from "../services/PartnerNotUploadedMOU";
import AllPartnersList from "../services/AllPartnersList";
const state: initialState = {
    status: "idle",
    error: false,
    data: [],
};
const AllPartnerListSlice = createSlice({
    name: "get/partner/list",
    initialState: state,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(AllPartnersList.pending, (state) => {
                state.status = "loading";
            })
            .addCase(AllPartnersList.fulfilled, (state, action) => {
                state.status = "success";
                state.data = action.payload.Result;
            })
            .addCase(AllPartnersList.rejected, (state, action) => {
                state.status = "error";
                state.error = true;
            })
            .addDefaultCase((state) => ({ ...state }));
    },
});

export const selectAllPartnerList = (state: RootState) => {
    const { data } = state.AllPartnerListSlice
    return data;
}
export default AllPartnerListSlice.reducer;
