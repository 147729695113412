import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { useAppDispatch, useAppSelector } from "../../../../redux/hook/hooks";
import getSchoolGradeDetails from "../../../../redux/services/getSchoolGradeDetails";
import getSchoolKycDetails from "../../../../redux/services/getSchoolKycDetail";
import getSchoolQuizDetails from "../../../../redux/services/getSchoolQuizDetails";
import { selectSchoolGradeDetails } from "../../../../redux/slice/getSchoolGradeDetailSlice";
import { selectSchoolQuizDetails } from "../../../../redux/slice/getSchoolQuizDetailSlice";
import ChartComponent from "../../../Chart/ChartComponent";
interface RowProps {
  row: any;
}

const SchoolGrade: React.FC<RowProps> = ({ row }) => {
  const [chartValue, setChartValue] = useState<any>([]);
  const [chartLabel, setChartLabel] = useState<any>([]);
  const GradeColumn = [
    {
      name: "First grade",
      selector: (row: any) => row.firstgrade,
      sortable: true,
    },
    {
      name: "Second grade",
      selector: (row: any) => row.secondgrade,
    },
    {
      name: "Third grade",
      selector: (row: any) => row.thirdgrade,
    },
    {
      name: "Fourth grade",
      selector: (row: any) => row.fourthgrade,
    },
    {
      name: "Fifth grade",
      selector: (row: any) => row.fivegrade,
    },
    {
      name: "Six grade",
      selector: (row: any) => row.sixgrade,
    },
    {
      name: "Sevent grade",
      selector: (row: any) => row.seventgrade,
    },
    {
      name: "Eight grade",
      selector: (row: any) => row.eightgrade,
    },
    {
      name: "Nine grade",
      selector: (row: any) => row.eightgrade,
    },
    {
      name: "Ten grade",
      selector: (row: any) => row.tengrade,
    },
    {
      name: "Eleventh grade",
      selector: (row: any) => row.eleventgrade,
    },
    {
      name: "Twelve grade",
      selector: (row: any) => row.twelvegrade,
    },
    {
      name: "Total",
      selector: (row: any) => row.total,
    },
  ];
  const dispatch = useAppDispatch();
  const SchoolGradeData = useAppSelector(selectSchoolGradeDetails);
  const handleSchoolQuizDetail = async () => {
    try {
      const credential = {
        School: row.school_id,
        Filter: row.Filter,
      };
      const result = await dispatch(getSchoolGradeDetails(credential)).unwrap();
    } catch (error) {}
  };
  useEffect(() => {
    handleSchoolQuizDetail();
  }, []);
  useEffect(() => {
    if (SchoolGradeData.length > 0) {
      const keys = Object.keys(SchoolGradeData[0]).filter(
        (item) => item !== "school_id" && item !== "Filter"
      );
      setChartLabel(keys);
      const values = [];
      for (let property in SchoolGradeData[0]) {
        if (property !== "school_id" && property !== "Filter") {
          values.push(SchoolGradeData[0][property]);
        }
      }
      setChartValue(values);
    }
  }, [SchoolGradeData]);
  return (
    <div>
      <div className="pt-[15px]">
        <h1 className="text-2xl">School Student Grade Detail</h1>
        <div>
          <DataTable
            className="z-0"
            columns={GradeColumn}
            data={SchoolGradeData}
            fixedHeader
          />
          <ChartComponent chartValue={chartValue} chartLabel={chartLabel} />
        </div>
      </div>
    </div>
  );
};

export default SchoolGrade;
