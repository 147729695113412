import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "./stateSlice";
import roleMenuUnMapping from "../services/roleMenuUnMapping";
const state: initialState = {
    status: 'idle',
    message: "",
    error: false
}
const roleMenuUnmappingSlice = createSlice({
    name: "role/menu/unmapping",
    initialState: state,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(roleMenuUnMapping.pending, (state) => {
            state.status = 'loading';
        }).addCase(roleMenuUnMapping.fulfilled, (state) => {
            state.status = 'success';
            state.message = "Unmapped successfully";
        })
            .addCase(roleMenuUnMapping.rejected, (state) => {
                state.status = 'error';
            })
            .addDefaultCase((state) => ({ ...state }))
    }
})
export default roleMenuUnmappingSlice.reducer;