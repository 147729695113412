import { createAsyncThunk } from "@reduxjs/toolkit";
import partnerHttpRequest from "../Apicall/partnerHttpRequest";
import { QUIZ_LEVEL_2_DETAILS } from "../endpoint/constant";

const GetQuizLevel2Details = createAsyncThunk<any, any>('quiz/level/2', async (data, { rejectWithValue }) => {

    try {
        const response = await partnerHttpRequest.post(QUIZ_LEVEL_2_DETAILS, data)
        return response.data
    } catch (error) {

    }

})
export default GetQuizLevel2Details;
