export const LOGIN = 'partner/login';
export const FORGOT_PASSWORD = "partner/forgotpassword"
export const RESET_PASSWORD = "partner/ResetPassword"
export const VERIFY_OTP = "partner/VerifyOTP";
export const ROLE_LIST = "role/GetRoleListByPartnerUserID";
export const ROLE_ACTIVATE = "role/active";
export const ROLE_DEACTIVATE = "role/deactive";
export const ROLE_DELETE = "role/delete";
export const CREATE_ROLE = "role/Create";
export const UPDATE_ROLE = "role/Update";
export const GET_ROLE_MENU = "rolemenumapping/listByRoleID";
export const ROLE_MENU_MAPPING = "rolemenumapping/mapping";
export const ROLE_MENU_UNMAPPING = "rolemenumapping/unmapping";
export const GET_PARTNER_USER = "partner/getUserListByPartnerUserID";
export const UPDATE_PARTNER_USER = "partner/UpdatePartnerUser";
export const CREATE_PARTNER_USER = "partner/CreatePartnerUser";
export const PARTNER_USER_DELETE = "/partner/deleteUser";
export const PARTNER_USER_ACTIVE = "partner/ActiveUser";
export const PARTNER_USER_INACTIVE = "partner/ActiveUser";
export const GET_SUBJECT = "partner/subjectList";
export const GET_PLATFORM_TYPE = "partner/GetPlateformType";
export const GET_PARTNER_TYPE = "partner/GetPartnerType";
export const GET_CATEGORY_LIST = "partner/GetCategoryList";
export const REGISTRATION = "partner/create";
export const QUIZ_LEVEL_1_DETAILS = "report/GetQuizLevel1Details";
export const QUIZ_LEVEL_2_DETAILS = "report/GetQuizLevel2Details";
export const QUIZ_LEVEL_3_DETAILS = "report/GetQuizLevel3Details";
export const KYC_LEVEL_1_DETAILS = "report/GetKycLevel1Details";
export const KYC_LEVEL_2_DETAILS = "report/GetKycLevel2Details";
export const KYC_LEVEL_3_DETAILS = "report/GetKycLevel3Details";
export const QUIZ_TRACK_INITIAL =
  "report/QuizTrackInitialReportByPartnerSource";
export const QUIZ_TAKING = "report/QuizTaking";
export const QUIZ_BY_STUDENT_CLASS = "report/QuizTrackStudentClass";
export const QUIZ_BY_SUBJECT_LIST = "report/QuizTrackSubjectList";
export const QUIZ_BY_STATUS_LIST = "report/QuizTrackKYCStatusList";
export const SCHOOL_KYC_DETAIL = "report/GetPartnerSchoolKYCDetails";
export const SCHOOL_QUIZ_DETAIL = "report/GetPartnerSchoolQuizDetails";
export const SCHOOL_GRADE_DETAIL = "report/GetPartnerSchoolGradeDetails";
export const SCHOOL_STUDENT_SUBJECT_DETAIL = "report/GetPartnerSchoolSubjectDetails";
export const SCHOOL_STUDENT_SUBJECT_GRADE_DETAIL = "report/GetPartnerSchoolSubjectGradeAverageDetails";
export const GET_PARTNER_SOURCE = "report/getPartnerSourceByPartnerUserID";
export const DOWNLOAD_PARTNER_INTEGRATION = "report/DownloadPartnerIntegrationKit"
export const QUIZ_TRACKING_DETAILS = "report/QuizTrackDetails";
export const PARTNER_LIST = 'partner/Lelev1PartnerList';
export const VERIFY_PARTNER = "partner/Lelev1Verify"
export const GET_MENU_LIST_ROLE_ID = "role/GetMenuListByRoleID";
export const LEVEL_2_PARTNER_LIST = 'partner/Lelev2PartnerList';
export const GET_USER_ID_ROLE_ID = 'report/getPartnerSourceByPartnerUserID'
export const MOU_NOT_UPLOADED_LIST = 'partner/Lelev2MOUNotUploadedPartnerList';
export const ALL_PARTNER_LIST = 'partner/list';
export const REJECTED_PARTNER = 'partner/RejectedPartnerList';