import React, { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../redux/hook/hooks";
import Table from "../Table/Table";
import getKycLevelThreeData from "../../redux/services/getKycLevelthree";
import { SelectKycLevel3 } from "../../redux/slice/KycLevel3Slice";
import { useNavigate } from "react-router-dom";
import Loader from "../../GlobalLoader/Loader";

const KycLevelThree: React.FC = () => {
  const [loader, setLoader] = useState(false);
  const navigate = useNavigate();
  const [search, setSearch] = useState({
    mobileNo: "",
    registrationFrom: "",
    registrationTo: "",
  });
  const [partnerSource, setPartnerSource] = useState({
    Filter: `sm.partner_source in (${localStorage.getItem("partnerSource")})`,
  });
  const dispatch = useAppDispatch();
  const TableData = useAppSelector(SelectKycLevel3);

  const handleKycLevelThree = async (data: any) => {
    try {
      setLoader(true);
      await dispatch(getKycLevelThreeData(data)).unwrap();
      setLoader(false);
    } catch (error) {}
  };

  const column = [
    {
      name: "Student",
      selector: (row: any) => row.CountStudent,
      sortable: true,
      cell: (row: any) => {
        return (
          <p
            onClick={() => {
              navigate("/partner/kyc-details3", {
                state: { row, type: "student", partnerSource },
              });
            }}
          >
            {row.CountStudent}
          </p>
        );
      },
      style: {
        cursor: "pointer",
      },
    },
    {
      name: "Status",
      selector: (row: any) => row.STATUS,
      sortable: true,
    },
  ];
  const handleChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
  ) => {
    setSearch({ ...search, [e.target.name]: e.target.value });
  };

  const handleSearchButton = () => {
    let condition = `sm.partner_source in (${localStorage.getItem(
      "partnerSource"
    )})`;

    if (search.mobileNo) {
      condition += `and sm.mobile_no = '${search.mobileNo}'`;
    }
    if (search.registrationFrom) {
      let date = search.registrationFrom;

      condition += `and skd.level3_date >= '${date + " 00:00:00"}'`;
    }
    if (search.registrationTo) {
      let date = search.registrationTo;

      condition += `and skd.level3_date <= '${date + " 23:59:59"}'`;
    }
    const filterObject = { Filter: condition };
    handleKycLevelThree(filterObject);
  };
  useEffect(() => {
    handleKycLevelThree(partnerSource);
  }, []);
  return (
    <>
      <div className="px-8">
        <div className="pt-[15px]">
          <h1 className="text-2xl">KYC Level 3 Report Dashboard</h1>
        </div>
        <div className="grid grid-cols-4 gap-4 mt-[12px]">
          <div className="col-span-1 border-r-[1px] border-[#bbbaba] pr-[20px]">
            <div
              className="flex justify-between px-1
          "
            >
              <h1 className="flex items-center">Filter</h1>
              <button
                className="px-[10px] bg-blue-500 hover:bg-blue-700 text-white rounded-[10px]"
                onClick={() => {
                  handleSearchButton();
                }}
              >
                Search{" "}
              </button>
            </div>
            <div className="py-2.5 pr-[5px] items-end [&_div]:py-[5px] ">
              <div>
                <input
                  className="px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400 w-full"
                  type="text"
                  name="mobileNo"
                  placeholder="Student Mobile No."
                  value={search.mobileNo}
                  onChange={handleChange}
                  maxLength={10}
                />
              </div>
              <div>
                <label>Registration from:</label>
                <input
                  className="px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400 w-full"
                  type="date"
                  name="registrationFrom"
                  placeholder="Registration Date From"
                  onChange={handleChange}
                  value={search.registrationFrom}
                />
              </div>
              <div>
                <label>Registration to:</label>
                <input
                  className="px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400 w-full"
                  type="date"
                  name="registrationTo"
                  placeholder="Registration Date To"
                  value={search.registrationTo}
                  onChange={handleChange}
                />
              </div>
              {/* <div>
                <input
                  className="px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400 w-full"
                  type="text"
                  name="studentMobileNo"
                  placeholder="School Name"
                  maxLength={10}
                />
              </div> */}
            </div>
          </div>
          {/* <div className="col-span-4 h-[78vh]">
            <img className="m-auto h-[80%]" src={filterImg} />
            <p className="flex justify-center">
              Please Select Filter and press Filter Search button to see the
              Quiz details !!!
            </p>
          </div> */}
          <div className="col-span-3 h-[78vh] tbl-selection">
            {loader ? <Loader /> : <Table data={TableData} columns={column} />}
          </div>
        </div>
      </div>
    </>
  );
};

export default KycLevelThree;
