import { createAsyncThunk } from "@reduxjs/toolkit";
import partnerHttpRequest from "../Apicall/partnerHttpRequest";
import { CREATE_ROLE } from "../endpoint/constant";
export interface RoleCredential {
    RoleName: string,
    CreateBy: string,
    RoleID?: string
}
const createRole = createAsyncThunk<any, RoleCredential>('create/role', async (data, { rejectWithValue }) => {
    try {
        const response = await partnerHttpRequest.post(CREATE_ROLE, data)
        const { ErrorCode } = response.data;
        if (ErrorCode !== 200) {
            throw new Error("Internal server error")
        }
        return response.data
    } catch (error: any) {
        const err = error?.response?.data?.Message;
        return rejectWithValue(err)
    }
})
export default createRole;