import { createAsyncThunk } from "@reduxjs/toolkit";
import partnerHttpRequest from "../Apicall/partnerHttpRequest";
import { LEVEL_2_PARTNER_LIST } from "../endpoint/constant";

const Level2PartnerList = createAsyncThunk('get/partner/level2', async (_, { rejectWithValue }) => {
    try {
        const response = await partnerHttpRequest.get(LEVEL_2_PARTNER_LIST)
        const { ErrorCode } = response.data;
        if (ErrorCode !== 200) {
            throw new Error("Internal server error")
        }
        return response.data
    } catch (error: any) {
        return rejectWithValue(error)
    }
})
export default Level2PartnerList;