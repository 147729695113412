import { useLocation } from "react-router";
import Table from "../../Table/Table";
import { useEffect, useState } from "react";

const PartnerQuizDeletedDetails = () => {
  const location = useLocation();
  const [column, setColumn] = useState([{}]);

  useEffect(() => {
    const key = Object?.keys(location.state[0]).reverse();

    const col = key?.map((item: any) => ({
      name: item,
      id: item,
      selector: (row: any) => row[item],
      sortable: true,
    }));

    setColumn(col);
  }, []);
  return (
    <div>
      <Table data={location.state} columns={column} />
    </div>
  );
};

export default PartnerQuizDeletedDetails;
