import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "./stateSlice";

import userroleMenuMapping from "../services/userroleMenuMapping";
const state: initialState = {
    status: 'idle',
    message: "",
    error: false
}
const roleMenuMappingSlice = createSlice({
    name: "role/menu/mapping",
    initialState: state,
    reducers: {

    },
    extraReducers: (builder) => {
        builder.addCase(userroleMenuMapping.pending, (state) => {
            state.status = 'loading';
        }).addCase(userroleMenuMapping.fulfilled, (state, action) => {
            state.status = 'success';
            state.message = "Mapped successfully";
        })
            .addCase(userroleMenuMapping.rejected, (state, action) => {
                state.status = 'error';
            })
            .addDefaultCase((state) => ({ ...state }))
    }
})
export default roleMenuMappingSlice.reducer;