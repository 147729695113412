import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "./stateSlice";
import userRegistration from "../services/userRegistration";
const state: initialState = {
  status: "idle",
  error: false,
  data: [],
};
const registerSlice = createSlice({
  name: "user/register",
  initialState: state,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(userRegistration.pending, (state) => {
        state.status = "loading";
      })
      .addCase(userRegistration.fulfilled, (state, action) => {
        state.status = "success";
        state.data = action.payload;
      })
      .addCase(userRegistration.rejected, (state, action) => {
        state.status = "error";
        state.error = true;
      })
      .addDefaultCase((state) => ({ ...state }));
  },
});

export default registerSlice.reducer;
