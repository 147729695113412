import { createAsyncThunk } from "@reduxjs/toolkit";
import partnerHttpRequest from "../Apicall/partnerHttpRequest";
import { ROLE_MENU_MAPPING } from "../endpoint/constant";

interface roleMenuMappingCredential {
    RoleID: string;
    MenuID: string;
    CreateBy: string;
}

const userroleMenuMapping = createAsyncThunk<any, any>('mapping', async (data, { rejectWithValue }) => {

    try {
        const response = await partnerHttpRequest.post(ROLE_MENU_MAPPING, data)
        return response.data
    } catch (error) {

    }

})
export default userroleMenuMapping;
