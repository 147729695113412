import InputField from "../../component/InputField/InputField";
import partners from "../../assets/images/partners.svg";
import Button from "../../component/button/Button";
import { ChangeEvent, FormEvent, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../redux/hook/hooks";
import resetPassword from "../../redux/services/resetPassword";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
interface resetPasswordCredential {
  Password: string;
  confirmPassword: string;
}
const ResetPassword = ({ searchParam }: any) => {
  // console.log(, "ssssss");
  const [credential, setCredential] = useState<resetPasswordCredential>({
    Password: "",
    confirmPassword: "",
  });
  const [showMessage, setShowMessage] = useState<boolean>(false);
  const [passwordType, setPasswordType] = useState("password");
  const [confirmPasswordType, setConfirmPasswordType] = useState("password");
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCredential({ ...credential, [e.target.name]: e.target.value });
  };
  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (
      credential.Password.length < 6 ||
      credential.confirmPassword.length < 6
    ) {
      setShowMessage(true);
      toast.warn("Password length should be between 6 to 20 characters");
      return;
    } else if (credential.Password !== credential.confirmPassword) {
      setShowMessage(true);
      toast.warn("password and confirm password should be same");
      return;
    }
    try {
      const PartnerUserID = searchParam.get("prtuserid");
      const data = { Password: credential.Password, PartnerUserID };
      await dispatch(resetPassword(data)).unwrap();
      setShowMessage(true);
      toast.success("Password reset successfully");
      setCredential({ ...credential, Password: "", confirmPassword: "" });
      setTimeout(() => {
        navigate("/");
      }, 2000);
    } catch (error: any) {
      setShowMessage(true);
      toast.error(error);
    }
  };
  return (
    <>
      <div className="w-2/4">
        <div className="w-3/4 h-4/5 m-auto">
          <div className="">
            <h1 className="text-3xl">Reset Password</h1>
            <form className="mt-4" onSubmit={handleSubmit}>
              <div className="relative flex w-4/5 mb-6">
                <InputField
                  className="block px-3 py-2 w-full bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400 flex-auto pl-[35px]"
                  type={passwordType}
                  // minLength={6}
                  // maxLength={20}
                  name="Password"
                  // required={true}
                  placeholder="Enter password"
                  value={credential.Password}
                  onChange={handleChange}
                />
                {passwordType === "password" ? (
                  <AiFillEye
                    className="cursor-pointer relative right-6 top-2.5"
                    onClick={() => {
                      setPasswordType("text");
                    }}
                  />
                ) : (
                  <AiFillEyeInvisible
                    className="cursor-pointer relative right-6 top-2.5"
                    onClick={() => {
                      setPasswordType("password");
                    }}
                  />
                )}
              </div>
              <div className="relative flex w-4/5">
                <InputField
                  className="block px-3 py-2 w-full bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400 flex-auto pl-[35px]"
                  type={confirmPasswordType}
                  name="confirmPassword"
                  // required={true}
                  placeholder="Enter comfirm password"
                  value={credential.confirmPassword}
                  onChange={handleChange}
                />
                {confirmPasswordType === "password" ? (
                  <AiFillEye
                    className="cursor-pointer relative right-6 top-2.5"
                    onClick={() => {
                      setConfirmPasswordType("text");
                    }}
                  />
                ) : (
                  <AiFillEyeInvisible
                    className="cursor-pointer relative right-6 top-2.5"
                    onClick={() => {
                      setConfirmPasswordType("password");
                    }}
                  />
                )}
              </div>
              <Button buttonContent={"Submit"} />

              <br />
            </form>
          </div>
        </div>
      </div>
      {showMessage && <ToastContainer autoClose={2000} />}
    </>
  );
};

export default ResetPassword;
