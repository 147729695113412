import React from "react";
import { Link } from "react-router-dom";

const Verifiedmail = () => {
  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100">
      <div className="bg-white rounded-lg shadow-lg p-8">
        <h2 className="text-2xl font-semibold mb-6">
          Email Successfully Verified
        </h2>
        <p className="text-gray-700 mb-6">
          Congratulations! Your email has been successfully verified.
        </p>
        <Link
          to="/"
          className="bg-blue-500 hover:bg-blue-600 text-white font-semibold py-2 px-4 rounded-full inline-block"
        >
          Continue
        </Link>
      </div>
    </div>
  );
};

export default Verifiedmail;
