import { createAsyncThunk } from "@reduxjs/toolkit";
import partnerHttpRequest from "../Apicall/partnerHttpRequest";
import { GET_PARTNER_TYPE } from "../endpoint/constant";

const getPartnerType = createAsyncThunk(
  "getPartnerType",
  async (_, { rejectWithValue }) => {
    try {
      const response = await partnerHttpRequest.get(GET_PARTNER_TYPE);
      return response.data;
    } catch (error) { }
  }
);
export default getPartnerType;
