import { createAsyncThunk } from "@reduxjs/toolkit";
import partnerHttpRequest from "../Apicall/partnerHttpRequest";
import { RESET_PASSWORD } from "../endpoint/constant";
interface resetPasswordCredential {
    Password: string;
    PartnerUserID: string
}
const resetPassword = createAsyncThunk<any, resetPasswordCredential>('reset/password', async (data, { rejectWithValue }) => {
    try {
        const response = await partnerHttpRequest.post(RESET_PASSWORD, data)
        return response.data;
    } catch (error: any) {
        const { message } = error.response.data
        return rejectWithValue(message)
    }

})
export default resetPassword