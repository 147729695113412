import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store/store";
import { initialState } from "./stateSlice";
import QuizByKycStatus from "../services/QuizByKycStatus";
const state: initialState = {
    status: "idle",
    data: [],
    error: false,
};

const QuizByKycStatusSlice = createSlice({
    name: "get/quiz/by/kyc/status",
    initialState: state,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(QuizByKycStatus.pending, (state) => {
                state.status = "loading";
            })
            .addCase(QuizByKycStatus.fulfilled, (state, action) => {
                state.status = "success";
                state.data = action.payload;
            })
            .addCase(QuizByKycStatus.rejected, (state, action) => {
                state.status = "error";
                state.error = true;
            })
            .addDefaultCase((state) => ({ ...state }));
    },
});

export const SelectQuizByKycStatus = (state: RootState) => {
    ;
    return state?.QuizByKycStatusSlice;
};

export default QuizByKycStatusSlice.reducer;
