import React, { ComponentPropsWithoutRef } from "react";

// interface ButtonProps {
//   buttonContent: string;
//   disableButton?: boolean;
//   onclick?: () => void;
// }
// Instead of this we can use this

export interface ButtonProps extends ComponentPropsWithoutRef<"button"> {
  buttonContent: string;
}

const Button = ({ onClick, type, buttonContent, disabled }: ButtonProps) => {
  return (
    <div className="">
      <button
        className="mt-2 py-1 px-3 bg-blue-500 hover:bg-blue-700 text-white rounded"
        type={type}
        onClick={onClick}
        disabled={disabled}
      >
        {buttonContent}
      </button>
    </div>
  );
};

export default Button;
