import { createAsyncThunk } from "@reduxjs/toolkit";

const getKycLevelTwoData = createAsyncThunk(
  "quizLevelTwo",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await fetch(
        `https://api.societypartner.auroscholar.org/api/report/GetKYCLevel2`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
      const result = await response.json();
      return result;
    } catch (error) {}
  }
);

export default getKycLevelTwoData;