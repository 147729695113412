import { createAsyncThunk } from "@reduxjs/toolkit";
import partnerHttpRequest from "../Apicall/partnerHttpRequest";
import { QUIZ_LEVEL_3_DETAILS } from "../endpoint/constant";

const GetQuizLevel3Details = createAsyncThunk<any, any>('quiz/level/3', async (data, { rejectWithValue }) => {

    try {
        const response = await partnerHttpRequest.post(QUIZ_LEVEL_3_DETAILS, data)
        return response.data
    } catch (error) {

    }

})
export default GetQuizLevel3Details;
