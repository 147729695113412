import { createSlice } from "@reduxjs/toolkit";
import getSchoolStudentSubjectDetails from "../services/getSchoolStudentSubject";
import { RootState } from "../store/store";
import { initialState } from "./stateSlice";

const state: initialState = {
  status: "idle",
  data: [],
  error: false,
};

const getSchoolSubjectDetailSlice = createSlice({
  name: "get/detail/subject/school",
  initialState: state,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSchoolStudentSubjectDetails.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getSchoolStudentSubjectDetails.fulfilled, (state, action) => {
        state.status = "success";
        state.data = action.payload.Result;
      })
      .addCase(getSchoolStudentSubjectDetails.rejected, (state) => {
        state.status = "error";
        state.error = true;
      })
      .addDefaultCase((state) => ({ ...state }));
  },
});

export const selectSchoolSubjectDetails = (state: RootState) => {
  const { data } = state?.getSchoolSubjectDetailSlice;
  return data;
};

export default getSchoolSubjectDetailSlice.reducer;
