import React, { useEffect, useState } from "react";
// import Button from "../../../component/button/Button";
// import SelectField from "../../../component/SelectField/SelectField";
// import Header from "../../../layout/Header/Header";
import { useAppDispatch, useAppSelector } from "../../../redux/hook/hooks";
// import getDistrict from "../../../redux/services/getDistrict";
import getGrade from "../../../redux/services/getGrade";
import getState from "../../../redux/services/getState";
// import { selectDistrict } from "../../../redux/slice/districtSlice";
import { selectGrade } from "../../../redux/slice/gradeSlice";
import { selectState } from "../../../redux/slice/stateSlice";
import Table from "../../Table/Table";
import Select from "react-select";
import { selectKyc } from "../../../redux/slice/getKycSlice";
import getKyc from "../../../redux/services/getKyc";
import getMultiDistrict from "../../../redux/services/getMultiDistrict";
import { selectMultiDistrict } from "../../../redux/slice/multiDistrictSlice";
// import getStudentDetail from "../../../redux/services/getStudentDetail";
// import { SelectStudentDetail } from "../../../redux/slice/studentDetailSLice";
import getSchoolDetails from "../../../redux/services/getSchool";
import { SelectSchoolDetail } from "../../../redux/slice/schoolDetailsSlice";
import Loader from "../../../GlobalLoader/Loader";
import { useNavigate } from "react-router-dom";

const MySchool: React.FC = () => {
  const navigate = useNavigate();
  const [loader, setLoader] = useState(false);
  const [search, setSearch] = useState({
    registrationDateFrom: "",
    registrationDateTo: "",
    grade: "",
    state: "",
    district: "",
    kycStatus: "",
  });
  const [partnerSource, setPartnerSource] = useState({
    Filter: `sm.partner_source in (${localStorage.getItem("partnerSource")})`,
  });
  const dispatch = useAppDispatch();
  const stateOptions = useAppSelector(selectState);
  const districtOptions = useAppSelector(selectMultiDistrict);
  const gradeOptions = useAppSelector(selectGrade);
  const kycStatusOptions = useAppSelector(selectKyc);
  const schoolOptions = useAppSelector(SelectSchoolDetail);

  const handleChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
  ) => {
    setSearch({ ...search, [e.target.name]: e.target.value });
  };
  const handleState = async () => {
    try {
      await dispatch(getState()).unwrap();
    } catch (error) {}
  };
  const handleMultiDistrict = async () => {
    try {
      const { state } = search;
      const data = { ID: state };
      await dispatch(getMultiDistrict(data));
    } catch (error) {}
  };
  const handleGrade = async () => {
    try {
      await dispatch(getGrade()).unwrap();
    } catch (error) {}
  };
  const handleKycStatus = async () => {
    try {
      await dispatch(getKyc()).unwrap();
    } catch (error) {}
  };
  const handleSchoolDetailList = async (data: any) => {
    try {
      setLoader(true);
      await dispatch(getSchoolDetails(data)).unwrap();
      setLoader(false);
    } catch (error) {}
  };

  const getFilter = (
    condition: string,
    param1: string,
    param2: string,
    param4: string,
    param5: string,
    param6: string,
    param7: string
  ) => {
    if (search.registrationDateFrom) {
      let date = search.registrationDateFrom;
      // let newString = string.replace(/-/g, "");
      condition += `and ${param1} >= '${date + "00:00:00"}'`;
    }
    if (search.registrationDateTo) {
      let date = search.registrationDateTo;
      // let newString = string.replace(/-/g, "");
      condition += `and ${param2} <= '${date + "23:59:59"}'`;
    }
    if (search.kycStatus) {
      condition += ` and ${param4} (${search.kycStatus})`;
    }
    if (search.state) {
      condition += ` and ${param5} (${search.state})`;
    }
    if (search.grade) {
      condition += ` and ${param6} (${search.grade})`;
    }
    if (search.district) {
      condition += ` and ${param7} (${search.district})`;
    }
    return condition;
  };
  const handleSearchButton = () => {
    let condition = `sm.partner_source in (${localStorage.getItem(
      "partnerSource"
    )})`;

    const filter = getFilter(
      condition,
      "sm.registration_date",
      "sm.registration_date",
      "sm.kyc_status in",
      "sd.state_id IN",
      "sm.grade IN",
      "sd.district_id IN"
    );

    const filterObject = { Filter: filter };
    setPartnerSource({ ...partnerSource, Filter: filter });
    handleSchoolDetailList(filterObject);
  };
  useEffect(() => {
    // setPartnerSource({
    //   ...partnerSource,
    //   Filter: "sm.partner_source in ('Aimify_WEB')",
    // });
    handleState();
    handleGrade();
    handleKycStatus();
    handleSchoolDetailList(partnerSource);
  }, []);
  useEffect(() => {
    if (search.state) {
      handleMultiDistrict();
    }
  }, [search.state]);

  const extractMultiId = (data: any) => {
    const ids = data?.map((item: any) => `'${item.value}'`).toString();
    return ids;
  };

  const column = [
    {
      name: "Sr. No",
      selector: (row: any, index: number) => index + 1,
      sortable: true,
    },
    {
      name: "School Name",
      selector: (row: any) => row.school_id,
      sortable: true,
    },
    {
      name: "Student Total",
      selector: (row: any) => row.student_count,
      sortable: true,
      cell: (row: any) => {
        return (
          <p
            onClick={() => {
              navigate("/partner/my-school-detail", {
                state: { row, partnerSource },
              });
            }}
          >
            {row.student_count}
          </p>
        );
      },
      style: {
        cursor: "pointer",
      },
    },
  ];

  return (
    <>
      <div className="px-8">
        <div className="pt-[15px]">
          <h1 className="text-2xl">My School Dashboard</h1>
        </div>
        <div className="grid grid-cols-4 gap-4 mt-[12px]">
          <div className="col-span-1 h-[75vh] overflow-y-scroll border-r-[1px] border-[#bbbaba] pr-[20px]">
            <div
              className="flex justify-between
          "
            >
              {/* <Button buttonContent={"Search"} /> */}
              <h1 className="flex items-center">Filter</h1>
              <button
                className="px-[10px] bg-blue-500 hover:bg-blue-700 text-white rounded-[10px]"
                onClick={() => {
                  handleSearchButton();
                }}
              >
                Search{" "}
              </button>
              {/* <Button buttonContent={"Reset"} /> */}
            </div>
            <div className="py-2.5 pr-[5px] items-end [&_div]:py-[5px] ">
              <div>
                <label>Registration from:</label>
                <input
                  className="px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400 w-full"
                  type="date"
                  name="registrationDateFrom"
                  placeholder="Registration Date From"
                  onChange={handleChange}
                />
              </div>
              <div>
                <label>Registration to:</label>
                <input
                  className="px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400 w-full"
                  type="date"
                  name="registrationDateTo"
                  placeholder="Registration Date To"
                  onChange={handleChange}
                />
              </div>
              <label>State:</label>
              <Select
                name="state"
                isMulti
                options={stateOptions}
                onChange={(selectedOption: any) => {
                  const val = extractMultiId(selectedOption);
                  setSearch({
                    ...search,
                    state: val,
                  });
                }}
              />
              <label>District:</label>
              <Select
                name="district"
                isMulti
                options={districtOptions}
                onChange={(selectedOption: any) => {
                  const val = extractMultiId(selectedOption);
                  setSearch({
                    ...search,
                    district: val,
                  });
                }}
              />
              <label>Grade:</label>
              <Select
                name="grade"
                isMulti
                options={gradeOptions}
                onChange={(selectedOption: any) => {
                  const val = extractMultiId(selectedOption);
                  setSearch({
                    ...search,
                    grade: val,
                  });
                }}
              />
              <label>KYC Status:</label>
              <Select
                name="studentKyc"
                isMulti
                options={kycStatusOptions}
                onChange={(selectedOption: any) => {
                  const val = extractMultiId(selectedOption);
                  setSearch({
                    ...search,
                    kycStatus: val,
                  });
                }}
              />
            </div>
          </div>

          <div className="col-span-3">
            {loader ? (
              <Loader />
            ) : (
              <Table data={schoolOptions} columns={column} />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default MySchool;
