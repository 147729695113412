import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../redux/hook/hooks";
import GetQuizLevel1Details from "../../redux/services/GetQuizLevel1Details";
import Table from "../Table/Table";
import { selectQuizDetails1 } from "../../redux/slice/GetQuizLevel1DetailsSlice";
import Loader from "../../GlobalLoader/Loader";
const QuizLevelDetails = () => {
  const [loader, setLoader] = useState(false);
  const column = [
    {
      name: "User Id",
      selector: (row: any) => row.user_id,
      sortable: true,
    },
    {
      name: "Registration Id",
      selector: (row: any) => row.registration_id,
    },
    {
      name: "Student Name",
      selector: (row: any) => row.student_name,
    },
    {
      name: "Student Mobile No",
      selector: (row: any) => row.mobile_no,
    },
    {
      name: "Student Email Id",
      selector: (row: any) => row.email_id,
    },
    {
      name: "Partner source",
      selector: (row: any) => row.partner_source,
    },
    {
      name: "School Name",
      selector: (row: any) => row.school_name,
    },
    {
      name: "Quiz Month",
      selector: (row: any) => row.school_name,
    },
    {
      name: "Quiz Subjects",
      selector: (row: any) => row.subject,
    },
    {
      name: "Quiz ID",
      selector: (row: any) => row.eklavvya_exam_id,
    },
    {
      name: "Quiz Attempt",
      selector: (row: any) => row.quiz_attempt,
    },
    {
      name: "Score",
      selector: (row: any) => row.score,
    },
    {
      name: "L1 Agent Name",
      selector: (row: any) => row.user_name,
    },
    {
      name: "Level1 verification Date",
      selector: (row: any) => row.level1_checked_date,
    },
    {
      name: "L1 Status",
      selector: (row: any) => row.level1_approve,
    },

    {
      name: "TATlevelone",
      selector: (row: any) => row.TATlevelone,
    },
  ];
  const location = useLocation();
  const dispatch = useAppDispatch();
  const selector = useAppSelector(selectQuizDetails1);
  const getDetails = async () => {
    const { row, type } = location.state;
    try {
      const credential = {
        Status: type,
        KYCStatus: row.LEVEL1_approve,
        Filter: row.Filter,
      };
      setLoader(true);
      const response = await dispatch(
        GetQuizLevel1Details(credential)
      ).unwrap();
      setLoader(false);
    } catch (error) {}
  };
  useEffect(() => {
    getDetails();
  }, []);
  return (
    <div className="mx-5">
      {loader ? <Loader /> : <Table data={selector} columns={column} />}
    </div>
  );
};

export default QuizLevelDetails;
