import { createAsyncThunk } from "@reduxjs/toolkit";
import partnerHttpRequest from "../Apicall/partnerHttpRequest";
import { MOU_NOT_UPLOADED_LIST } from "../endpoint/constant";

const PartnerNotUploadedMOU = createAsyncThunk<any>('partner/notUploadedMou', async (_, { rejectWithValue }) => {
    try {
        const response = await partnerHttpRequest.get(MOU_NOT_UPLOADED_LIST)
        const { ErrorCode } = response.data;
        if (ErrorCode !== 200) {
            throw new Error("Internal server error")
        }
        return response.data
    } catch (error: any) {

        return rejectWithValue(error)
    }
})
export default PartnerNotUploadedMOU;