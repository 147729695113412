import React from "react";
import DataTable from "react-data-table-component";

interface TableProps {
  columns: any[];
  data: any[];
  noDataComponent?: string;
}

const Table: React.FC<TableProps> = ({ columns, data, noDataComponent }) => {
  return (
    <div>
      <DataTable
        className="z-0"
        columns={columns}
        data={data}
        pagination
        fixedHeader
        fixedHeaderScrollHeight="420px"
        // noDataComponent={data.length === 0 && "No data found"}
      />
    </div>
  );
};

export default Table;
