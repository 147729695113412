import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store/store";
import { initialState } from "./stateSlice";
import QuizTrackInitialReportByPartnerSource from "../services/QuizTrackInitialReportByPartnerSource";

const state: initialState = {
    status: "idle",
    data: [],
    error: false,
};

const QuizTrackInitialReportByPartnerSourceSlice = createSlice({
    name: "/initial/quiz/report",
    initialState: state,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(QuizTrackInitialReportByPartnerSource.pending, (state) => {
                state.status = "loading";
            })
            .addCase(QuizTrackInitialReportByPartnerSource.fulfilled, (state, action) => {

                state.status = "success";
                state.data = action.payload.Result;
            })
            .addCase(QuizTrackInitialReportByPartnerSource.rejected, (state, action) => {
                state.status = "error";
                state.error = true;
            })
            .addDefaultCase((state) => ({ ...state }));
    },
});

export const SelectQuizReportInitially = (state: RootState) => {
    const { data } = state?.QuizTrackInitialReportByPartnerSourceSlice;

    const customisedData1 = data?.map((item: any) => {
        return {
            id: 1,
            label: "Total quiz initiated - not completed",
            student_count: item.examnotcompletestudent,
            quiz_count: item.examnotcomplete,
            Eligible_quiz_student_count: item.examnotcompletewinnerstudent,
            Eligible_quiz:
                item.examnotcompletewinnerstudent,
        }
    })
    const customisedData2 = data?.map((item: any) => {
        return {
            id: 2,
            label: "Total core quiz",
            student_count: item.firsttimequizstudent,
            quiz_count: item.firsttimequiz,
            Eligible_quiz_student_count: item.firsttimequizwinnerstudent,
            Eligible_quiz:
                item.firsttimequizwinnerstudent,
        }
    })
    const customisedData3 = data?.map((item: any) => {
        return {
            id: 3,
            label: "Total retake",
            student_count: item.retakestudent,
            quiz_count: item.retake,
            Eligible_quiz_student_count: item.retakewinnerstudent,
            Eligible_quiz:
                item.retakewinnerstudent,
        }
    })
    const customisedData4 = data?.map((item: any) => {
        return {
            id: 3,
            label: "Total quiz",
            student_count: item.allquizstudent,
            quiz_count: item.allquiz,
            Eligible_quiz_student_count: item.allquizwinnerstudent,
            Eligible_quiz:
                item.allquizwinnerstudent,
        }
    })
    const customisedData5 = data?.map((item: any) => {
        return {
            id: 4,
            label: "More than 1 month old registration",
            student_count: item.beforelastmonthquizstudent,
            quiz_count: item.beforelastmonthquiz,
            Eligible_quiz_student_count: item.beforelastmonthquizwinnerstudentt,
            Eligible_quiz:
                item.beforelastmonthquizwinnerstudent,
        }
    })
    const customisedData6 = data?.map((item: any) => {
        return {
            id: 5,
            label: "Less than 1 month old registration",
            student_count: item.thismonthquizstudent,
            quiz_count: item.thismonthquiz,
            Eligible_quiz_student_count: item.thismonthquizwinnerstudent,
            Eligible_quiz:
                item.thismonthquizwinnerstudent,
        }
    })


    const finalData = [customisedData1?.[0], customisedData2?.[0], customisedData3?.[0], customisedData4?.[0], customisedData5?.[0], customisedData6?.[0]]

    return finalData;
};

export default QuizTrackInitialReportByPartnerSourceSlice.reducer;
