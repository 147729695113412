import { createSlice } from "@reduxjs/toolkit";
import getSchoolQuizDetails from "../services/getSchoolQuizDetails";
import { RootState } from "../store/store";
import { initialState } from "./stateSlice";

const state: initialState = {
  status: "idle",
  data: [],
  error: false,
};

const getSchoolQuizDetailSlice = createSlice({
  name: "get/detail/quiz/school",
  initialState: state,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSchoolQuizDetails.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getSchoolQuizDetails.fulfilled, (state, action) => {
        state.status = "success";
        state.data = action.payload.Result;
      })
      .addCase(getSchoolQuizDetails.rejected, (state) => {
        state.status = "error";
        state.error = true;
      })
      .addDefaultCase((state) => ({ ...state }));
  },
});

export const selectSchoolQuizDetails = (state: RootState) => {
  const { data } = state?.getSchoolQuizDetailSlice;
  return data;
};

export default getSchoolQuizDetailSlice.reducer;
