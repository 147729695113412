import { createAsyncThunk } from "@reduxjs/toolkit";
import httpRequest from "../Apicall/httpRequest";

const getStudentDetail = createAsyncThunk(
  "studentDetail",
  async (data: any, { rejectWithValue }) => {
    try {
      const response = await fetch(
        `https://api.societypartner.auroscholar.org/api/report/getPartnerStudentDetails`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );
      const result = await response.json();
      return result;
    } catch (error) {}
  }
);

export default getStudentDetail;
