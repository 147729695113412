import { createAsyncThunk } from "@reduxjs/toolkit";
import partnerHttpRequest from "../Apicall/partnerHttpRequest";
import { GET_SUBJECT } from "../endpoint/constant";

const getSubject = createAsyncThunk(
  "subject",
  async (_, { rejectWithValue }) => {
    try {
      const response = await partnerHttpRequest.get(GET_SUBJECT);
      return response.data;
    } catch (error) { }
  }
);
export default getSubject;
