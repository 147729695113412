import React, { useEffect, useState } from "react";
import Header from "../../layout/Header/Header";
import { useAppDispatch, useAppSelector } from "../../redux/hook/hooks";
import getQuizLevelThreeData from "../../redux/services/getQuizLevelThree";
import { SelectQuizLevel3 } from "../../redux/slice/QuizLevel3Slice";
import Table from "../Table/Table";
import { useNavigate } from "react-router-dom";
import Loader from "../../GlobalLoader/Loader";

const QuizLevelThree: React.FC = () => {
  const [loader, setLoader] = useState(false);
  const [search, setSearch] = useState({
    mobileNo: "",
    registrationFrom: "",
    registrationTo: "",
  });
  const [partnerSource, setPartnerSource] = useState({
    Filter: `ev.partner_source in (${localStorage.getItem("partnerSource")})`,
  });
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const TableData = useAppSelector(SelectQuizLevel3);

  const handleQuizLevelThree = async (data: any) => {
    try {
      setLoader(true);
      const result = await dispatch(getQuizLevelThreeData(data)).unwrap();
      setLoader(false);
    } catch (error) {}
  };
  const handleChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
  ) => {
    setSearch({ ...search, [e.target.name]: e.target.value });
  };

  const getFilter = (condition: string, param1: string, param2: string) => {
    if (search.mobileNo) {
      condition += `and ev.mobile_no = '${search.mobileNo}'`;
    }
    if (search.registrationFrom) {
      let string = search.registrationFrom;
      let newString = string.replace(/-/g, "");
      condition += `and ${param1} >= '${newString + "0000"}'`;
    }
    if (search.registrationTo) {
      let string = search.registrationTo;
      let newString = string.replace(/-/g, "");
      condition += `and ${param2} >= '${newString + "235959"}'`;
    }
    return condition;
  };
  const handleSearchButton = () => {
    let condition = `ev.partner_source in (${localStorage.getItem(
      "partnerSource"
    )})`;
    const filter = getFilter(
      condition,
      "ev.level3_checked_date",
      "ev.level3_checked_date"
    );
    const filterObject = { Filter: filter };
    setPartnerSource({ ...partnerSource, Filter: filter });
    handleQuizLevelThree(filterObject);
  };

  const column = [
    {
      name: "Parameter",
      selector: (row: any) => row.LEVEL3_approve,
      sortable: true,
    },
    {
      name: "Student",
      selector: (row: any) => row.CountStudent,
      sortable: true,
      cell: (row: any) => {
        return (
          <p
            onClick={() => {
              navigate("/partner/quiz-details3", {
                state: { row, type: "student", partnerSource },
              });
            }}
          >
            {row.CountStudent}
          </p>
        );
      },
      style: {
        cursor: "pointer",
      },
    },

    {
      name: "Quiz",
      selector: (row: any) => row.quizcount,
      sortable: true,
      cell: (row: any) => {
        return (
          <p
            onClick={() => {
              navigate("/partner/quiz-details3", {
                state: { row, type: "quiz", partnerSource },
              });
            }}
          >
            {row.quizcount}
          </p>
        );
      },
      style: {
        cursor: "pointer",
      },
    },
  ];

  useEffect(() => {
    handleQuizLevelThree(partnerSource);
  }, []);
  return (
    <>
      <div className="px-8">
        <div className="pt-[15px]">
          <h1 className="text-2xl">Quiz Level 3 Report Dashboard</h1>
        </div>
        <div className="grid grid-cols-4 gap-4 mt-[12px]">
          <div className="col-span-1 border-r-[1px] border-[#bbbaba] pr-[20px]">
            <div
              className="flex justify-between px-1
          "
            >
              <h1 className="flex items-center">Filter</h1>
              <button
                className="px-[10px] bg-blue-500 hover:bg-blue-700 text-white rounded-[10px]"
                onClick={() => {
                  handleSearchButton();
                }}
              >
                Search{" "}
              </button>
            </div>
            <div className="py-2.5 pr-[5px] items-end [&_div]:py-[5px] ">
              <div>
                <input
                  className="px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400 w-full"
                  type="text"
                  name="mobileNo"
                  placeholder="Student Mobile No."
                  maxLength={10}
                  value={search.mobileNo}
                  onChange={handleChange}
                />
              </div>
              <div>
                <label>Registration from:</label>
                <input
                  className="px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400 w-full"
                  type="date"
                  name="registrationFrom"
                  placeholder="Registration Date From"
                  onChange={handleChange}
                  value={search.registrationFrom}
                />
              </div>
              <div>
                <label>Registration to:</label>
                <input
                  className="px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400 w-full"
                  type="date"
                  name="registrationTo"
                  placeholder="Registration Date To"
                  value={search.registrationTo}
                  onChange={handleChange}
                />
              </div>
              {/* <div>
                <input
                  className="px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400 w-full"
                  type="text"
                  name="studentMobileNo"
                  placeholder="School Name"
                  maxLength={10}
                />
              </div> */}
            </div>
          </div>
          {/* <div className="col-span-4 h-[78vh]">
            <img className="m-auto h-[80%]" src={filterImg} />
            <p className="flex justify-center">
              Please Select Filter and press Filter Search button to see the
              Quiz details !!!
            </p>
          </div> */}
          <div className="col-span-3 h-[78vh] tbl-selection">
            {loader ? <Loader /> : <Table data={TableData} columns={column} />}
          </div>
        </div>
      </div>
    </>
  );
};

export default QuizLevelThree;
