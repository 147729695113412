import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store/store";
import { initialState } from "./stateSlice";
import getPartnerSource from "../services/getPartnerSource";
import GetMenuListByRoleID from "../services/GetMenuListByRoleID";
import GetUserDataByRoleID from "../services/GetUserDataByRoleID";
const state: initialState = {
    status: "idle",
    data: [],
    error: false,
};

const GetUserDataByRoleIDSlice = createSlice({
    name: "get/user/data",
    initialState: state,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(GetUserDataByRoleID.pending, (state) => {
                state.status = "loading";
            })
            .addCase(GetUserDataByRoleID.fulfilled, (state, action) => {
                state.status = "success";
                state.data = action.payload.Result;
            })
            .addCase(GetUserDataByRoleID.rejected, (state,) => {
                state.status = "error";
                state.error = true;
            })
            .addDefaultCase((state) => ({ ...state }));
    },
});

export const selectMenuList = (state: RootState) => {
    return state.GetUserDataByRoleIDSlice.data
};

export default GetUserDataByRoleIDSlice.reducer;
