import { createAsyncThunk } from "@reduxjs/toolkit";
import httpRequest from "../Apicall/httpRequest";

const getKyc = createAsyncThunk("kyc", async (_, { rejectWithValue }) => {
  try {
    const response = await fetch(
      `https://api.societypartner.auroscholar.org/api/report/getKYCStatus`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
    const result = await response.json();
    return result;
  } catch (error) {}
});

export default getKyc;