import React, { useEffect, useState, ChangeEvent, FormEvent } from "react";
import InputField from "../../component/InputField/InputField";
import partners from "../../assets/images/partners.svg";
import emailIcon from "../../assets/images/emailicon.svg";
import camera from "../../assets/images/Camera.svg";
import logo from "../../assets/images/Logo.svg";
import Button from "../../component/button/Button";
import SelectField from "../../component/SelectField/SelectField";
import { Link, Navigate, useLocation, useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../redux/hook/hooks";
import getState from "../../redux/services/getState";
import { selectState } from "../../redux/slice/stateSlice";
import getDistrict from "../../redux/services/getDistrict";
import { selectDistrict } from "../../redux/slice/districtSlice";
import getGrade from "../../redux/services/getGrade";
import { selectGrade } from "../../redux/slice/gradeSlice";
import { selectGetSubject } from "../../redux/slice/subjectSlice";
import getSubject from "../../redux/services/getSubject";
import { selectPlatformType } from "../../redux/slice/platformSlice";
import getPlatform from "../../redux/services/getPlatform";
import userRegistration from "../../redux/services/userRegistration";
import getPartnerType from "../../redux/services/getPartnerType";
import { partnerType } from "../../redux/slice/partnerTypeSlice";
import { selectCategoryType } from "../../redux/slice/categorySlice";
import getCategory from "../../redux/services/getCategory";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
import Select from "react-select";
import {
  indianMobileNumber,
  partnerNameRegex,
  urlRegex,
} from "../../utilities/Regex";
import {
  faIdBadge,
  faUser,
  faEnvelope,
  faBuilding,
} from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGlobe,
  faGraduationCap,
  faHandshakeSimple,
  faLocationDot,
  faLock,
  faPhone,
  faSliders,
} from "@fortawesome/free-solid-svg-icons";

const Registration: React.FC = () => {
  const navigate = useNavigate();
  const [showMessage, setShowMessage] = useState<boolean>(false);
  const [passwordType, setPasswordType] = useState("password");
  const [disableButton, setDisableButton] = useState<boolean>(false);
  const [platformArray, setPlatformArray] = useState<any[]>([]);
  const [showProfileImage, setProfileImage] = useState("");
  const [loader, setLoader] = useState(false);
  const [signupData, setSignupData] = useState({
    Email: "",
    PartnerName: "",
    MobileNo: "",
    WhatsAppNo: "",
    state: "",
    district: "",
    CallBackURL: "",
    PartnerLogo: "",
    WebsiteURL: "",
    PartnerTypeID: "",
    PartnerGrade: "",
    PartnerSubject: "",
    PartnerPlateform: "",
    CategoryID: "",
    Password: "",
    CompanyName: "",
    BrandName: "",
    Address: "",
  });

  // const [partnerNameError, setPartnerNameError] = useState(false);
  // const [mobileNumberError, setMobileNumberError] = useState(false);
  // const [whatsAppmobileNumberError, setWhatsAppMobileNumberError] =
  //   useState(false);

  const dispatch = useAppDispatch();
  const stateOptions = useAppSelector(selectState);
  const districtOptions = useAppSelector(selectDistrict);
  const gradeOptions = useAppSelector(selectGrade);
  const subjectOptions = useAppSelector(selectGetSubject);
  const platformOptions = useAppSelector(selectPlatformType);
  const partnerTypeOptions = useAppSelector(partnerType);
  const categoryTypeOptions = useAppSelector(selectCategoryType);

  const handleChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setSignupData({ ...signupData, [name]: value });
  };
  const handleState = async () => {
    // setSignupData({ ...signupData, district: "" });
    try {
      await dispatch(getState()).unwrap();
    } catch (error) {}
  };
  const handleDistrict = async () => {
    setSignupData({ ...signupData, district: "" });
    try {
      const { state } = signupData;
      const data = { ID: state };
      await dispatch(getDistrict(data));
    } catch (error) {}
  };

  const handleGrade = async () => {
    try {
      await dispatch(getGrade()).unwrap();
    } catch (error) {}
  };
  const selectImage = (e: ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) return;
    const file = e.target.files[0];
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      const base64 = reader.result as string;
      setProfileImage(base64);
      setSignupData({ ...signupData, PartnerLogo: base64.split(",")[1] });
    };
    reader.onerror = function (error) {};
  };

  const handleSubject = async () => {
    try {
      await dispatch(getSubject()).unwrap();
    } catch (error) {}
  };
  const handlePlatformType = async () => {
    try {
      await dispatch(getPlatform()).unwrap();
    } catch (error) {}
  };
  const handlePartnerTypeData = async () => {
    try {
      await dispatch(getPartnerType()).unwrap();
    } catch (error) {}
  };
  const handleCategoryTypeData = async () => {
    try {
      await dispatch(getCategory()).unwrap();
    } catch (error) {}
  };

  const handlePlatformSelect = (e: any, data: any) => {
    if (e.target.checked) {
      setPlatformArray((prevPlatformArray) => {
        if (!prevPlatformArray.includes(data)) {
          return [...prevPlatformArray, data];
        }
        return prevPlatformArray;
      });
    } else {
      setPlatformArray((prevPlatformArray) =>
        prevPlatformArray.filter((item) => item !== data)
      );
    }
  };
  // Update signupData whenever platformArray changes
  useEffect(() => {
    const value = platformArray.join(",");
    setSignupData({ ...signupData, PartnerPlateform: value });
  }, [platformArray]);

  const showErrorMessages = (message: string) => {
    setShowMessage(true);
    toast.warn(message);
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const isPartnerNameValid = partnerNameRegex.test(signupData.PartnerName);
    const isMobileNumberValid = indianMobileNumber.test(signupData.MobileNo);
    const isWhatsAppNumberValid = indianMobileNumber.test(
      signupData.WhatsAppNo
    );
    const isValidWebUrl = urlRegex.test(signupData.WebsiteURL);
    const isValidCallbackUrl = urlRegex.test(signupData.CallBackURL);

    if (
      signupData.PartnerName.length < 3 ||
      signupData.PartnerName.length > 50
    ) {
      showErrorMessages("Please enter partner name (3 to 50 characters)");
      return;
    } else if (!isPartnerNameValid) {
      showErrorMessages("Partner name contains only alphabet");
      return;
    } else if (!signupData.Email) {
      showErrorMessages("Please enter email address");
      return;
    } else if (!isMobileNumberValid) {
      showErrorMessages("Mobile Number should be valid");
      return;
    } else if (signupData.WhatsAppNo && !isWhatsAppNumberValid) {
      showErrorMessages("Mobile Number should be valid");
      return;
    } else if (
      !signupData.CompanyName ||
      signupData.CompanyName.length < 3 ||
      signupData.CompanyName.length > 200
    ) {
      showErrorMessages("Please enter the company name (3 to 200 characters)");
      return;
    } else if (
      !signupData.BrandName ||
      signupData.BrandName.length < 3 ||
      signupData.BrandName.length > 50
    ) {
      showErrorMessages("Please enter the brand name (3 to 50 characters)");

      return;
    } else if (
      !signupData.Address ||
      signupData.Address.length < 3 ||
      signupData.Address.length > 200
    ) {
      showErrorMessages("Please enter the address (3 to 200 characters)");
      return;
    } else if (!signupData.state) {
      showErrorMessages("Please choose the state");
      return;
    } else if (!signupData.district) {
      showErrorMessages("Please choose the district");
      return;
    } else if (!signupData.WebsiteURL) {
      showErrorMessages("Please enter the Website url");
      return;
    } else if (!signupData.CallBackURL) {
      showErrorMessages("Please enter the callback url");
      return;
    } else if (!isValidWebUrl || !isValidCallbackUrl) {
      showErrorMessages("Please provide the valid url");
      return;
    } else if (!signupData.PartnerTypeID) {
      showErrorMessages("Please choose the partner type");
      return;
    } else if (!signupData.CategoryID) {
      showErrorMessages("Please choose the category");
      return;
    } else if (signupData.Password.length < 6) {
      showErrorMessages("Password length should be between 6 to 20");
      return;
    } else if (!signupData.PartnerPlateform) {
      showErrorMessages("Partner platform is required");
      return;
    } else if (!signupData.PartnerLogo) {
      showErrorMessages("Partner logo is required");
      return;
    }

    try {
      setLoader(true);
      const response = await dispatch(userRegistration(signupData)).unwrap();
      const { Message } = response;
      setShowMessage(true);
      toast.success(Message);
      setTimeout(() => {
        navigate("/");
        setLoader(false);
      }, 1500);
      setSignupData({
        Email: "",
        PartnerName: "",
        MobileNo: "",
        WhatsAppNo: "",
        state: "",
        district: "",
        CallBackURL: "",
        PartnerLogo: "",
        WebsiteURL: "",
        PartnerTypeID: "",
        PartnerGrade: "",
        PartnerSubject: "",
        PartnerPlateform: "",
        CategoryID: "",
        Password: "",
        CompanyName: "",
        BrandName: "",
        Address: "",
      });
    } catch (error: any) {
      setLoader(false);
      setShowMessage(true);
      toast.error(error);

      // toast.error(error);
    }
  };

  useEffect(() => {
    if (signupData.state) {
      handleDistrict();
    }
  }, [signupData.state]);

  useEffect(() => {
    handleState();
    handleGrade();
    handleSubject();
    handlePlatformType();
    handlePartnerTypeData();
    handleCategoryTypeData();
  }, []);
  const location = useLocation();
  if (localStorage.getItem("a_t")) {
    if (
      location.pathname === "/sign-up" &&
      localStorage.getItem("r_id") === "1"
    ) {
      return <Navigate to="/partner/list" />;
    } else if (
      location.pathname === "/sign-up" &&
      localStorage.getItem("r_id") === "35"
    ) {
      return <Navigate to="/partner/levelone" />;
    } else if (
      location.pathname === "sign-up" &&
      localStorage.getItem("r_id") === "36"
    ) {
      return <Navigate to="/partner/mou-upload" />;
    } else {
      return <Navigate to="/partner/config" />;
    }
  }

  const extractMultiId = (data: any) => {
    const ids = data?.map((item: any) => item.value).toString();
    return ids;
  };
  return (
    <div className="w-full h-[100vh] flex justify-between m-auto items-center">
      <div className="w-2/4">
        <div className="w-3/4 m-auto h-4/5">
          <div className="text-center">
            <div className="flex justify-between mx-[22px]">
              <div>
                <h1 className="text-5xl text-theme-color">Register</h1>
                <p className="mt-4">Welcome ! Register here.</p>
              </div>
              <div className="flex items-center gap-12 pb-4 justify-center">
                <div className="relative">
                  <img
                    src={showProfileImage ? showProfileImage : logo}
                    className="drop-shadow-myp_filter border-[4px] h-[90px] w-[90px] object-covesr rounded-full border-[#4D86BF]"
                    alt=""
                  />
                  <div className="image-upload absolute bg-theme-color right-0 bottom-0 p-2 rounded-full">
                    <label htmlFor="file-input">
                      <img className="cursor-pointer" src={camera} alt="" />
                    </label>
                    <input
                      id="file-input"
                      className="hidden"
                      type="file"
                      accept="image/png, image/jpeg ,image/jpg "
                      onChange={selectImage}
                    />
                  </div>
                </div>
              </div>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="outer pl-4 pr-12"></div>
              <div className="flex flex-wrap [&_.elements]:w-[45%] [&_.elements]:m-[5px] [&_.elements]:mx-2">
                <div className="relative mt-1 flex m-auto elements">
                  <div className="pointer-events-none absolute inset-y-0 flex items-center w-9 bg-[#cbd5e1] rounded-l-md shadow-inner">
                    <span className="text-gray-500 m-auto">
                      {/* <img src={userIcon} width="15px" alt="" /> */}
                      <FontAwesomeIcon
                        icon={faUser}
                        style={{ color: "#0052a4" }}
                      />
                    </span>
                  </div>

                  <InputField
                    className="block px-3 py-2 w-full bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400 flex-auto pl-[45px] focus:outline-none"
                    type="text"
                    name="PartnerName"
                    placeholder="Partner Name*"
                    value={signupData.PartnerName}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                  />
                </div>{" "}
                <div className="relative mt-1 flex m-auto elements">
                  <div className="pointer-events-none absolute inset-y-0 flex items-center w-9 bg-[#cbd5e1] rounded-l-md shadow-inner">
                    <span className="text-gray-500 m-auto">
                      {/* <img src={emailIcon} width="18px" alt="" /> */}
                      <FontAwesomeIcon
                        icon={faEnvelope}
                        style={{ color: "#0052a4" }}
                      />
                    </span>
                  </div>
                  <InputField
                    className="block px-3 py-2 w-full bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400 flex-auto pl-[45px] focus:outline-none"
                    type="email"
                    name="Email"
                    // required={true}
                    placeholder="Email*"
                    value={signupData.Email}
                    onChange={handleChange}
                  />
                </div>{" "}
                <div className="relative mt-1 flex m-auto elements">
                  <div className="pointer-events-none absolute inset-y-0 flex items-center w-9 bg-[#cbd5e1] rounded-l-md shadow-inner">
                    <span className="text-gray-500 m-auto">
                      {/* <img src={emailIcon} width="18px" alt="" /> */}
                      <FontAwesomeIcon
                        icon={faPhone}
                        style={{ color: "#0052a4" }}
                      />
                    </span>
                  </div>
                  <InputField
                    className="block px-3 py-2 w-full bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400 flex-auto pl-[45px] focus:outline-none"
                    type="text"
                    name="MobileNo"
                    placeholder="Mobile Number*"
                    value={signupData.MobileNo}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                  />
                </div>{" "}
                <div className="relative mt-1 flex m-auto elements">
                  <div className="pointer-events-none absolute inset-y-0 flex items-center w-9 bg-[#cbd5e1] rounded-l-md shadow-inner">
                    <span className="text-gray-500 m-auto">
                      <img src={emailIcon} width="18px" alt="" />
                    </span>
                  </div>
                  <InputField
                    className="block px-3 py-2 w-full bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400 flex-auto pl-[45px] focus:outline-none"
                    type="text"
                    name="WhatsAppNo"
                    placeholder="WhatsApp Number"
                    value={signupData.WhatsAppNo}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                  />
                </div>{" "}
                <div className="relative mt-1 flex m-auto elements">
                  <div className="pointer-events-none absolute inset-y-0 flex items-center w-9 bg-[#cbd5e1] rounded-l-md shadow-inner">
                    <span className="text-gray-500 m-auto">
                      <FontAwesomeIcon
                        icon={faBuilding}
                        style={{ color: "#0052a4" }}
                      />
                    </span>
                  </div>
                  <input
                    className="block px-3 py-2 w-full bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400 flex-auto pl-[45px] focus:outline-none"
                    type="text"
                    name="CompanyName"
                    placeholder="Company Name*"
                    value={signupData.CompanyName}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                  />
                </div>{" "}
                <div className="relative mt-1 flex m-auto elements">
                  <div className="pointer-events-none absolute inset-y-0 flex items-center w-9 bg-[#cbd5e1] rounded-l-md shadow-inner">
                    <span className="text-gray-500 m-auto">
                      <FontAwesomeIcon
                        icon={faBuilding}
                        style={{ color: "#0052a4" }}
                      />
                    </span>
                  </div>
                  <input
                    className="block px-3 py-2 w-full bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400 flex-auto pl-[45px] focus:outline-none"
                    type="text"
                    name="BrandName"
                    placeholder="Brand name*"
                    value={signupData.BrandName}
                    onChange={(e) => {
                      handleChange(e);
                    }}
                  />
                </div>{" "}
                <div className="relative mt-1 flex m-auto elements">
                  <div className="pointer-events-none absolute inset-y-0 flex items-center w-9 bg-[#cbd5e1] rounded-l-md shadow-inner">
                    <span className="text-gray-500 m-auto">
                      {/* <img src={emailIcon} width="18px" alt="" /> */}
                      <FontAwesomeIcon
                        icon={faLocationDot}
                        style={{ color: "#0052a4" }}
                      />
                    </span>
                  </div>
                  <InputField
                    className="block px-3 py-2 w-full bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400 flex-auto pl-[45px] focus:outline-none"
                    name="Address"
                    // required={true}
                    type="textarea"
                    value={signupData.Address}
                    placeholder="Address"
                    onChange={handleChange}
                  />
                </div>
                <div className="relative mt-1 flex m-auto elements">
                  <div className="pointer-events-none absolute inset-y-0 flex items-center w-9 bg-[#cbd5e1] rounded-l-md shadow-inner">
                    <span className="text-gray-500 m-auto">
                      {/* <img src={emailIcon} width="18px" alt="" /> */}
                      <FontAwesomeIcon
                        icon={faLocationDot}
                        style={{ color: "#0052a4" }}
                      />
                    </span>
                  </div>
                  <SelectField
                    className="block px-3 py-2 w-full bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400 flex-auto pl-[45px] focus:outline-none"
                    name="state"
                    // required={true}
                    value={signupData.state}
                    placeholder="State"
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    options={stateOptions}
                  />
                </div>
                <div className="relative mt-1 flex m-auto elements">
                  <div className="pointer-events-none absolute inset-y-0 flex items-center w-9 bg-[#cbd5e1] rounded-l-md shadow-inner">
                    <span className="text-gray-500 m-auto">
                      {/* <img src={emailIcon} width="18px" alt="" /> */}
                      <FontAwesomeIcon
                        icon={faLocationDot}
                        style={{ color: "#0052a4" }}
                      />
                    </span>
                  </div>
                  <SelectField
                    className="block px-3 py-2 w-full bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400 flex-auto pl-[45px] focus:outline-none"
                    name="district"
                    value={signupData.district}
                    // required={true}
                    placeholder="District"
                    onChange={(e) => {
                      handleChange(e);
                    }}
                    options={districtOptions}
                  />
                </div>
                <div className="relative mt-1 flex m-auto elements">
                  <div className="pointer-events-none absolute inset-y-0 flex items-center w-9 bg-[#cbd5e1] rounded-l-md shadow-inner">
                    <span className="text-gray-500 m-auto">
                      {/* <img src={emailIcon} width="18px" alt="" /> */}
                      <FontAwesomeIcon
                        icon={faGlobe}
                        style={{ color: "#0052a4" }}
                      />
                    </span>
                  </div>
                  <InputField
                    className="block px-3 py-2 w-full bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400 flex-auto pl-[45px] focus:outline-none"
                    type="text"
                    name="WebsiteURL"
                    placeholder="Web Url*"
                    // required={true}
                    value={signupData.WebsiteURL}
                    onChange={handleChange}
                  />
                </div>{" "}
                <div className="relative mt-1 flex m-auto elements">
                  <div className="pointer-events-none absolute inset-y-0 flex items-center w-9 bg-[#cbd5e1] rounded-l-md shadow-inner">
                    <span className="text-gray-500 m-auto">
                      {/* <img src={emailIcon} width="18px" alt="" /> */}
                      <FontAwesomeIcon
                        icon={faGlobe}
                        style={{ color: "#0052a4" }}
                      />
                    </span>
                  </div>
                  <InputField
                    className="block px-3 py-2 w-full bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400 flex-auto pl-[45px] focus:outline-none"
                    type="text"
                    name="CallBackURL"
                    placeholder="Call Back Url*"
                    // required={true}
                    value={signupData.CallBackURL}
                    onChange={handleChange}
                  />
                </div>{" "}
                <div className="relative mt-1 flex m-auto elements">
                  <div className="pointer-events-none absolute inset-y-0 flex items-center w-9 bg-[#cbd5e1] rounded-l-md shadow-inner z-[0]">
                    <span className="text-gray-500 m-auto">
                      {/* <img src={emailIcon} width="18px" alt="" /> */}
                      <FontAwesomeIcon
                        icon={faIdBadge}
                        style={{ color: "#0052a4" }}
                      />
                    </span>
                  </div>
                  <Select
                    className="[&_.css-1fdsijx-ValueContainer]:pxs-[65px] w-full pl-[35px] focus:outline-none"
                    name="PartnerGrade"
                    isMulti
                    placeholder="Grade"
                    options={gradeOptions}
                    onChange={(selectedOption: any) => {
                      const val = extractMultiId(selectedOption);
                      setSignupData({
                        ...signupData,
                        PartnerGrade: val,
                      });
                    }}
                  />
                </div>{" "}
                <div className="relative mt-1 flex m-auto elements">
                  <div className="pointer-events-none absolute inset-y-0 flex items-center w-9 bg-[#cbd5e1] rounded-l-md shadow-inner z-[0]">
                    <span className="text-gray-500 m-auto">
                      {/* <img src={emailIcon} width="18px" alt="" /> */}
                      <FontAwesomeIcon
                        icon={faGraduationCap}
                        style={{ color: "#0052a4" }}
                      />
                    </span>
                  </div>
                  <Select
                    className="[&_.css-1fdsijx-ValueContainer]:pxs-[65px] w-full pl-[35px] focus:outline-none"
                    name="PartnerSubject"
                    isMulti
                    placeholder="Subject"
                    options={subjectOptions}
                    onChange={(selectedOption: any) => {
                      const val = extractMultiId(selectedOption);
                      setSignupData({
                        ...signupData,
                        PartnerSubject: val,
                      });
                    }}
                  />
                </div>{" "}
                <div className="relative mt-1 flex m-auto elements">
                  <div className="pointer-events-none absolute inset-y-0 flex items-center w-9 bg-[#cbd5e1] rounded-l-md shadow-inner z-[0]">
                    <span className="text-gray-500 m-auto">
                      {/* <img src={emailIcon} width="18px" alt="" /> */}
                      <FontAwesomeIcon
                        icon={faHandshakeSimple}
                        style={{ color: "#0052a4" }}
                      />
                    </span>
                  </div>
                  <Select
                    className="[&_.css-1fdsijx-ValueContainer]:pxs-[65px] w-full pl-[35px] focus:outline-none"
                    name="PartnerTypeID"
                    isMulti
                    placeholder="PartnerType*"
                    // required={true}
                    options={partnerTypeOptions}
                    onChange={(selectedOption: any) => {
                      const val = extractMultiId(selectedOption);
                      setSignupData({
                        ...signupData,
                        PartnerTypeID: val,
                      });
                    }}
                  />
                </div>{" "}
                <div className="relative mt-1 flex m-auto elements">
                  <div className="pointer-events-none absolute inset-y-0 flex items-center w-9 bg-[#cbd5e1] rounded-l-md shadow-inner z-[0]">
                    <span className="text-gray-500 m-auto">
                      {/* <img src={emailIcon} width="18px" alt="" /> */}
                      <FontAwesomeIcon
                        icon={faSliders}
                        style={{ color: "#0052a4" }}
                      />
                    </span>
                  </div>
                  <SelectField
                    className="block px-3 py-2 w-full bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400 flex-auto pl-[45px] focus:outline-none"
                    name="CategoryID"
                    placeholder="Category Id*"
                    // required={true}
                    value={signupData.CategoryID}
                    onChange={handleChange}
                    options={categoryTypeOptions}
                  />
                </div>{" "}
                <div className="relative mt-1 flex m-auto elements">
                  <div className="pointer-events-none absolute inset-y-0 flex items-center w-9 bg-[#cbd5e1] rounded-l-md shadow-inner">
                    <span className="text-gray-500 m-auto">
                      {/* <img src={emailIcon} width="18px" alt="" /> */}
                      <FontAwesomeIcon
                        icon={faLock}
                        style={{ color: "#0052a4" }}
                      />
                    </span>
                  </div>
                  <InputField
                    className="block px-3 py-2 w-full bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400 flex-auto pl-[45px] focus:outline-none"
                    type={passwordType}
                    name="Password"
                    placeholder="Password*"
                    value={signupData.Password}
                    onChange={handleChange}
                  />
                  {passwordType === "password" ? (
                    <AiFillEye
                      className="cursor-pointer relative right-6 top-2.5"
                      onClick={() => {
                        setPasswordType("text");
                      }}
                    />
                  ) : (
                    <AiFillEyeInvisible
                      className="cursor-pointer relative right-6 top-2.5"
                      onClick={() => {
                        setPasswordType("password");
                      }}
                    />
                  )}
                </div>{" "}
                <div className="mt-1 flex w-full justify-around mb-5">
                  <p>Partner Platform*: &nbsp; </p>
                  {platformOptions?.map(
                    (
                      item: {
                        ID: string | number | readonly string[] | undefined;
                        PlateformName:
                          | string
                          | number
                          | boolean
                          | React.ReactElement<
                              any,
                              string | React.JSXElementConstructor<any>
                            >
                          | Iterable<React.ReactNode>
                          | React.ReactPortal
                          | null
                          | undefined;
                      },
                      index: any
                    ) => (
                      <>
                        {" "}
                        <label className="checkbox-label">
                          <input
                            className="cursor-pointer"
                            value={item.ID}
                            type="checkbox"
                            onChange={(e) => {
                              handlePlatformSelect(e, item.ID);
                            }}
                            // onChange={()=>{setSignupData(...signupData,PartnerPlateform: item.ID)}
                          />
                          <span className="checkbox-custom "></span>
                          &nbsp; {item.PlateformName}
                        </label>
                        &nbsp;
                      </>
                    )
                  )}
                </div>{" "}
                <div className="relative mt-1 flex m-auto">
                  <div className="pointer-events-none absolute inset-y-0 flex items-center pl-2">
                    <span className="text-gray-500">
                      <img src={emailIcon} width="18px" alt="" />
                    </span>
                  </div>
                </div>{" "}
              </div>
              <Button
                buttonContent={loader ? "loading.." : "Sign Up"}
                disabled={loader}
                type="submit"
              />
            </form>
            <div className="mt-1">
              <span>Already have an account ?</span>&nbsp;
              <Link to="/">
                <span className="text-blue-600 hover:text-blue-700 font-medium  cursor-pointer ...">
                  Login
                </span>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="w-2/4 flex ">
        <h1 className="">
          <img className="h-[100vh]" src={partners} alt="" />
        </h1>
      </div>
      {showMessage && <ToastContainer autoClose={1000} limit={1} />}
    </div>
  );
};
export default Registration;
