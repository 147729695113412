import React, { Fragment, useRef, useState } from "react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { Dialog, Transition } from "@headlessui/react";

import { useDispatch, useSelector } from "react-redux";
import { selectPartner2List } from "../../redux/slice/Level2ParnterSlice";
import { removePartner } from "../../redux/slice/Level2ParnterSlice";
import partnerHttpRequest from "../../redux/Apicall/partnerHttpRequest";
interface RejectedModalProps {
  isOpen: boolean;
  closeModal: () => void;
  rejectPartner: any;
  setShowMessage: any;
}

const RejectedModal: React.FC<RejectedModalProps> = ({
  isOpen,
  closeModal,
  rejectPartner,
  setShowMessage,
}) => {
  const dispatch = useDispatch();
  const [rejectReason, setRejectReason] = useState("");
  const cancelButtonRef = useRef<HTMLButtonElement>(null);

  const selector = useSelector(selectPartner2List);

  const rejectPartnerApi = async () => {
    try {
      // await axios(`${}partner/Level2RejectPartner`, {
      // method: "POST",
      // data: {
      //   PartnerUserId: rejectPartner?.PartnerUserID,
      //   Reason: rejectReason,
      //   CreateBy: localStorage.getItem("p_id"),
      // },
      partnerHttpRequest
        .post("partner/Level2RejectPartner", {
          PartnerUserId: rejectPartner?.PartnerUserID,
          Reason: rejectReason,
          CreateBy: localStorage.getItem("p_id"),
        })
        .then((result) => {
          if (result.status === 200) {
            dispatch(removePartner(rejectPartner.ID));
            setShowMessage(true);
            toast.success(result.data.Message);
            closeModal();
          }
        });
    } catch (error) {
      toast.error("Some error occured! Please try again");
    }
  };

  return (
    <>
      {isOpen && (
        <Transition.Root show={isOpen} as={Fragment}>
          <Dialog
            as="div"
            className="fixed inset-0 z-10 overflow-y-auto"
            initialFocus={cancelButtonRef}
            onClose={closeModal}
          >
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
                    <div className="sm:flex sm:items-start">
                      <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                        <ExclamationTriangleIcon
                          className="h-6 w-6 text-red-600"
                          aria-hidden="true"
                        />
                      </div>
                      <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                        <Dialog.Title
                          as="h3"
                          className="text-base font-semibold leading-6 text-gray-900"
                        >
                          Reject Partner
                        </Dialog.Title>
                        <div className="mt-2">
                          <p className="text-sm text-gray-500">
                            Are you sure you want to reject your Partner? All of
                            your data will be permanently removed. This action
                            cannot be undone.
                          </p>
                          <textarea
                            className="mt-2 rounded border border-red-200 w-full active:border-red-200 p-2 placeholder:text-sm  "
                            placeholder="Type Rejection Reason"
                            onChange={(e) => {
                              setRejectReason(e.target.value);
                            }}
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                      disabled={rejectReason === "" ? true : false}
                      onClick={() => {
                        rejectPartnerApi();
                      }}
                    >
                      Deactivate
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                      onClick={closeModal}
                      ref={cancelButtonRef}
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
      )}
    </>
  );
};

export default RejectedModal;
