import React, { useState } from "react";

const IFrameOnClick = () => {
  const [url] = useState(localStorage.getItem("MOUPath"));
  if (!url) {
    return <h1>NO docs is present</h1>;
  }
  return (
    <div>
      <iframe
        src={
          localStorage.getItem("MOUPath")
            ? `https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(
                url
              )}&embedded=true`
            : ""
        }
        width="100%"
        height="600px"
        title="Embedded Document"
        // onLoad={handleIfrmeLoad}
      />
    </div>
  );
};

export default IFrameOnClick;
