import { ChangeEvent, FormEvent, useEffect, useState } from "react";
import Button from "../../component/button/Button";
import InputField from "../../component/InputField/InputField";
import MultiSelect from "../../component/SelectField/MultiSelect";
import useFetch from "../../customHook/useFetch";
import { useAppDispatch, useAppSelector } from "../../redux/hook/hooks";
import { selectRoleNameAndId } from "../../redux/slice/getUserRoleSlice";
import createPartner from "../../redux/services/createPartner";
import { useLocation, useNavigate } from "react-router-dom";
import EditPartner from "../../redux/services/EditPartner";
import { userNamRegex } from "../../utilities/Regex";
import { ToastContainer, toast } from "react-toastify";
import { AiFillEye, AiFillEyeInvisible } from "react-icons/ai";
export interface createUser {
  PartnerName: string;
  Password: string;
  RoleID: string;
  CreateBy: string;
}
export interface editUser extends createUser {
  UserID: number;
}
const CreatePartnerUser = () => {
  const { error } = useFetch();
  const location = useLocation();
  const [credential, setCredential] = useState<createUser>({
    PartnerName: location?.state ? location?.state?.PartnerUserName : "",
    Password: "",
    RoleID: location?.state ? location?.state?.RoleID : "",
    CreateBy: location?.state
      ? location?.state?.CreateBy
      : localStorage.getItem("p_id"),
  });
  const [passwordType, setPasswordType] = useState("password");
  const [defaultRole, setDefaultRole] = useState();
  const navigate = useNavigate();
  const [showMessage, setShowMessage] = useState<boolean>(false);

  const selector = useAppSelector(selectRoleNameAndId);
  const dispatch = useAppDispatch();

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setShowMessage(true);

    const isUserNameValid = userNamRegex.test(credential.PartnerName);
    if (credential.PartnerName.length < 3) {
      toast.warn("Partner name should be more than 3 characters");
      return;
    } else if (!isUserNameValid) {
      toast.warn("User name should contains alphabet , underscore and @");
      return;
    } else if (!location.state && credential.Password.length < 6) {
      toast.warn("Password length should be between 6 to 20 characters");
      return;
    } else if (!credential.RoleID) {
      toast.warn("Please choose a role");
      return;
    }
    try {
      if (!location.state) {
        await dispatch(createPartner(credential)).unwrap();
      } else {
        const UserID = location.state.ID;
        const cred = { ...credential, UserID };
        await dispatch(EditPartner(cred)).unwrap();
      }
      toast.success("User is created successfully");
      setCredential({
        ...credential,
        RoleID: "",
        CreateBy: "",
        PartnerName: "",
        Password: "",
      });
      setTimeout(() => {
        navigate("/partner/user");
      }, 1000);
    } catch (error: any) {
      toast.error(error);
    }
  };
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCredential({ ...credential, [e.target.name]: e.target.value });
  };
  useEffect(() => {
    if (selector.length > 0) {
      const getRole = selector.filter(
        (item: any) => item.value === location?.state?.RoleID
      );
      setDefaultRole(getRole[0]);
    }
  }, [selector]);

  return (
    <>
      <div className="w-full h-[60vh] flex justify-center mt-4 items-center">
        <div className="w-2/5  ">
          <div className="">
            <div className="text-left bg-white border border-gray-200 rounded-lg shadow p-6">
              <form onSubmit={handleSubmit}>
                <div>
                  <div className="relative mt-1 flex flex-col w-4/5 m-auto">
                    <label className="mb-2">Partner Name :</label>
                    <InputField
                      className="block px-3 py-2 w-full bg-white border border-slate-300 rounded-lg text-sm shadow-sm placeholder-slate-400 flex-auto mb-2"
                      type="text"
                      name="PartnerName"
                      placeholder="Enter Partner name"
                      // required={true}
                      readOnly={location?.state === null ? false : true}
                      value={credential.PartnerName}
                      // value={loginCred.userName}
                      onChange={handleChange}
                    />
                  </div>
                  <div className="relative mt-1 flex flex-col w-4/5 m-auto">
                    <label className="mb-2">Password :</label>
                    <InputField
                      className="block px-3 py-2 w-full bg-white border border-slate-300 rounded-lg text-sm shadow-sm placeholder-slate-400 flex-auto mb-2"
                      type={passwordType}
                      name="Password"
                      // minLength={6}
                      maxLength={20}
                      // required={location.state ? false : true}
                      placeholder="••••••••"
                      value={credential.Password}
                      onChange={handleChange}
                    />

                    {passwordType === "password" ? (
                      <AiFillEye
                        className="cursor-pointer absolute right-6 top-11"
                        onClick={() => {
                          setPasswordType("text");
                        }}
                      />
                    ) : (
                      <AiFillEyeInvisible
                        className="cursor-pointer absolute right-6 top-11"
                        onClick={() => {
                          setPasswordType("password");
                        }}
                      />
                    )}
                  </div>
                  <div className="relative mt-1 flex flex-col w-4/5 m-auto">
                    <label className="mb-2">Select Role :</label>
                    <MultiSelect
                      options={selector}
                      multi={false}
                      // required={true}
                      value={defaultRole}
                      noOptionsMessage={() => "Please create the role first"}
                      onChange={(selectedItem) => {
                        setDefaultRole(selectedItem);
                        setCredential({
                          ...credential,
                          RoleID: selectedItem.value,
                        });
                      }}
                      className="[&_.css-13cymwt-control]:rounded-lg"
                    />
                  </div>
                </div>
                <div className="flex justify-center">
                  <Button buttonContent={"Submit"} type="submit" />
                </div>
              </form>
              {showMessage && <ToastContainer autoClose={1000} />}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CreatePartnerUser;
