import { createAsyncThunk } from "@reduxjs/toolkit";
import partnerHttpRequest from "../Apicall/partnerHttpRequest";
import { GET_MENU_LIST_ROLE_ID } from "../endpoint/constant";

const GetMenuListByRoleID = createAsyncThunk<any, any>(
    "platform",
    async (data, { rejectWithValue }) => {
        try {
            const response = await partnerHttpRequest.post(GET_MENU_LIST_ROLE_ID, data);
            return response.data;
        } catch (error) { }
    }
);
export default GetMenuListByRoleID;
