import React from "react";
import { useLocation } from "react-router-dom";
import SchoolGrade from "./SchoolDetailPages/SchoolGrade";
import Schoolkyc from "./SchoolDetailPages/Schoolkyc";
import SchoolQuiz from "./SchoolDetailPages/SchoolQuiz";
import SchoolSubject from "./SchoolDetailPages/SchoolSubject";

const MySchoolDetail: React.FC = () => {
  const {
    state: { row },
  } = useLocation();
  return (
    <div className="px-8">
      <Schoolkyc row={row} />
      <SchoolQuiz row={row} />
      <SchoolGrade row={row} />
      <SchoolSubject row={row} />
      {/* <SchoolSubjecGradeAverage row={row} /> */}
    </div>
  );
};

export default MySchoolDetail;
