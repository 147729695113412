import { createAsyncThunk } from "@reduxjs/toolkit";
import partnerHttpRequest from "../Apicall/partnerHttpRequest";
import { SCHOOL_QUIZ_DETAIL } from "../endpoint/constant";

const getSchoolQuizDetails = createAsyncThunk<any, any>(
  "quiz/school/detail/level",
  async (data, { rejectWithValue }) => {
    try {
      const response = await partnerHttpRequest.post(SCHOOL_QUIZ_DETAIL, data);
      return response.data;
    } catch (error) { }
  }
);
export default getSchoolQuizDetails;
