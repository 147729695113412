import { createAsyncThunk } from "@reduxjs/toolkit";
import partnerHttpRequest from "../Apicall/partnerHttpRequest";

const getState = createAsyncThunk('states', async (_, { rejectWithValue }) => {

    try {
        const response = await partnerHttpRequest.get('report/GetState')
        return response.data

    } catch (error) {

    }

})
export default getState;
