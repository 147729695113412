import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../redux/hook/hooks";
import Table from "../Table/Table";
import GetKycLevel3Details from "../../redux/services/GetKycLevel3Details";
import { selectKycDetails3 } from "../../redux/slice/getKycLevel3DetailsSlice";
import Loader from "../../GlobalLoader/Loader";
const KycLevelThreeDetails = () => {
  const [loader, setLoader] = useState(false);
  const column = [
    {
      name: "User Id",
      selector: (row: any) => row.user_id,
      sortable: true,
    },
    {
      name: "Student Name",
      selector: (row: any) => row.student_name,
    },
    {
      name: "Student Mobile No",
      selector: (row: any) => row.mobile_no,
    },
    {
      name: "Student Email Id",
      selector: (row: any) => row.email_id,
    },
    {
      name: "Level 1 Kyc Status",
      selector: (row: any) => row.STATUS,
    },
    {
      name: "Document Type",
      selector: (row: any) => row.doctype,
    },
    {
      name: "Kyc Upload At",
      selector: (row: any) => row.uploaded_at,
    },
    {
      name: "Level 1 Remarks",
      selector: (row: any) => row.level1_remarks,
    },
    {
      name: "Level 1 Verification",
      selector: (row: any) => row.leveloneverification_date,
    },
  ];
  const location = useLocation();
  const dispatch = useAppDispatch();
  const selector = useAppSelector(selectKycDetails3);
  const getDetails = async () => {
    const { row, type } = location.state;
    try {
      const credential = {
        KYCStatus: `skd.STATUS ='${row.STATUS}'`,
        Filter: row.Filter,
      };
      setLoader(true);
      await dispatch(GetKycLevel3Details(credential)).unwrap();
      setLoader(false);
    } catch (error) {}
  };
  useEffect(() => {
    getDetails();
  }, []);
  return (
    <div className="mx-5">
      {loader ? <Loader /> : <Table data={selector} columns={column} />}
    </div>
  );
};

export default KycLevelThreeDetails;
