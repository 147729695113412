import { createAsyncThunk } from "@reduxjs/toolkit";
import partnerHttpRequest from "../Apicall/partnerHttpRequest";
import { QUIZ_TRACK_INITIAL } from "../endpoint/constant";

const QuizTrackInitialReportByPartnerSource = createAsyncThunk<any, any>('get/initial/quiz/report', async (data, { rejectWithValue }) => {
    try {
        const response = await partnerHttpRequest.post(QUIZ_TRACK_INITIAL, data)
        const { ErrorCode } = response.data;
        if (ErrorCode !== 200) {
            throw new Error("Internal server error")
        }
        return response.data
    } catch (error: any) {
        return rejectWithValue(error)
    }
})
export default QuizTrackInitialReportByPartnerSource;