import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "./stateSlice";
import forgotPassword from "../services/forgotPassword";
const state: initialState = {
    status: 'idle',
    error: false,
    data: []
}
const forgotSlice = createSlice({
    name: "forgot/password",
    initialState: state,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(forgotPassword.pending, (state) => {
            state.status = 'loading';
        })
            .addCase(forgotPassword.fulfilled, (state, action) => {
                state.status = 'success';
                state.data = action.payload

            }).addCase(forgotPassword.rejected, (state, action) => {
                state.status = 'error';
                state.error = true;
            }).addDefaultCase((state) => ({ ...state }))
    }
})

export default forgotSlice.reducer