import { createAsyncThunk } from "@reduxjs/toolkit";
import partnerHttpRequest from "../Apicall/partnerHttpRequest";
import { KYC_LEVEL_1_DETAILS } from "../endpoint/constant";

const GetKycLevel1Details = createAsyncThunk<any, any>('kyc/level/1', async (data, { rejectWithValue }) => {

    try {
        const response = await partnerHttpRequest.post(KYC_LEVEL_1_DETAILS, data)
        return response.data
    } catch (error) {

    }

})
export default GetKycLevel1Details;