import { createSlice } from "@reduxjs/toolkit";
import getKyc from "../services/getKyc";
import { RootState } from "../store/store";
import { initialState } from "./stateSlice";
import GetKycLevel2Details from "../services/GetKycLevel2Details";
const state: initialState = {
  status: "idle",
  data: [],
  error: false,
};

const GetKycLevel2DetailsSlice = createSlice({
  name: "get/detials/kyc/2",
  initialState: state,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(GetKycLevel2Details.pending, (state) => {
        state.status = "loading";
      })
      .addCase(GetKycLevel2Details.fulfilled, (state, action) => {
        state.status = "success";
        state.data = action.payload.Result;
      })
      .addCase(GetKycLevel2Details.rejected, (state) => {
        state.status = "error";
        state.error = true;
      })
      .addDefaultCase((state) => ({ ...state }));
  },
});

export const selectKycDetails2 = (state: RootState) => {
  return state.getKycLevel2DetailsSlice.data;
};

export default GetKycLevel2DetailsSlice.reducer;
