import { createAsyncThunk } from "@reduxjs/toolkit";
import { editUser } from "../../pages/users/CreatePartnerUser";
import partnerHttpRequest from "../Apicall/partnerHttpRequest";
import { UPDATE_PARTNER_USER } from "../endpoint/constant";

const EditPartner = createAsyncThunk<any, editUser>(
    "edit/partner/user",
    async (data, { rejectWithValue }) => {
        try {
            const response = await partnerHttpRequest.post(UPDATE_PARTNER_USER, data);

            const { p_ErrorCode, p_Status } = response.data;
            if (p_ErrorCode !== 200) {
                throw new Error(p_Status);
            }
            return response.data;
        } catch (error: any) {

            return rejectWithValue(error);
        }
    }
);
export default EditPartner;
