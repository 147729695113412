import { Navigate, RouterProvider } from "react-router-dom";

import router from "./routes/router";
import "./App.css";
import { useAppDispatch, useAppSelector } from "./redux/hook/hooks";
import { selectMenuList } from "./redux/slice/GetMenuListByRoleIDSlice";
import { useEffect } from "react";
import GetMenuListByRoleID from "./redux/services/GetMenuListByRoleID";

function App() {
  return (
    <div>
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
