import { useEffect, useState } from "react";
import { useLocation } from "react-router";
import QuizTrackDetails from "../../../redux/services/QuizTrackDetails";
import { useAppDispatch, useAppSelector } from "../../../redux/hook/hooks";
import Table from "../../Table/Table";
import { SelectQuizTrackingDetails } from "../../../redux/slice/QuizTrackDetailsSlice";
import { Link } from "react-router-dom";

const PartnerQuizzesDetails = () => {
  const [column, setColumn] = useState([{}]);
  const dispatch = useAppDispatch();
  const selector = useAppSelector(SelectQuizTrackingDetails);

  const location = useLocation();

  const getDetails = async () => {
    const { Filter, ParamFilter } = location.state;

    try {
      const result = await dispatch(
        QuizTrackDetails({
          Filter,
          ParamFilter,
        })
      ).unwrap();

      const { QuizTrackDetails: quizTrackDetails } = result.Result;

      makeColumn(quizTrackDetails);
    } catch (error) {}
  };
  const makeColumn = (data: any) => {
    const key = Object?.keys(data[0])
      .reverse()
      .filter((item: any) => item !== "QuestionDetails");

    const col = key?.map((item: any) => ({
      name: item,
      id: item,
      selector: (row: any) => row[item],
      sortable: true,
    }));
    setColumn(col);
  };

  useEffect(() => {
    getDetails();
  }, []);
  return (
    <>
      <ul className="flex flex-wrap text-sm font-medium text-center text-gray-500 dark:text-gray-400">
        <li className="mr-2">
          <Link
            to={"/partner/deleted-details"}
            state={selector?.Result?.QuizTrackDeleteDetails}
            className="inline-block px-4 py-3 text-white bg-blue-600 rounded-lg active"
            aria-current="page"
          >
            Tap to see Deleted Data
          </Link>
        </li>
      </ul>
      <div style={{ marginBottom: "150px" }}>
        <h1>Existing QuizTrackDetails</h1>
        <Table columns={column} data={selector?.Result?.QuizTrackDetails} />
      </div>
    </>
  );
};

export default PartnerQuizzesDetails;
