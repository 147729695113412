import { configureStore } from "@reduxjs/toolkit";
import rootReducer, { reducerProxy } from "../rootReducer/rootReducer";

export const store = configureStore({
    reducer: reducerProxy
})


// Defining the Root State and Dispatch Types

// Return Type => Constructs a type consisting of the return type of function Type.
// Example => type T0 = ReturnType<() => string>; 
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;