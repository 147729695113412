import { createAsyncThunk } from "@reduxjs/toolkit";
import partnerHttpRequest from "../Apicall/partnerHttpRequest";
import { GET_CATEGORY_LIST } from "../endpoint/constant";

const getCategory = createAsyncThunk(
  "category",
  async (_, { rejectWithValue }) => {
    try {
      const response = await partnerHttpRequest.get(GET_CATEGORY_LIST);
      return response.data;
    } catch (error) { }
  }
);
export default getCategory;
