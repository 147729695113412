import { createSlice } from "@reduxjs/toolkit";
import getQuizLevelThreeData from "../services/getQuizLevelThree";
import { RootState } from "../store/store";

interface initialState {
  status: "idle" | "loading" | "success" | "error";
  data: any;
  error: boolean;
}

const state: initialState = {
  status: "idle",
  data: [],
  error: false,
};

const getQuizLevelThreeSlice = createSlice({
  name: "get/quizLevelThree",
  initialState: state,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getQuizLevelThreeData.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getQuizLevelThreeData.fulfilled, (state, action) => {
        state.status = "success";
        state.data = action.payload;
      })
      .addCase(getQuizLevelThreeData.rejected, (state, action) => {
        state.status = "error";
        state.error = true;
      })
      .addDefaultCase((state) => ({ ...state }));
  },
});

export const SelectQuizLevel3 = (state: RootState) => {
  const { Result } = state?.QuizLevel3Slice?.data;
  return Result;
};

export default getQuizLevelThreeSlice.reducer;
