import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "./stateSlice";
import partnerList from "../services/partnerList";
import { RootState } from "../store/store";
const state: initialState = {
    status: "idle",
    error: false,
    data: [],
};
const partnerListSlice = createSlice({
    name: "get/partner/list",
    initialState: state,
    reducers: {
        verifyPartner: (state, action) => {
            const { ID } = action.payload;
            state.data = state.data?.map((item: any) => {
                return item.PartnerUserId === ID ? { ...item, Status: 1 } : { ...item }
            })

        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(partnerList.pending, (state) => {
                state.status = "loading";
            })
            .addCase(partnerList.fulfilled, (state, action) => {
                state.status = "success";
                state.data = action.payload.Result;
            })
            .addCase(partnerList.rejected, (state, action) => {
                state.status = "error";
                state.error = true;
            })
            .addDefaultCase((state) => ({ ...state }));
    },
});

export const selectPartnerList = (state: RootState) => {
    const { data } = state.partnerListSlice
    const cutomisedData = data.map(((item: any) => {
        return item.Status === 1
            ? { ...item, Status: "Active" }
            : { ...item, Status: "Inactive" }
    }));
    return cutomisedData;
}
export const { verifyPartner } = partnerListSlice.actions
export default partnerListSlice.reducer;
