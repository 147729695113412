import React, { useEffect, useState } from "react";
// import Button from "../../component/button/Button";
// import SelectField from "../../component/SelectField/SelectField";
// import Header from "../../layout/Header/Header";
import { useAppDispatch, useAppSelector } from "../../redux/hook/hooks";
// import getDistrict from "../../redux/services/getDistrict";
import getGrade from "../../redux/services/getGrade";
import getState from "../../redux/services/getState";
// import { selectDistrict } from "../../redux/slice/districtSlice";
import { selectGrade } from "../../redux/slice/gradeSlice";
import { selectState } from "../../redux/slice/stateSlice";
import Table from "../Table/Table";
import Select from "react-select";
import { selectKyc } from "../../redux/slice/getKycSlice";
import getKyc from "../../redux/services/getKyc";
import getMultiDistrict from "../../redux/services/getMultiDistrict";
import { selectMultiDistrict } from "../../redux/slice/multiDistrictSlice";
import getStudentDetail from "../../redux/services/getStudentDetail";
import { SelectStudentDetail } from "../../redux/slice/studentDetailSLice";
import Loader from "../../GlobalLoader/Loader";

const StudentDetails: React.FC = () => {
  const [loader, setLoader] = useState(false);
  const [search, setSearch] = useState({
    studentMobileNo: "",
    registrationDateFrom: "",
    registrationDateTo: "",
    studentGrade: "",
    studentState: "",
    studentDistrict: "",
    kycStatus: "",
  });
  const [partnerSource, setPartnerSource] = useState({
    Filter: `sm.partner_source in (${localStorage.getItem("partnerSource")})`,
  });
  const dispatch = useAppDispatch();
  const stateOptions = useAppSelector(selectState);
  const districtOptions = useAppSelector(selectMultiDistrict);
  const gradeOptions = useAppSelector(selectGrade);
  const kycStatusOptions = useAppSelector(selectKyc);
  const studentOptions = useAppSelector(SelectStudentDetail);
  const column = [
    {
      name: "Registration ID",
      selector: (row: any) => row.registration_id,
      sortable: true,
    },
    {
      name: "Registration Date",
      selector: (row: any) => row.registration_date,
      sortable: true,
    },
    {
      name: "Student Name",
      selector: (row: any) => row.student_name,
      sortable: true,
    },
    {
      name: "Mobile Number",
      selector: (row: any) => row.mobile_no,
      sortable: true,
    },
    {
      name: "Is Kyc Upload",
      selector: (row: any) => row.is_kyc_uploaded,
      sortable: true,
    },
    {
      name: "Kyc Status",
      selector: (row: any) => row.kyc_status,
      sortable: true,
    },
    {
      name: "State",
      selector: (row: any) => row.state_name,
      sortable: true,
    },
    {
      name: "District",
      selector: (row: any) => row.district_name,
      sortable: true,
    },
    {
      name: "Grade",
      selector: (row: any) => row.grade,
      sortable: true,
    },
    {
      name: "School",
      selector: (row: any) => row.school_name,
      sortable: true,
    },
  ];

  const handleChange = (
    e:
      | React.ChangeEvent<HTMLInputElement>
      | React.ChangeEvent<HTMLSelectElement>
  ) => {
    setSearch({ ...search, [e.target.name]: `${e.target.value}` });
  };
  const handleState = async () => {
    try {
      await dispatch(getState()).unwrap();
    } catch (error) {}
  };
  const handleMultiDistrict = async () => {
    try {
      const { studentState } = search;
      const data = { ID: studentState };
      await dispatch(getMultiDistrict(data));
    } catch (error) {}
  };
  const handleGrade = async () => {
    try {
      await dispatch(getGrade()).unwrap();
    } catch (error) {}
  };
  const handleKycStatus = async () => {
    try {
      await dispatch(getKyc()).unwrap();
    } catch (error) {}
  };
  const handleStudendentDetailList = async (data: any) => {
    try {
      setLoader(true);
      await dispatch(getStudentDetail(data)).unwrap();
      setLoader(false);
    } catch (error) {}
  };
  const handleSearchButton = () => {
    let condition = `sm.partner_source in (${localStorage.getItem(
      "partnerSource"
    )})`;

    if (search.studentMobileNo) {
      condition += `and sm.mobile_no = '${search.studentMobileNo}'`;
    }
    if (search.studentState) {
      condition += `and sd.state_id In (${search.studentState})`;
    }
    if (search.studentGrade) {
      condition += `and sm.grade In(${search.studentGrade})`;
    }
    if (search.kycStatus) {
      condition += `and sm.kyc_status In (${search.kycStatus})`;
    }
    if (search.studentDistrict) {
      condition += `and sd.district_id In (${search.studentDistrict})`;
    }
    if (search.registrationDateTo) {
      condition += `and sm.registration_date <= '${
        search.registrationDateTo + " 23:59:59"
      } '`;
    }
    if (search.registrationDateFrom) {
      condition += `and sm.registration_date >=' ${
        search.registrationDateFrom + " 00:00:00"
      }' `;
    }
    const filterObject = { Filter: condition };
    handleStudendentDetailList(filterObject);
  };
  useEffect(() => {
    handleState();
    handleGrade();
    handleKycStatus();
    handleStudendentDetailList(partnerSource);
  }, []);
  useEffect(() => {
    if (search.studentState) {
      handleMultiDistrict();
    }
  }, [search.studentState]);

  const extractMultiId = (data: any) => {
    const ids = data?.map((item: any) => `'${item.value}'`).toString();
    return ids;
  };

  return (
    <>
      <div className="px-8">
        <div className="pt-[15px]">
          <h1 className="text-2xl">Partner Student Details Dashboard</h1>
        </div>
        <div className="grid grid-cols-4 gap-4 mt-[12px]">
          <div className="col-span-1 h-[75vh] overflow-y-scroll border-r-[1px] border-[#bbbaba] pr-[20px]">
            <div
              className="flex justify-between
          "
            >
              {/* <Button buttonContent={"Search"} /> */}
              <h1 className="flex items-center">Filter</h1>
              <button
                className="px-[10px] bg-blue-500 hover:bg-blue-700 text-white rounded-[10px]"
                onClick={() => {
                  handleSearchButton();
                }}
              >
                Search{" "}
              </button>
              {/* <Button buttonContent={"Reset"} /> */}
            </div>
            <div className="py-2.5 pr-[5px] items-end [&_div]:py-[5px] ">
              <div>
                <input
                  className="px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400 w-full"
                  type="text"
                  name="studentMobileNo"
                  placeholder="Student Mobile No."
                  value={search.studentMobileNo}
                  onChange={handleChange}
                  maxLength={10}
                />
              </div>
              <div>
                <label>Registration from:</label>
                <input
                  className="px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400 w-full"
                  type="date"
                  name="registrationDateFrom"
                  placeholder="Registration Date From"
                  onChange={handleChange}
                />
              </div>
              <div>
                <label>Registration to:</label>
                <input
                  className="px-3 py-2 bg-white border border-slate-300 rounded-md text-sm shadow-sm placeholder-slate-400 w-full"
                  type="date"
                  name="registrationDateTo"
                  placeholder="Registration Date To"
                  onChange={handleChange}
                />
              </div>
              <label>State:</label>
              <Select
                name="studentState"
                isMulti
                options={stateOptions}
                onChange={(selectedOption: any) => {
                  const val = extractMultiId(selectedOption);
                  setSearch({
                    ...search,
                    studentState: val,
                  });
                }}
              />
              <label>District:</label>
              <Select
                name="studentDistrict"
                isMulti
                options={districtOptions}
                onChange={(selectedOption: any) => {
                  const val = extractMultiId(selectedOption);
                  setSearch({
                    ...search,
                    studentDistrict: val,
                  });
                }}
              />
              <label>Grade:</label>
              <Select
                name="studentGrade"
                isMulti
                options={gradeOptions}
                onChange={(selectedOption: any) => {
                  const val = extractMultiId(selectedOption);
                  setSearch({
                    ...search,
                    studentGrade: val,
                  });
                }}
              />
              <label>KYC Status:</label>
              <Select
                name="studentKyc"
                isMulti
                options={kycStatusOptions}
                onChange={(selectedOption: any) => {
                  const val = extractMultiId(selectedOption);
                  setSearch({
                    ...search,
                    kycStatus: val,
                  });
                }}
              />
            </div>
          </div>

          <div className="col-span-3">
            {loader ? (
              <Loader />
            ) : (
              <Table data={studentOptions} columns={column} />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default StudentDetails;
