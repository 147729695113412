import React, { useState } from "react";
import partners from "../../assets/images/partners.svg";
import Button from "../../component/button/Button";
import OtpInput from "./OtpInput";
import { useAppDispatch } from "../../redux/hook/hooks";
import verifyOTP from "../../redux/services/verifyOTP";
import { useNavigate, useSearchParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ResetPassword from "./ResetPassword";
const VerifyOtp: React.FC = () => {
  const [otp, setOtp] = useState("");
  const [show, setShow] = useState(false);
  const [showMessage, setShowMessage] = useState<boolean>(false);
  const onChange = (value: string) => setOtp(value);
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const navigation = useNavigate();
  console.log(searchParams.get("prtuserid"));
  const verifyOtp = async () => {
    if (otp.length < 6) {
      setShowMessage(true);
      toast.warn("Please enter OTP");
      return;
    }
    try {
      const PartnerUserID = searchParams.get("prtuserid");
      const verificationDetails = { OTP: otp, PartnerUserID };
      await dispatch(verifyOTP(verificationDetails)).unwrap();
      setShowMessage(true);
      setShow(true);
      toast.success("OTP is verfied successfully");
    } catch (error) {
      setShowMessage(true);
      toast.error(error as string);
    }
  };
  return (
    <div className="flex flex-col">
      <div className="w-full h-[100vh] flex justify-between m-auto items-center">
        {!show && (
          <div className="w-2/4">
            <div className="w-3/4 h-4/5 m-auto">
              <div className="">
                <h1 className="text-3xl">Verify Otp</h1>
              </div>
              <div className="">
                <OtpInput
                  required={true}
                  value={otp}
                  valueLength={6}
                  onChange={onChange}
                />
              </div>
              <div className="flex w-[85%] justify-between mt-[10px]">
                <Button
                  buttonContent="Verify"
                  type="button"
                  onClick={verifyOtp}
                />
              </div>
            </div>
          </div>
        )}

        {show && <ResetPassword searchParam={searchParams} />}
        <div className="w-2/4 flex ">
          <h1>
            <img className="h-[100vh]" src={partners} alt="" />
          </h1>
        </div>
        {showMessage && <ToastContainer autoClose={2000} />}
      </div>
    </div>
  );
};

export default VerifyOtp;
