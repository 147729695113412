import { createSlice } from "@reduxjs/toolkit";
import resetPassword from "../services/resetPassword";

export interface initialState {
    status: 'idle' | 'loading' | 'success' | 'error';
    data: any;
    error: boolean
}
const state: initialState = {
    status: 'idle',
    data: [],
    error: false
}
const resetPasswordSlice = createSlice({
    name: 'reset/password',
    initialState: state,
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(resetPassword.pending, (state) => {
            state.status = 'loading'

        }).addCase(resetPassword.fulfilled, (state, action) => {
            state.status = 'success';
            state.data = action.payload.states;

        }).addCase(resetPassword.rejected, (state, action) => {
            state.status = 'error';
            state.error = true
        }).addDefaultCase(state => ({ ...state }))
    }
})

// export const selectState = (state: RootState) => {
//     const { data } = state.stateSlice
//     return data;
// }
export default resetPasswordSlice.reducer
