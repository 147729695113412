import { useEffect, useState } from "react";
import { useAppDispatch } from "../redux/hook/hooks";
import getUserRole from "../redux/services/getUserRole";

const useFetch = () => {
  // const [data,setData] = useState([])
  const [loading, isLoading] = useState(false);
  const [error, setError] = useState(false);
  const dispatch = useAppDispatch();
  const getData = async () => {
    try {
      const ID = localStorage.getItem("p_id") || "";
      const response = await dispatch(getUserRole({ ID })).unwrap();
    } catch (error) {}
  };
  useEffect(() => {
    getData();
  }, []);
  return { loading, error };
};

export default useFetch;
