import { createAsyncThunk } from "@reduxjs/toolkit";
import partnerHttpRequest from "../Apicall/partnerHttpRequest";
import { KYC_LEVEL_2_DETAILS } from "../endpoint/constant";

const GetKycLevel2Details = createAsyncThunk<any, any>(
  "kyc/level/2",
  async (data, { rejectWithValue }) => {
    try {
      const response = await partnerHttpRequest.post(KYC_LEVEL_2_DETAILS, data);
      return response.data;
    } catch (error) { }
  }
);
export default GetKycLevel2Details;
