import React from "react";
import { Bar } from "react-chartjs-2";
import { Chart, registerables } from "chart.js";
Chart.register(...registerables);
interface ChartData {
  labels: string[];
  datasets: {
    label: string;
    data: number[];
    backgroundColor: string;
    borderColor: string;
    borderWidth: number;
  }[];
}

interface ChartOptions {
  scales: {
    y: {
      beginAtZero: boolean;
    };
  };
}

interface ChartProps {
  chartValue: any;
  chartLabel: any;
}

const ChartComponent: React.FC<ChartProps> = ({ chartValue, chartLabel }) => {
  const data: ChartData = {
    labels: chartLabel,
    datasets: [
      {
        label: "Dataset 1",
        data: chartValue,
        backgroundColor: "rgba(75,192,192,0.2)",
        borderColor: "rgba(75,192,192,1)",
        borderWidth: 1,
      },
    ],
  };

  const options: ChartOptions = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };
  return (
    <div className="w-[750px] m-auto">
      <Bar data={data} options={options} />
    </div>
  );
};

export default ChartComponent;
