import partnerHttpRequest from "../Apicall/partnerHttpRequest";
import { ROLE_DELETE } from "../endpoint/constant";

const deleteRole = (credential: { ID: string, CreateBy: string }) => {
    return async () => {
        try {
            const response = await partnerHttpRequest.post(ROLE_DELETE, credential)
            const { ErrorCode, Message } = response.data;
            if (ErrorCode !== 200) {
                throw new Error(Message);
            }
            return response.data;
        } catch (error) {
            return error;
        }
    };
};
export default deleteRole;