import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store/store";
import { initialState } from "./stateSlice";
import QuizTaking from "../services/QuizTaking";
const state: initialState = {
    status: "idle",
    data: [],
    error: false,
};

const QuizTakingSlice = createSlice({
    name: "get/quiz/taking",
    initialState: state,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(QuizTaking.pending, (state) => {
                state.status = "loading";
            })
            .addCase(QuizTaking.fulfilled, (state, action) => {
                state.status = "success";
                state.data = action.payload;
            })
            .addCase(QuizTaking.rejected, (state, action) => {
                state.status = "error";
                state.error = true;
            })
            .addDefaultCase((state) => ({ ...state }));
    },
});

export const SelectQuizTaking = (state: RootState) => {
    const { Result } = state.QuizTakingSlice.data;

    const customisedData1 = Result?.map((item: any) => {
        return {
            id: 1,
            label: "quiz taker 1-2",
            student_count: item.quizattempt_one_two_student,
            quiz_count: item.quizattempt_one_two,
            Eligible_quiz_student_count: item.quizattempt_one_two_winner_student,
            Eligible_quiz: item.quizattempt_one_two_winner
        }
    })
    const customisedData2 = Result?.map((item: any) => {
        return {
            id: 2,
            label: "quiz taker 3-5",
            student_count: item?.quizattempt_three_five_student,
            quiz_count: item?.quizattempt_three_five,
            Eligible_quiz_student_count: item?.quizattempt_three_five_winner_student,
            Eligible_quiz: item?.quizattempt_three_five_winner
        }
    })
    const customisedData3 = Result?.map((item: any) => {
        return {
            id: 3,
            label: "quiz taker 6-10",
            student_count: item?.quizattempt_six_ten_student,
            quiz_count: item?.quizattempt_six_ten,
            Eligible_quiz_student_count: item?.quizattempt_six_ten_winner_student,
            Eligible_quiz: item?.quizattempt_six_ten_winner
        }
    })
    const customisedData4 = Result?.map((item: any) => {
        return {
            id: 4,
            label: "quiz taker 11-20",
            student_count: item?.quizattempt_eleven_twenty_student,
            quiz_count: item?.quizattempt_eleven_twenty,
            Eligible_quiz_student_count: item?.quizattempt_eleven_twenty_winner_student,
            Eligible_quiz: item?.quizattempt_eleven_twenty_winner
        }
    })
    const customisedData5 = Result?.map((item: any) => {
        return {
            id: 5,
            label: "quiz taker 20+",
            student_count: item?.quizattempt_more_twenty_student,
            quiz_count: item?.quizattempt_more_twenty,
            Eligible_quiz_student_count: item?.quizattempt_more_twenty_winner_student,
            Eligible_quiz: item?.quizattempt_more_twenty_winner
        }
    })

    const finalData = [customisedData1?.[0], customisedData2?.[0], customisedData3?.[0], customisedData4?.[0], customisedData5?.[0]]

    return finalData;
};

export default QuizTakingSlice.reducer;
