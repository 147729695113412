import { createAsyncThunk } from "@reduxjs/toolkit";
import partnerHttpRequest from "../Apicall/partnerHttpRequest";
import { QUIZ_BY_STUDENT_CLASS } from "../endpoint/constant";

const quizByStudentClass = createAsyncThunk<any, any>('quiz/by/class', async (data, { rejectWithValue }) => {
    try {
        const response = await partnerHttpRequest.post(QUIZ_BY_STUDENT_CLASS, data)
        const { ErrorCode } = response.data;
        if (ErrorCode !== 200) {
            throw new Error("Internal server error")
        }
        return response.data
    } catch (error: any) {
        return rejectWithValue(error)
    }
})
export default quizByStudentClass;