import PartnerConfig from "../pages/partnerconfig/PartnerConfig";
import KycLevelOne from "../pages/PartnerKycReport/KycLevelOne";
import KycLevelOneDetails from "../pages/PartnerKycReport/KycLevelOneDetails";
import KycLevelThree from "../pages/PartnerKycReport/KycLevelThree";
import KycLevelThreeDetails from "../pages/PartnerKycReport/KycLevelThreeDetails";
import KycLevelTwo from "../pages/PartnerKycReport/KycLevelTwo";
import KycLevelTwoDetails from "../pages/PartnerKycReport/KycLevelTwoDetails";
import QuizLevelDetails from "../pages/PartnerQuizReport/QuizLevelDetails";
import QuizLevelDetails2 from "../pages/PartnerQuizReport/QuizLevelDetails2";
import QuizLevelDetails3 from "../pages/PartnerQuizReport/QuizLevelDetails3";
import QuizLevelOne from "../pages/PartnerQuizReport/QuizLevelOne";
import QuizLevelThree from "../pages/PartnerQuizReport/QuizLevelThree";
import QuizLevelTwo from "../pages/PartnerQuizReport/QuizLevelTwo";
import MySchool from "../pages/PartnerQuizTrack/MySchool/MySchool";
import MySchoolDetail from "../pages/PartnerQuizTrack/MySchool/MySchoolDetail";
import PartnerQuizzes from "../pages/PartnerQuizTrack/partnerQuizzes/PartnerQuizzes";
import StudentDetails from "../pages/PartnerQuizTrack/StudentDetails";
import CreateRole from "../pages/role/CreateRole";
import GetRole from "../pages/role/GetRole";
import GetRoleMenu from "../pages/RoleMenuMapping/GetRoleMenu";
import CreatePartnerUser from "../pages/users/CreatePartnerUser";
import GetUser from "../pages/users/GetUser";
import PartnerQuizzesDetails from "../pages/PartnerQuizTrack/partnerQuizzes/PartnerQuizzesDetails";
import PartnerQuizDeletedDetails from "../pages/PartnerQuizTrack/partnerQuizzes/PartnerQuizDeletedDetails";
// import AllPartner from "../pages/allPartner/Level1Partner";
import MouNotUploadedList from "../pages/allPartner/MouNotUploadedList";
import PDFUploader from "../pages/Document/PDFUploader";
import Level2Partner from "../pages/allPartner/Level2Partner";
import Level1Partner from "../pages/allPartner/Level1Partner";
import AllPartnerList from "../pages/allPartner/AllPartnerList";
import Partners from "../pages/allPartner/Partners";
import IFrameOnClick from "../pages/Document/IFrameOnClick";
export const privateRoutes = [
  {
    path: "role",
    element: <GetRole />,
  },
  {
    path: "role/create",
    element: <CreateRole />,
  },
  {
    path: "role/menu",
    element: <GetRoleMenu />,
  },
  {
    path: "user",
    element: <GetUser />,
  },
  {
    path: "create/partner",
    element: <CreatePartnerUser />,
  },
  {
    path: "quiz-details1",
    element: <QuizLevelDetails />,
  },
  {
    path: "kyc-details1",
    element: <KycLevelOneDetails />,
  },
  {
    path: "kyc-details2",
    element: <KycLevelTwoDetails />,
  },
  {
    path: "kyc-details3",
    element: <KycLevelThreeDetails />,
  },
  {
    path: "quiz-details2",
    element: <QuizLevelDetails2 />,
  },
  {
    path: "quiz-details3",
    element: <QuizLevelDetails3 />,
  },
  {
    path: "quiz-level-1",
    element: <QuizLevelOne />,
  },
  {
    path: "quiz-level-2",
    element: <QuizLevelTwo />,
  },
  {
    path: "quiz-level-3",
    element: <QuizLevelThree />,
  },
  {
    path: "kyc-level-1",
    element: <KycLevelOne />,
  },
  {
    path: "kyc-level-2",
    element: <KycLevelTwo />,
  },
  {
    path: "kyc-level-3",
    element: <KycLevelThree />,
  },
  {
    path: "student-detail",
    element: <StudentDetails />,
  },
  {
    path: "my-school",
    element: <MySchool />,
  },
  {
    path: "partner-quizzes",
    element: <PartnerQuizzes />,
  },
  {
    path: "my-school-detail",
    element: <MySchoolDetail />,
  },
  {
    path: "config",
    element: <PartnerConfig />,
  },
  {
    path: "details",
    element: <PartnerQuizzesDetails />,
  },
  {
    path: "deleted-details",
    element: <PartnerQuizDeletedDetails />,
  },
  {
    path: "levelone",
    element: <Level1Partner />,
  },
  {
    path: "mou-upload",
    element: <PDFUploader />,
  },
  {
    path: "level-2-verification",
    element: <Level2Partner />,
  },
  // {
  //   path: "mou-not-verified",
  //   element: <MouNotUploadedList />,
  // },
  {
    path: "list",
    element: <Partners />,
  },
  // {
  //   path: "view/pdf",
  //   element: <IFrameOnClick />,
  // },
];
