import { useEffect, useState } from "react";
import { useAppDispatch } from "../../redux/hook/hooks";
import { useAppSelector } from "../../redux/hook/hooks";
import { selectPartnerNotUploaded } from "../../redux/slice/PartnerNotUploadedMOUSlice";
import DataTable from "react-data-table-component";
import { ToastContainer } from "react-toastify";
import PartnerNotUploadedMOU from "../../redux/services/PartnerNotUploadedMOU";
const MouNotUploadedList = () => {
  const [showMessage, setShowMessage] = useState(false);
  const [open, setOpen] = useState(false);
  const [rejectPartner, setRejectPartner] = useState();

  const closeModal = () => {
    setOpen(false);
  };

  const [column] = useState([
    {
      name: "Name",
      selector: (row: any) => row.Name,
    },
    {
      name: "CreateDate",
      selector: (row: any) => row.CreateDate?.split("T")[0],
    },
    {
      name: "Mobileno",
      selector: (row: any) => row.Mobileno,
    },
  ]);
  const dispatch = useAppDispatch();
  const selector = useAppSelector(selectPartnerNotUploaded);
  const conditionalRowStyles = [
    {
      when: (row: any) => row.Status,
      style: (row: any) => {
        return { cursor: row.Status === "Active" ? "" : "pointer" };
      },
    },
  ];

  const getPartner = async () => {
    try {
      const response = await dispatch(PartnerNotUploadedMOU()).unwrap();
    } catch (error) {}
  };

  useEffect(() => {
    getPartner();
  }, []);

  return (
    <div>
      {" "}
      <DataTable
        columns={column}
        data={selector}
        pagination={true}
        conditionalRowStyles={conditionalRowStyles}
      />
      {showMessage && <ToastContainer />}
    </div>
  );
};

export default MouNotUploadedList;
