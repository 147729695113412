import { useState, useEffect } from "react";
import { useAppDispatch } from "../redux/hook/hooks";
import getRoleMenuMapping from "../redux/services/getRoleMenuMapping";
const useRoleMenu = ({ roleId }: any) => {
  const [error] = useState(false);
  const dispatch = useAppDispatch();
  const getData = async () => {
    try {
      const ID = roleId ? roleId : localStorage.getItem("r_id") || "";
      await dispatch(getRoleMenuMapping({ ID })).unwrap();
    } catch (error) {}
  };
  useEffect(() => {
    getData();
  }, [roleId]);
  return { error };
};

export default useRoleMenu;
